import React, { FunctionComponent, useCallback } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { ProductType } from 'ui/types/asset';
import { Money } from 'ui/types/money';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import Date from 'ui/atoms/date';
import Currency from 'ui/atoms/currency';
import Link from 'ui/atoms/link';
import { camelCase } from 'change-case';
import Section from 'ui/atoms/section';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import useTranslate from 'ui/hooks/use-translate';
import { RuntimeTypeEnum } from 'api/models/RuntimeTypeEnum';
import { Runtime } from 'api/models/Runtime';

export interface DashboardProductDetailsProps {
  /** Additional classes. */
  className?: string;

  productName: string;

  productType: ProductType;

  lastSignedDate: Date;

  totalInvestmentAmount: Money | null;

  website: string | null;

  totalEarnings: Money | null;

  lastProfit: Money | null;

  nextProfit: Date | null;

  runtime: Runtime | null;
}

const DashboardProductDetails: FunctionComponent<DashboardProductDetailsProps> = (props) => {
  const {
    className,
    productName,
    productType,
    lastSignedDate,
    totalInvestmentAmount,
    website,
    totalEarnings,
    lastProfit,
    nextProfit,
    runtime,
    ...restProps
  } = props;

  const translate = useTranslate();

  const getRuntime = useCallback(() => {
    if (!runtime) return;

    const period = runtime.type === RuntimeTypeEnum.Years ? translate('dashboardProductDetails.years') : '';
    return `${runtime.value} ${period}`;
  }, [runtime, translate]);

  return (
    <div className={cx('dashboard-product-details', className)} {...restProps}>
      <Section spacing={website ? 'medium' : 'none'}>
        <InfoGrid columns={3}>
          <InfoField title={<Translate name="dashboardProductDetails.totalAmount" />}>
            <PlaceholderFallback>
              {totalInvestmentAmount && <Currency>{totalInvestmentAmount}</Currency>}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="dashboardProductDetails.type" />}>
            <Translate name={`productType.${camelCase(productType)}`} />
          </InfoField>
          <InfoField title={<Translate name="dashboardProductDetails.runtime" />}>
            <PlaceholderFallback>{getRuntime()}</PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="dashboardProductDetails.totalEarnings" />}>
            <PlaceholderFallback>{totalEarnings && <Currency>{totalEarnings}</Currency>}</PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="dashboardProductDetails.lastProfit" />}>
            <PlaceholderFallback>{lastProfit && <Currency>{lastProfit}</Currency>}</PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="dashboardProductDetails.nextProfit" />}>
            <PlaceholderFallback>{nextProfit && <Date>{nextProfit}</Date>}</PlaceholderFallback>
          </InfoField>
        </InfoGrid>
      </Section>
      {website && (
        <Link href={website} external>
          <Translate name="dashboardProductDetails.toCompanyWebsite" />
        </Link>
      )}
    </div>
  );
};

export default DashboardProductDetails;
