import React, { useState, useEffect, useCallback, useMemo } from 'react';

import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import LoadingRing from 'ui/atoms/loading-ring';
import { useInvestorSelector } from 'apps/investor/resources/investor/hooks';
import Translate from 'ui/atoms/translate';
import LossAbilityCalculator from 'libraries/wizard/components/loss-ability-calculator';
import ActionButtons from 'src/ui/molecules/action-buttons';
import Button from 'src/ui/atoms/button';
import useApiCall from 'src/hooks/use-api-call';
import { InvestorsApi, NetworthAnswerRequest } from 'src/api';
import { Money } from 'src/ui/types/money';
import * as Styled from './styled';
import Currency from 'src/ui/atoms/currency';
import Spacer from 'src/ui/atoms/spacer';
import { toNumber } from 'src/ui/helper/money';
import { Prompt } from 'react-router';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

const LossAbilityPage = () => {
  const { investor, error } = useInvestorSelector();

  const [isSavingInProgress, setSavingInProgress] = useState(false);
  const [netWorthAnswers, setNetWorthAnswers] = useState<NetworthAnswerRequest[]>([]);
  const [savedNetWorth, setSavedNetworth] = useState<Money | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormPristine, setFormPristine] = useState(true);
  const [shouldResetValues, setShouldResetValues] = useState(false);

  const translate = useTranslateWithStringArgs();
  const { withApi, makeAuthenticatedApi } = useApiCall(true);
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    if (!investor?.id) return;

    withApi(async () => {
      try {
        const answers = await investorsApi.investorsNetworthAnswersRetrieve({ id: investor.id });
        setSavedNetworth(answers.networth);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    });
  }, [investor?.id]);

  const isFormPopulated = useMemo(() => {
    return netWorthAnswers.some((item) => toNumber(item.answer) > 0);
  }, [JSON.stringify(netWorthAnswers)]);

  const onFormChange = useCallback((netWorthAnswers: NetworthAnswerRequest[]) => {
    setNetWorthAnswers(netWorthAnswers);

    const isFormPopulated = netWorthAnswers.some((item) => toNumber(item.answer) > 0);
    setFormPristine(!isFormPopulated);
  }, []);

  const onSaveEntries = useCallback(async () => {
    if (!investor?.id || netWorthAnswers?.length === 0) return;

    setSavingInProgress(true);

    try {
      await investorsApi.investorsNetworthAnswersCreate({
        id: investor.id,
        investorNetworthRequest: {
          networthAnswers: netWorthAnswers,
        },
      });
      setFormPristine(true);
      setSavingInProgress(false);
    } catch (e) {
      console.error(e);
      setSavingInProgress(false);
    }
  }, [netWorthAnswers.length, investor?.id]);

  const resetEntries = useCallback(() => {
    setNetWorthAnswers([]);
    setShouldResetValues(!shouldResetValues);
  }, [shouldResetValues]);

  const restartCalculation = useCallback(async () => {
    if (!investor?.id) return;

    try {
      await investorsApi.investorsNetworthResetDestroy({
        id: investor.id,
      });
    } catch (e) {
      console.error(e);
    }

    setSavedNetworth(null);
  }, [investorsApi, investor?.id]);

  if (isLoading) {
    return <LoadingRing />;
  }

  if (error || !investor?.id) return null;

  return (
    <WideContent title={<Translate name="lossAbility.title" />}>
      <Prompt when={!isFormPristine} message={() => translate('lossAbility.confirmLeavingPage')} />
      {savedNetWorth ? (
        <Styled.RestartCalculationContainer releaseSpaceWhenTitleIsEmpty={true}>
          <Translate
            name="lossAbility.alreadyCalculatedDashboard"
            args={[
              (_, key) => (
                <strong>
                  <Currency key={key}>{savedNetWorth}</Currency>
                </strong>
              ),
            ]}
          />
          <Spacer y={2} />
          <Button variant="primary" fluid={true} onClick={restartCalculation}>
            <Translate name="lossAbility.restartCalculation" />
          </Button>
        </Styled.RestartCalculationContainer>
      ) : (
        <LossAbilityCalculator
          onUpdateAnswers={(netWorthAnswers: NetworthAnswerRequest[]) => onFormChange(netWorthAnswers)}
          shouldResetValues={shouldResetValues}
          actionButtons={
            <ActionButtons>
              <Button variant="secondary" onClick={resetEntries} disabled={!isFormPopulated || isSavingInProgress}>
                <Translate name="lossAbility.resetEntries" />
              </Button>
              <Button variant="primary" onClick={onSaveEntries} disabled={isSavingInProgress}>
                <Translate name="lossAbility.saveEntries" />
              </Button>
            </ActionButtons>
          }
        />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(LossAbilityPage, AppType.INVESTOR);
