import React, { FunctionComponent, useCallback, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Table from 'ui/molecules/table';
import Translate from 'ui/atoms/translate';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Number from 'ui/atoms/number';
import Currency from 'ui/atoms/currency';
import { Money } from 'ui/types/money';
import { invertMoneyValue, toNumber } from 'ui/helper/money';
import { MoneyValue } from 'ui/types/money-value';
import Button from 'ui/atoms/button';
import AddVoucherModal from 'apps/issuer/pages/investment-details/payment-details/add-voucher-modal';
import { AdminInvestmentVoucher } from 'api';
import * as Styled from './styled';

export interface PaymentTotalListingProps {
  /** Additional classes. */
  className?: string;

  /** Show unit amount and price, if product has set useUnits to true */
  showUnitsInfoIfConfigured?: boolean;

  /** Amount of securities */
  amount?: number;

  /** tokenPrice per security */
  tokenPrice: Money | null;

  /** Investment amount */
  investmentTotal: Money | null;

  /** Payment amount */
  paymentTotal: Money | null;

  /** Disagio amount */
  disagio: Money | null;

  /** Agio amount */
  agio: Money | null;

  /** Accrued interest */
  accruedInterest: Money | null;

  /** Investors can enter units (true) or investment amount (false) in the commitment step */
  useUnits: boolean;

  showAccruedInterest: boolean;

  /** As long as investment is not accepted, accrued interest might still change. */
  isAccruedInterestFixed: boolean;

  /** Adjust wording if payment was already made. */
  paymentCompleted?: boolean;

  investmentId?: string;

  isInvestmentAccepted?: boolean;

  hasCompliance?: boolean;

  hasManageDataPermission?: boolean;

  voucher?: AdminInvestmentVoucher | null;

  cancellationDate?: Date | null;
}

const PaymentTotalListing: FunctionComponent<PaymentTotalListingProps> = (props) => {
  const {
    amount,
    tokenPrice,
    investmentTotal,
    investmentId,
    isInvestmentAccepted,
    hasCompliance,
    hasManageDataPermission,
    disagio,
    agio,
    paymentTotal,
    useUnits,
    className,
    accruedInterest,
    showAccruedInterest,
    isAccruedInterestFixed,
    showUnitsInfoIfConfigured,
    paymentCompleted,
    cancellationDate,
  } = props;

  const [voucher, setVoucher] = useState<AdminInvestmentVoucher | null | undefined>(props.voucher);

  const [voucherModalOpen, setVoucherModalOpen] = useState(false);

  const disagioMoneyValue = disagio && MoneyValue.fromMoney(disagio);
  const negativeDisagio = disagioMoneyValue?.bigNumber.gt(0) ? invertMoneyValue(disagioMoneyValue) : undefined;

  const onVoucherAdd = useCallback(
    (voucher) => {
      setVoucher(voucher);
    },
    [voucher],
  );

  return (
    <Styled.PaymentTotalListingContainer className={cx('payment-total-listing', className)}>
      <Styled.SummaryTable borderless={true} size="small">
        <Table.Body>
          {useUnits && showUnitsInfoIfConfigured && (
            <Styled.Row>
              <Styled.Cell>
                <Translate name="investmentSummary.amountDescription" />
              </Styled.Cell>
              <Styled.Cell alignContent="right">
                <PlaceholderFallback>
                  {amount && (
                    <b>
                      <Number>{amount}</Number>
                    </b>
                  )}
                </PlaceholderFallback>
              </Styled.Cell>
            </Styled.Row>
          )}
          {useUnits && showUnitsInfoIfConfigured && (
            <Styled.Row>
              <Styled.Cell>
                <Translate name="investmentSummary.tokenPriceDescription" />
              </Styled.Cell>
              {tokenPrice && (
                <Styled.Cell alignContent="right">
                  <b>
                    <Currency decimals={'*'}>{tokenPrice}</Currency>
                  </b>
                </Styled.Cell>
              )}
            </Styled.Row>
          )}
          <Styled.Row>
            <Styled.Cell>
              <Translate name={`investmentSummary.${paymentCompleted ? 'investedAmount' : 'investmentAmount'}`} />
            </Styled.Cell>
            <Styled.Cell alignContent="right">
              <PlaceholderFallback>
                {investmentTotal && (
                  <b>
                    <Currency>{investmentTotal}</Currency>
                  </b>
                )}
              </PlaceholderFallback>
            </Styled.Cell>
          </Styled.Row>
          {(negativeDisagio || voucher?.disagio) && (
            <Styled.Row>
              <Styled.Cell>
                <Translate name="investmentSummary.voucherRedeemed" />
              </Styled.Cell>
              <Styled.Cell alignContent="right">
                {negativeDisagio && <Currency>{negativeDisagio}</Currency>}
                {!negativeDisagio && voucher?.disagio && (
                  <b>
                    <span>-</span>
                    <Currency>{voucher!.disagio}</Currency>
                  </b>
                )}
              </Styled.Cell>
            </Styled.Row>
          )}
          {(negativeDisagio || voucher?.disagio) && voucher?.name && (
            <Styled.Row>
              <Styled.SmallFont>{voucher.name}</Styled.SmallFont>
            </Styled.Row>
          )}
          {agio && toNumber(agio) !== 0 && (
            <Styled.Row>
              <Styled.Cell>
                <Translate name="investmentSummary.agio" />
              </Styled.Cell>
              <Styled.Cell alignContent="right">
                <b>
                  <Currency showSign={true}>{agio}</Currency>
                </b>
              </Styled.Cell>
            </Styled.Row>
          )}
          {showAccruedInterest && accruedInterest && (
            <Styled.Row>
              <Styled.Cell>
                <Translate
                  name="investmentSummary.accruedInterest"
                  args={!isAccruedInterestFixed ? [<Translate key={0} name="investmentSummary.today" />] : ['']}
                />
              </Styled.Cell>
              <Styled.Cell alignContent="right">
                <b>
                  <Currency showSign={true}>{accruedInterest}</Currency>
                </b>
              </Styled.Cell>
            </Styled.Row>
          )}
        </Table.Body>
        <Table.Foot>
          <Styled.FootRow>
            <Styled.Cell>
              <Translate name={'investmentSummary.paymentAmount'} />
            </Styled.Cell>
            <Styled.Cell alignContent="right">
              <PlaceholderFallback>
                {paymentTotal && (
                  <b>
                    <Currency>{paymentTotal}</Currency>
                  </b>
                )}
              </PlaceholderFallback>
            </Styled.Cell>
          </Styled.FootRow>
          {!voucher && !isInvestmentAccepted && !hasCompliance && hasManageDataPermission && cancellationDate === null && (
            <Styled.FootRow>
              <Button variant="link" icon="plus" onClick={() => setVoucherModalOpen(true)}>
                <Translate name="investmentSummary.addVoucher" />
              </Button>
            </Styled.FootRow>
          )}
        </Table.Foot>
      </Styled.SummaryTable>

      {voucherModalOpen && (
        <AddVoucherModal
          title={<Translate name="investmentSummary.addVoucher" />}
          investmentId={investmentId!}
          onVoucherAdd={(voucher) => onVoucherAdd(voucher)}
          onHideModal={() => setVoucherModalOpen(false)}
        />
      )}
    </Styled.PaymentTotalListingContainer>
  );
};

export default PaymentTotalListing;
