import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { compact } from 'lodash';
import LoadingRing from 'ui/atoms/loading-ring';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi, UsersApi, WalletsApi } from 'api/apis';
import { User, Wallet } from 'api/models';
import useInvestorMe from 'hooks/use-investor-me';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import { convertWalletTypeToApi } from 'core/api/conversions';
import { WalletType } from 'ui/types/wallet';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';
import InvestmentSelectDepot, { InvestmentWallet } from './select-depot';
import CreateDepot from './create-depot';
import CreateCashlinkWallet from './create-cashlink-wallet';
import useOnlyOnce from 'hooks/use-only-once';
import CreateWallet from './create-wallet';
import { useServerConfigSelector } from 'src/core/config/hooks';

const SelectWalletStep: FunctionComponent<{}> = () => {
  const { finalize, loading: contextLoading } = useContext(WizardContext);

  const [wallets, setWallets] = useState<Wallet[] | null>();
  const [nonExistingWalletTypeSelection, setNonExistingWalletTypeSelection] = useState<WalletType | null>();

  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();

  const { investor, error: investorError } = useInvestorMe();

  const [userMe, setUserMe] = useState<User>();

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);
  const walletsApi: WalletsApi = useMemo(() => makeAuthenticatedApi(WalletsApi), [makeAuthenticatedApi]);

  const {
    config: { registerOnlyFlow },
  } = useServerConfigSelector();

  useOnlyOnce(() => {
    withApi(async () => {
      setWallets(await walletsApi.walletsList());
    });
  });

  useEffect(() => {
    withApi(async () => {
      const api: UsersApi = makeAuthenticatedApi(UsersApi);
      setUserMe(await api.usersMeRetrieve());
    });
  }, [withApi, makeAuthenticatedApi]);

  const onFinalizeCreateWallet = () => {
    finalize();
  };

  const createWallet = useCallback(
    async (address: string) => {
      await withApi(async () => {
        if (nonExistingWalletTypeSelection === WalletType.GENERIC) {
          await walletsApi.walletsGenericWalletCreate({
            genericWalletCreationRequest: { address },
          });
        }
      });

      finalize();
    },
    [withApi, walletsApi, nonExistingWalletTypeSelection],
  );

  const createWalletSelection = useCallback(
    async (walletType: WalletType.MOBILE) => {
      await withApi(async () => {
        if (!userMe?.investor) return;

        await investorsApi.investorsWalletSelectionCreate({
          id: userMe.investor,
          investorWalletSelectionRequest: {
            walletType: convertWalletTypeToApi(walletType),
          },
        });

        finalize();
      });
    },
    [withApi, finalize, userMe?.investor],
  );

  const onDepotSelected = useCallback(
    async (selectedWallet: InvestmentWallet) => {
      // existing wallet:
      if (selectedWallet.address) {
        if (selectedWallet.id) await onFinalizeCreateWallet();
      }
      // non-existing mobile safekeeping wallet:
      else if (selectedWallet.type === WalletType.MOBILE) {
        await createWalletSelection(selectedWallet.type);
      }
      // other non-existing wallets:
      else {
        setNonExistingWalletTypeSelection(selectedWallet.type);
      }
    },
    [onFinalizeCreateWallet, setNonExistingWalletTypeSelection, createWalletSelection],
  );

  const prioritizedAllowedWalletTypes: WalletType[] = compact([
    registerOnlyFlow.canUseCashlinkWallet && WalletType.CASHLINK,
    registerOnlyFlow.canUseTanganyWallet && WalletType.TANGANY,
    registerOnlyFlow.canUseMobileWallet && WalletType.MOBILE,
    WalletType.GENERIC,
  ]);

  const title = <Translate name="investmentDepot.title" />;

  if (investorError) return null;

  if (!wallets || !investor) return <LoadingRing />;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      {registerOnlyFlow.canUseCashlinkWallet && nonExistingWalletTypeSelection === WalletType.CASHLINK && (
        <CreateCashlinkWallet
          onWalletCreated={onFinalizeCreateWallet}
          onCancel={() => setNonExistingWalletTypeSelection(null)}
          loading={apiLoading}
          error={apiError}
        />
      )}
      {registerOnlyFlow.canUseTanganyWallet && nonExistingWalletTypeSelection === WalletType.TANGANY && (
        <CreateDepot
          onWalletCreated={onFinalizeCreateWallet}
          onCancel={() => setNonExistingWalletTypeSelection(null)}
          loading={apiLoading}
          error={apiError}
        />
      )}
      {nonExistingWalletTypeSelection === WalletType.GENERIC && (
        <CreateWallet
          onSubmit={({ address }) => createWallet(address)}
          onCancel={() => setNonExistingWalletTypeSelection(null)}
          type={nonExistingWalletTypeSelection}
          loading={apiLoading}
          error={apiError}
        />
      )}
      {!nonExistingWalletTypeSelection && (
        <InvestmentSelectDepot
          loading={apiLoading || contextLoading}
          error={apiError}
          onDepotSelected={onDepotSelected}
          prioritizedAllowedWalletTypes={prioritizedAllowedWalletTypes}
          tfaDevice={userMe?.tfaDevice || undefined}
        />
      )}
    </>
  );
};

export default SelectWalletStep;
