import React, { FunctionComponent, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { Money } from 'ui/types/money';
import PaymentTotalListing from 'libraries/payment-total-listing';
import { ProductType } from 'ui/types/asset';
import Header from 'ui/atoms/header';
import { camelCase } from 'change-case';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Image from 'ui/atoms/image';
import { Document } from 'ui/types/document';
import Text from 'ui/polymorphs/text';
import * as Styled from './styled';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import Spacer from 'ui/atoms/spacer';
import Icon from 'src/ui/atoms/icon';

export interface InvestmentSideContentProps {
  /** Additional classes. */
  className?: string;

  image?: Document;

  productName: string;

  productType: ProductType;

  /** Amount of securities */
  amount?: number;

  /** tokenPrice per security */
  tokenPrice: Money;

  /** Investment amount */
  investmentTotal: Money | null;

  /** Payment amount */
  paymentTotal: Money | null;

  /** Disagio amount */
  disagio: Money | null;

  /** Accrued interest */
  accruedInterest: Money;

  useUnits: boolean;

  showAccruedInterest: boolean;

  /** As long as investment is not accepted, accrued interest might still change. */
  isAccruedInterestFixed: boolean;

  paymentCompleted: boolean;

  showPaymentListing: boolean;

  agio: Money | null;
}

const InvestmentSideContent: FunctionComponent<InvestmentSideContentProps> = (props) => {
  const { image, className, productName, productType, showPaymentListing, ...paymentTotalListingProps } = props;

  const [paymentDetailsExpanded, setPaymentDetailsExpanded] = useState(false);

  const isLargeDesktop = useIsMedia([DEVICES.desktop, DEVICES.lDesktop]);
  const isPhoneTablet = useIsMedia([DEVICES.phone, DEVICES.tablet]);
  const isPhone = useIsMedia(DEVICES.phone);

  return (
    <Styled.SideContent className={cx('investment-process-summary-card', className)}>
      {isLargeDesktop && (
        <>
          <Section spacing={showPaymentListing ? 'medium' : 'none'}>
            <Header size="small" spacing="none" breakWords={true}>
              <Text wordBreak="break-word">{productName}</Text>
            </Header>
            <Section spacing="medium">
              <Translate name={`productType.${camelCase(productType)}`} />
            </Section>
            {image && (
              <Section spacing="none">
                <Image src={image.file} alt={`${productName} image`} size="xlarge" bordered />
              </Section>
            )}
          </Section>
          {showPaymentListing && <PaymentTotalListing {...paymentTotalListingProps} />}
        </>
      )}
      {!isLargeDesktop && (
        <>
          {image && (
            <>
              <Section spacing="none">
                <Image src={image.file} alt={`${productName} image`} size={isPhone ? 'small' : 'medium'} bordered />
              </Section>
              <Spacer x={6} />
            </>
          )}
          <Styled.ProductPaymentContainer>
            <div>
              <Styled.ProductDetails>
                <Header size="small" spacing="none" breakWords={true}>
                  <Text wordBreak="break-word">{productName}</Text>
                </Header>
                <Translate name={`productType.${camelCase(productType)}`} />
              </Styled.ProductDetails>
              {showPaymentListing && (!isPhoneTablet || paymentDetailsExpanded) && (
                <PaymentTotalListing {...paymentTotalListingProps} />
              )}
            </div>
            {isPhoneTablet && showPaymentListing && (
              <Icon
                name={paymentDetailsExpanded ? 'angle-up' : 'angle-down'}
                color="link"
                onClick={() => setPaymentDetailsExpanded(!paymentDetailsExpanded)}
              />
            )}
          </Styled.ProductPaymentContainer>
        </>
      )}
    </Styled.SideContent>
  );
};

export default InvestmentSideContent;
