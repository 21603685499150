import { Cmd, loop, Loop, LoopReducerWithDefinedState } from 'redux-loop';
import { mergeAll } from 'lodash/fp';
import { push } from 'connected-react-router';
import { parse } from 'query-string';
import applicationConfig from 'core/config/local';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';
import type {
  AuthActionTypes,
  AuthFetchFailure,
  AuthFetchSuccess,
  InvalidateAuth,
  Login,
  LoginFailure,
  LoginSuccess,
  RedirectLoggedIn,
  RedirectWithLoopback,
  ReLogin,
  SetAuthToken,
  SetUserLanguage,
} from './actions';
import {
  AuthActions,
  authFetch,
  authFetchFailure,
  authFetchSuccess,
  invalidateAuth,
  loginFailure,
  loginSuccess,
  redirectPathWithLoopback,
  setAuthToken,
} from './actions';
import {
  getUserEffect,
  loginEffect,
  removeLocalStorageAuth,
  setLocalStorageAuth,
  setUserLanguageEffect,
  verifyInitialAuthEffect,
} from './effects';
import type { User } from 'api';
import { AppType } from 'core/auth/types';
import ServerError from 'ui/types/server-error';
import { resetAppState, WithRootActionTypes } from 'store/actions';

export type AuthStateShape = {
  me: {
    data: User | null;
    loading: boolean;
    error: ServerError | null;
  };
  authToken: {
    data: string | null;
    loading: boolean;
    error: ServerError | null;
  };
  authVerified: boolean;
  redirectPath: string | null;
  redirectOrigin: AppType;
};

export const initialState: AuthStateShape = {
  me: {
    data: null,
    loading: false,
    error: null,
  },
  authToken: {
    data: null,
    loading: false,
    error: null,
  },
  authVerified: false,
  redirectPath: null,
  redirectOrigin: AppType.DEFAULT,
};

type AuthLoop<A> = (state: AuthStateShape, action: A) => AuthStateShape | Loop<AuthStateShape>;

const handleVerifyAuth: AuthLoop<any> = (state) => {
  return loop(
    state,
    Cmd.run(verifyInitialAuthEffect, {
      args: [],
      successActionCreator: setAuthToken,
      failActionCreator: invalidateAuth,
    }),
  );
};

const handleAuthFetch: AuthLoop<AuthActionTypes> = (state) => {
  return loop(
    mergeAll([state, { me: { loading: true, error: null } }]),
    Cmd.run(getUserEffect, {
      args: [Cmd.getState],
      successActionCreator: authFetchSuccess,
      failActionCreator: authFetchFailure,
    }),
  );
};

const handleAuthFetchSuccess: AuthLoop<AuthFetchSuccess> = (state, { data }) => {
  return mergeAll([
    state,
    {
      me: { data: data, loading: false, error: null },
      authVerified: true,
    },
  ]);
};

const handleAuthFetchFailure: AuthLoop<AuthFetchFailure> = (state, { error }) => {
  return loop(
    mergeAll([
      state,
      {
        me: { data: null, loading: false, error: error },
        authVerified: true,
      },
    ]),
    Cmd.action(invalidateAuth()),
  );
};

const handleLogin: AuthLoop<Login> = (state, { email, password }) => {
  return loop(
    mergeAll([state, { authToken: { data: null, loading: true, error: null } }]),
    Cmd.run(loginEffect, {
      args: [email, password],
      successActionCreator: loginSuccess,
      failActionCreator: loginFailure,
    }),
  );
};

const handleLoginSuccess: AuthLoop<LoginSuccess> = (state, { token }) => {
  return loop(state, Cmd.action(setAuthToken(token)));
};

const handleLoginFailure: AuthLoop<LoginFailure> = (state, { error }) => {
  return mergeAll([state, { authToken: { data: null, loading: false, error: error } }]);
};

const handleSetAuthToken: AuthLoop<SetAuthToken> = (state, { token }) => {
  return loop(
    mergeAll([state, { authToken: { data: token, loading: false, error: null } }]),
    Cmd.list([
      Cmd.run(setLocalStorageAuth, {
        args: [token],
      }),
      Cmd.action(authFetch()),
    ]),
  );
};

const handleInvalidateAuth: AuthLoop<InvalidateAuth> = (state) => {
  return loop(
    mergeAll([
      state,
      {
        me: { data: null, loading: false, error: null },
        authToken: { data: null, loading: false, error: null },
        authVerified: true,
        redirectPath: null,
        redirectOrigin: AppType.DEFAULT,
      },
    ]),
    Cmd.list([Cmd.run(removeLocalStorageAuth), Cmd.action(resetAppState())]),
  );
};

const handleReLogin: AuthLoop<ReLogin> = (state, { appType }) => {
  return loop(
    state,
    Cmd.list([Cmd.action(invalidateAuth()), Cmd.action(redirectPathWithLoopback(AUTH_ROUTES.login.path, appType))]),
  );
};

const handleRedirectWithLoopback: AuthLoop<RedirectWithLoopback> = (state, { path, nextPath, appType, pathname }) => {
  const next = nextPath ? nextPath : pathname;

  // do nothing if on the same path already
  if (path === next) {
    return state;
  }

  return loop(
    mergeAll([state, { redirectPath: next, redirectOrigin: appType }]),
    Cmd.action(push(`${path}?nextPath=${encodeURIComponent(next)}`)),
  );
};

const handleRedirectLoggedIn: AuthLoop<RedirectLoggedIn> = (state, { search }) => {
  const queryParams = parse(search);
  let newPath = applicationConfig.auth.defaultRedirectRoute;

  if (queryParams?.nextPath && !Array.isArray(queryParams.nextPath)) {
    newPath = decodeURIComponent(queryParams.nextPath);
  }

  return loop(mergeAll([state, { redirectPath: null, redirectOrigin: AppType.DEFAULT }]), Cmd.action(push(newPath)));
};

const handleSetUserLanguage: AuthLoop<SetUserLanguage> = (state, { language }) => {
  return loop(
    state,
    Cmd.run(setUserLanguageEffect, {
      args: [language, Cmd.getState],
      successActionCreator: authFetchSuccess,
    }),
  );
};

const reducer: LoopReducerWithDefinedState<AuthStateShape, WithRootActionTypes<AuthActionTypes>> = (
  state = initialState,
  action,
): AuthStateShape | Loop<AuthStateShape> => {
  switch (action.type) {
    case AuthActions.VERIFY_AUTH:
      return handleVerifyAuth(state, action);
    case AuthActions.AUTH_FETCH:
      return handleAuthFetch(state, action);
    case AuthActions.AUTH_FETCH_SUCCESS:
      return handleAuthFetchSuccess(state, action);
    case AuthActions.AUTH_FETCH_FAILURE:
      return handleAuthFetchFailure(state, action);
    case AuthActions.LOGIN:
      return handleLogin(state, action);
    case AuthActions.LOGIN_SUCCESS:
      return handleLoginSuccess(state, action);
    case AuthActions.LOGIN_FAILURE:
      return handleLoginFailure(state, action);
    case AuthActions.SET_AUTH_TOKEN:
      return handleSetAuthToken(state, action);
    case AuthActions.INVALIDATE_AUTH:
      return handleInvalidateAuth(state, action);
    case AuthActions.RE_LOGIN:
      return handleReLogin(state, action);
    case AuthActions.REDIRECT_WITH_LOOPBACK:
      return handleRedirectWithLoopback(state, action);
    case AuthActions.REDIRECT_LOGGED_IN:
      return handleRedirectLoggedIn(state, action);
    case AuthActions.SET_USER_LANGUAGE:
      return handleSetUserLanguage(state, action);
    default:
      return state;
  }
};

export default reducer;
