import { CurrencyEnum, Money } from '../types/money';
import has from 'lodash/has';
import { MoneyValue } from '../types/money-value';
import BigNumber from 'bignumber.js';

export const defaultCurrency = CurrencyEnum.EUR;

export const countDecimals = (num: number) => {
  if (Number.isNaN(num)) return 0;
  if (Math.floor(num) === num) return 0;
  return num.toString().split('.')[1].length || 0;
};

export const toNumber = (num: string | number | undefined | Money): number => {
  // is money field
  if (has(num, 'amount')) {
    const { amount, decimals } = num as Money;
    return (parseFloat(amount as any) || 0) / Math.pow(10, decimals || 0);
  }

  if (typeof num === 'string' && num.length) {
    num = num.replace(',', '.');
  }
  // is string, number or undefined
  return parseFloat(num as any);
};

export const toMoney = (num: string | number, currency: CurrencyEnum = defaultCurrency): Money => {
  const value = parseFloat(num as any);
  const decimals = countDecimals(value);

  return {
    decimals,
    currency,
    amount: (value * Math.pow(10, decimals)).toFixed(),
  };
};

export const deductMoney = (from: Money, deductValue: Money): Money => {
  const value = toNumber(from) - toNumber(deductValue);
  return toMoney(value, from.currency);
};

export const nullMoney: Money = {
  decimals: 0,
  amount: '0',
  currency: defaultCurrency,
};

export const invertMoneyValue = (moneyValue: MoneyValue): MoneyValue => {
  return moneyValue.mul(new BigNumber(-1));
};
