import React, { FunctionComponent, useMemo } from 'react';
import RoutesRenderer from 'core/routing/routes-renderer';
import DashboardLayout from 'core/layout/dashboard';
import { COMPONENT_ROUTING, ISSUER_NAVIGATION, LEGACY_REDIRECTS, ROUTES } from './routes.config';
import { useIssuerNavigationItems } from './hooks';
import type { InvestmentToken } from 'api';
import { useServerConfigSelector } from 'core/config/hooks';
import { hasManageTriggerSolutionPermission } from '../helpers/permissions';

export interface IssuerPagesProps {
  tokens: InvestmentToken[];
}

const IssuerPages: FunctionComponent<IssuerPagesProps> = ({ tokens }) => {
  const { config } = useServerConfigSelector();

  const routes = useIssuerNavigationItems({
    tokens,
    configFeatures: config.features,
  });

  let filteredRoutes = ISSUER_NAVIGATION;
  if (!config.friendVouchersEnabled) {
    filteredRoutes = ISSUER_NAVIGATION.filter((nav) => nav.key !== ROUTES.friendReferrals);
  }

  if (!hasManageTriggerSolutionPermission(tokens)) {
    filteredRoutes = ISSUER_NAVIGATION.filter((nav) => nav.key !== ROUTES.triggerSolutionsSettlements);
  }

  const navItems = useMemo(
    () => (routes ? filteredRoutes.filter((nav) => !!routes[nav.key]) : []),
    [JSON.stringify(routes)],
  );

  return (
    <DashboardLayout navigationItems={navItems}>
      <RoutesRenderer routes={routes} componentRouting={COMPONENT_ROUTING} legacyRedirects={LEGACY_REDIRECTS} />
    </DashboardLayout>
  );
};

export default IssuerPages;
