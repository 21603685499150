import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import useApiCall from 'hooks/use-api-call';
import { AdminApi } from 'api/apis';
import { Campaign } from 'api/models';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import useClipboard from 'ui/hooks/use-clipboard';
import makeLink from 'helper/make-link';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import ProductCampaignExtendedDetailsCard from 'apps/issuer/pages/product-details/campaigns/campaign-details/product-campaign-card';

type BoxedIssuerCampaignProps = Campaign & {
  issuersCanManage?: boolean;
};

const BoxedIssuerCampaign: FunctionComponent<BoxedIssuerCampaignProps> = (props) => {
  const { issuersCanManage, ...campaignProps } = props;
  const [campaign, setCampaign] = useState<Campaign>(campaignProps);

  const {
    id,
    allocation,
    allocationAvailable,
    canActivate,
    minNumberOfTokens,
    maxNumberOfTokens,
    pricePerToken,
    ruleType,
    country,
    active,
    token,
    name,
    signedPercentage,
    createdAt,
  } = campaign;

  const { withApi, makeAuthenticatedApi, loading, error } = useApiCall();
  const { copy, copied } = useClipboard();

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const answerErrors = getRemainingError();

  const url = makeLink(INVESTMENT_ROUTES.campaign, { campaignId: id }, true);

  useEffect(() => {
    const { issuersCanManage, ...campaignProps } = props;
    setCampaign(campaignProps);
  }, [props]);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const activate = useCallback(() => {
    withApi(async () => {
      if (id) {
        await api.adminCampaignsActivationCreate({
          id,
          campaignActivationRequest: { active: !active },
        });
        const campaignNew = await api.adminCampaignsRetrieve({ id });
        setCampaign(campaignNew);
      }
    });
  }, [withApi, api, id, active]);

  return (
    <BoxedContent
      releaseSpaceWhenTitleIsEmpty={true}
      actionButtons={
        !issuersCanManage
          ? [
              {
                name: 'copyLinkToClipboard',
                content: <Translate name={`copy.link.${!copied ? 'copy' : 'copied'}`} />,
                variant: 'primary',
                disabled: copied,
                onClick: () => copy(url),
              },
            ]
          : active
          ? [
              {
                name: 'deactivateCampaign',
                content: <Translate name="issuerCampaigns.deactivateCampaign" />,
                error: answerErrors,
                onClick: activate,
              },
              {
                name: 'copyLinkToClipboard',
                content: <Translate name={`copy.link.${!copied ? 'copy' : 'copied'}`} />,
                variant: 'primary',
                disabled: copied,
                onClick: () => copy(url),
              },
            ]
          : [
              {
                name: 'reactivateCampaign',
                content: <Translate name="issuerCampaigns.reactivateCampaign" />,
                variant: 'primary',
                error: answerErrors,
                disabled: !canActivate && allocationAvailable > 0,
                onClick: activate,
              },
            ]
      }
    >
      {loading && <LoadingRing />}
      {!loading && (
        <ProductCampaignExtendedDetailsCard
          allocation={allocation}
          allocationAvailable={allocationAvailable}
          minNumberOfTokens={minNumberOfTokens}
          maxNumberOfTokens={maxNumberOfTokens}
          pricePerToken={pricePerToken}
          ruleType={ruleType as any}
          country={country as any}
          active={active}
          token={token}
          name={name}
          createdAt={createdAt}
          signedPercentage={signedPercentage}
        />
      )}
    </BoxedContent>
  );
};

export default BoxedIssuerCampaign;
