import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { AdminInvestment, InvestmentCancellationEnum } from 'api/models';
import { AdminApi } from 'api/apis';
import Translate from 'ui/atoms/translate';
import StudioConfirmInvestmentsTable from 'apps/issuer/shared/confirm-investments-table-view/confirm-investments-table';
import IssuerBatchConfirmation from './batch-confirmation';
import WithDataRecord from 'hoc/WithDataRecord';
import HeaderPanel from 'ui/atoms/header-panel';
import IssuerCreditSecurities from './credit-securities';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import InvestmentCancellationModal, {
  CancellationModalType,
} from 'src/apps/issuer/shared/investment-cancellation-modal';
import WideContent from 'core/layout/content/wide-content';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { AdminInvestmentsListStatusEnum } from 'api';
import type { DataRecordOrdering } from 'ui/types/data-ordering';
import { useIssuerOverview } from 'apps/issuer/hooks';
import { ConfirmInvestmentsTypes } from 'apps/issuer/shared/confirm-investments-table-view/types';
import useIsMedia, { DEVICES } from 'src/ui/hooks/use-is-media';
import Spacer from 'src/ui/atoms/spacer';

const mapConfirmationEmptyViewType = {
  approveOffers: EmptyViewType.ISSUER_APPROVALS,
  confirmPayments: EmptyViewType.ISSUER_PAYMENTS,
  creditSecurities: EmptyViewType.ISSUER_DIGITAL_SECURITIES,
};

interface IssuerInvestmentsCornfirmationTableProps {
  type: ConfirmInvestmentsTypes;
  filters?: React.ReactNode;
  defaultOrdering: DataRecordOrdering;
  defaultLimit?: number;
}

const IssuerInvestmentsCornfirmationTable: FunctionComponent<IssuerInvestmentsCornfirmationTableProps> = WithDataRecord<
  AdminApi,
  AdminInvestment
>(AdminApi)(
  ({ data, filters, ordering, onOrderBy, type, loadData, loading, paginationProps }) => {
    // TODO(mara-cashlink): error handling!
    const [loadingConfirmationId, setLoadingConfirmationId] = useState<string>();
    const [investmentForIssuance, setInvestmentForIssuance] = useState<AdminInvestment>();
    const [investmentForCancellationConfirmation, setInvestmentForCancellationConfirmation] = useState<string>();

    const isPhone = useIsMedia(DEVICES.phone);

    const { withApi, makeAuthenticatedApi } = useApiCall();

    const {
      withApi: withCancellationApi,
      makeAuthenticatedApi: makeAuthenticatedCancellationApi,
      loading: loadingCancellation,
    } = useApiCall();

    const investmentsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

    const investmentsCancellationApi: AdminApi = useMemo(
      () => makeAuthenticatedCancellationApi(AdminApi),
      [makeAuthenticatedCancellationApi],
    );

    const tokensApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

    const { requiredActions, tokenLoading, loading: loadingOpenActions } = useIssuerOverview();

    const onConfirm = useCallback(
      (investmentId) => {
        if (type === 'approveOffers') {
          withApi(async () => {
            setLoadingConfirmationId(investmentId);
            await investmentsApi.adminInvestmentsOfferApprovalCreate({
              id: investmentId,
            });
            loadData();
            setLoadingConfirmationId(undefined);
          });
        }
        if (type === 'confirmPayments') {
          withApi(async () => {
            setLoadingConfirmationId(investmentId);
            await investmentsApi.adminInvestmentsPaymentConfirmationCreate({
              id: investmentId,
            });
            loadData();
            setLoadingConfirmationId(undefined);
          });
        }
        if (type === 'creditSecurities') {
          withApi(async () => {
            setLoadingConfirmationId(investmentId);
            const investment = await investmentsApi.adminInvestmentsRetrieve({
              id: investmentId,
            });
            setInvestmentForIssuance(investment);
            loadData();
            setLoadingConfirmationId(undefined);
          });
        }
      },
      [withApi, investmentsApi, tokensApi, loadData, type],
    );

    const cancellationReason = useMemo(() => {
      if (type === 'approveOffers') return InvestmentCancellationEnum.REJECTION;
      if (type === 'confirmPayments') return InvestmentCancellationEnum.PAYMENTMISSING;
      return undefined;
    }, [type]);

    const onCancelInvestment = useCallback(
      (investmentId) => {
        if (!cancellationReason) return;
        withCancellationApi(async () => {
          await investmentsCancellationApi.adminInvestmentsCancellationCreate({
            id: investmentId,
            investmentCancellationRequest: {
              reason: cancellationReason,
            },
          });
          loadData();
        });
      },
      [withCancellationApi, investmentsCancellationApi, loadData, cancellationReason],
    );

    const filteredData = useMemo(() => {
      if (type === 'creditSecurities') return data?.filter(({ canIssue }) => canIssue);

      return data;
    }, [data, type]);

    return (
      <>
        <HeaderPanel title={<Translate name={`studioConfirmInvestmentsTable.${type}.title`} />}>
          {!isPhone && (type === 'approveOffers' || type === 'confirmPayments') && (
            <IssuerBatchConfirmation type={type} investments={filteredData} onReloadData={loadData} />
          )}
        </HeaderPanel>
        {isPhone && (type === 'approveOffers' || type === 'confirmPayments') && (
          <>
            <IssuerBatchConfirmation type={type} investments={filteredData} onReloadData={loadData} />
            <Spacer y={2} />
          </>
        )}
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <StudioConfirmInvestmentsTable
          type={type}
          ordering={ordering}
          onOrderBy={onOrderBy}
          loading={loading || loadingOpenActions || tokenLoading}
          loadingConfirmationId={loadingConfirmationId}
          onConfirm={onConfirm}
          onOpenCancellationModal={setInvestmentForCancellationConfirmation}
          investments={filteredData}
          paginationProps={paginationProps}
          emptyView={
            <IssuerEmptyView
              type={mapConfirmationEmptyViewType[type]}
              openIssuerApprovals={!!requiredActions?.find((action) => action.type === 'approveOffers')}
              openIssuerPaymentConfirmations={!!requiredActions?.find((action) => action.type === 'confirmPayments')}
            />
          }
        />
        {investmentForCancellationConfirmation && (
          <InvestmentCancellationModal
            onCancelInvestment={() => onCancelInvestment(investmentForCancellationConfirmation)}
            loading={loadingCancellation}
            onClose={() => setInvestmentForCancellationConfirmation(undefined)}
            type={
              cancellationReason === InvestmentCancellationEnum.REJECTION
                ? CancellationModalType.REJECTION
                : CancellationModalType.KYC_OR_PAYMENT_MISSING
            }
          />
        )}
        {type === 'creditSecurities' && investmentForIssuance && (
          <IssuerCreditSecurities
            investment={investmentForIssuance}
            onDone={() => {
              loadData();
              setInvestmentForIssuance(undefined);
            }}
          />
        )}
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminInvestmentsList({
      offset,
      limit,
      ordering,
      status: {
        approveOffers: AdminInvestmentsListStatusEnum.Acceptable,
        confirmPayments: AdminInvestmentsListStatusEnum.PaymentOutstanding,
        creditSecurities: AdminInvestmentsListStatusEnum.SecuritiesOutstanding,
      }[props.type],
      ...props,
    });
  },
);

const IssuerConfirmInvestmentsTableView: FunctionComponent<{
  type: ConfirmInvestmentsTypes;
  filters?: React.ReactNode;
  defaultLimit?: number;
  defaultOrdering?: number;
}> = ({ type, filters, defaultLimit }) => {
  const addDefaultOrdering = useMemo(() => {
    switch (type) {
      case 'approveOffers':
        return {
          direction: 'desc' as 'asc' | 'desc',
          fieldName: 'signed_date',
        };
      case 'confirmPayments':
      case 'creditSecurities':
        return { direction: 'asc' as 'asc' | 'desc', fieldName: 'signed_date' };
      default:
        return {
          direction: 'desc' as 'asc' | 'desc',
          fieldName: 'signed_date',
        };
    }
  }, [type]);

  return (
    <WideContent>
      <IssuerInvestmentsCornfirmationTable
        type={type}
        filters={filters}
        defaultOrdering={addDefaultOrdering}
        defaultLimit={defaultLimit}
      />
    </WideContent>
  );
};

export default IssuerConfirmInvestmentsTableView;
