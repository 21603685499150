import React, { FunctionComponent } from 'react';
import { StepEnum } from 'api/models';
import { assertUnreachable } from 'ui/helper/unreachable';
import OfferStep from 'subapps/investment/pages/investment/wizard-steps/offer';
import QuestionnaireStep from 'subapps/investment/pages/investment/wizard-steps/questionnaire';
import VibStep from 'subapps/investment/pages/investment/wizard-steps/vib';
import BeneficiaryStep from 'subapps/investment/pages/investment/wizard-steps/beneficiary';
import IdentificationStep from 'subapps/investment/pages/investment/wizard-steps/identification-step';
import SummaryStep from 'subapps/investment/pages/investment/wizard-steps/summary';
import SelectWalletStep from 'subapps/investment/pages/investment/wizard-steps/wallet/select-wallet';
import AccountSetupStep from 'subapps/investment/pages/investment/wizard-steps/account-setup';
import UseConfirmationStep from 'subapps/investment/pages/investment/wizard-steps/user-confirmation';
import FinalStep from 'subapps/investment/pages/investment/wizard-steps/final';
import AdditionalInformationStep from 'subapps/investment/pages/investment/wizard-steps/additional-information';
import InvestmentAmountStep from 'subapps/investment/pages/investment/wizard-steps/investment-amount';
import SetupMobileWalletStep from 'subapps/investment/pages/investment/wizard-steps/wallet/setup-mobile-wallet';
import ConfirmWalletStep from 'subapps/investment/pages/investment/wizard-steps/wallet/confirm';
import WalletStepDone from 'subapps/investment/pages/investment/wizard-steps/wallet/wallet-step-done';
import IdentificationStepDone from 'libraries/wizard/components/identification-step-done';
import PrepareAssetStep from 'subapps/investment/pages/investment/wizard-steps/wallet/prepare-asset';

const stepMapping = (
  step?: StepEnum,
  isTransferInvitation?: boolean,
): {
  Component: FunctionComponent<any>;
  DoneComponent?: FunctionComponent<any>;
  investmentRequired?: boolean;
} => {
  switch (step) {
    case StepEnum.ACCOUNTSETUP:
      return {
        Component: AccountSetupStep,
        investmentRequired: false,
      };
    case StepEnum.UNCONFIRMED:
      return {
        Component: UseConfirmationStep,
        investmentRequired: false,
      };
    case StepEnum.INVESTMENTAMOUNT:
      return {
        Component: InvestmentAmountStep,
        investmentRequired: false,
      };
    case StepEnum.QUESTIONNAIRE:
      return {
        Component: QuestionnaireStep,
        investmentRequired: false,
      };
    case StepEnum.VIB:
      return {
        Component: VibStep,
        investmentRequired: false,
      };
    case StepEnum.OFFERCREATION:
      return {
        Component: OfferStep,
        investmentRequired: false,
      };
    case StepEnum.BENEFICIARY:
      return {
        Component: BeneficiaryStep,
      };
    case StepEnum.IDENTIFICATION:
    case StepEnum.RESUMEIDENTIFICATION:
    case StepEnum.IDENTIFICATIONFAILED:
    case StepEnum.IDENTIFICATIONREJECTED:
      return {
        Component: IdentificationStep,
        DoneComponent: IdentificationStepDone,
      };
    case StepEnum.WAITINGKYC:
      if (isTransferInvitation) {
        return {
          Component: IdentificationStep,
          DoneComponent: IdentificationStepDone,
        };
      }
      return {
        Component: SummaryStep,
      };
    case StepEnum.WAITINGACCEPTANCE:
    case StepEnum.WAITINGCOMPLIANCE:
    case StepEnum.PAYMENT:
      return {
        Component: SummaryStep,
      };
    case StepEnum.SELECTWALLET:
      return {
        Component: SelectWalletStep,
        DoneComponent: WalletStepDone,
      };
    case StepEnum.SETUPMOBILEWALLET:
      return {
        Component: SetupMobileWalletStep,
      };
    case StepEnum.PREPAREASSET:
      return {
        Component: PrepareAssetStep,
      };
    case StepEnum.CONFIRMWALLET:
      return {
        Component: ConfirmWalletStep,
      };
    case StepEnum.ADDITIONALINFORMATION:
      return {
        Component: AdditionalInformationStep,
      };
    case StepEnum.FINAL:
      return {
        Component: FinalStep,
      };
    case StepEnum.CANCELED:
    case undefined:
      return {
        Component: () => <></>,
        investmentRequired: false,
      };
  }

  return assertUnreachable(step);
};

export default stepMapping;
