import React, { FunctionComponent, useEffect, useState } from 'react';

import appConfig from 'core/config/local';
import useApiCall from 'hooks/use-api-call';
import { registerHandler } from 'helper/postmessage-buffer';
import { ExternalInfoApi, ExternalInfo } from 'api';

interface ExternalInfoResponse {
  success: boolean;
  error?: string;
  result?: ExternalInfo;
}

// todo: this does not belong in auth, there should be a "public" subapp potentially, where random stuff like can go
const ExternalInfoPage: FunctionComponent<{}> = (_) => {
  const trustedOrigins = appConfig.externalInfoOrigins || [];
  const { withApi, makeAuthenticatedApi, error } = useApiCall();

  const [result, setResult] = useState<null | ExternalInfo>(null);

  useEffect(() => {
    const api = makeAuthenticatedApi(ExternalInfoApi);
    withApi(async () => {
      setResult(await api.externalInfoRetrieve());
    });
  }, [withApi, makeAuthenticatedApi]);

  useEffect(() => {
    if (result || error) {
      // TODO(niklasb) event should be MessageEvent but TypeScript is fucked and produces
      // incorrect type errors
      registerHandler('getExternalInfoHandler', async (event: any) => {
        const respond = (data: ExternalInfoResponse) => {
          event.source.postMessage(data, event.origin);
        };

        if (event.data.method !== 'getExternalInfo') {
          // not meant for us
          return;
        }

        if (!trustedOrigins.includes(event.origin)) {
          respond({
            success: false,
            error: `sender origin not allowed: ${event.origin}`,
          });
          return;
        }

        if (error) {
          respond({ success: false, error: 'could not fetch data' });
        }

        respond({ success: true, result: result as ExternalInfo });
      });
    }

    // NOTE(niklasb) It's fine to not deregister here, since this page will be
    // loaded in an invisible frame and cannot be navigated away from
  }, [result, error, trustedOrigins]);

  return <></>;
};

export default ExternalInfoPage;
