import React, { FunctionComponent, ReactNode } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { Money } from 'ui/types/money';
import Translate from 'ui/atoms/translate';
import Number from 'ui/atoms/number';
import Currency from 'ui/atoms/currency';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Header from 'ui/atoms/header';
import CopyButton from 'ui/molecules/copy-button';
import Spacer from 'ui/atoms/spacer';

export interface StudioInvestmentInvestorDetailsProps {
  /** Additional classes. */
  className?: string;
  title?: ReactNode;
  name: string;
  email: string;
  investorId?: string;
  activeInvestmentsNo: number | null;
  activeInvestmentsSum: Money | null;
  isLegalPerson: boolean;
  custodianBank?: boolean;
}

const StudioInvestmentInvestorDetails: FunctionComponent<StudioInvestmentInvestorDetailsProps> = (props) => {
  const { name, email, investorId, activeInvestmentsNo, activeInvestmentsSum, title, isLegalPerson, custodianBank } =
    props;

  const isPhone = useIsMedia([DEVICES.phone]);

  return (
    <>
      {title && <Header size="small">{title}</Header>}
      {!title && investorId && (
        <>
          <InfoField title={name}>
            <p>
              <Translate name="studioInvestmentInvestorDetails.investorId" />
              <span>: {investorId} </span>
              <CopyButton valueToCopy={investorId} />
            </p>
          </InfoField>
          <Spacer y={4} />
        </>
      )}
      <InfoGrid columns={isPhone ? 1 : 3}>
        <InfoField title={<Translate name="common.emailShort" />}>{email}</InfoField>
        <InfoField title={<Translate name="studioInvestmentInvestorDetails.investments" />}>
          <PlaceholderFallback>
            {activeInvestmentsNo !== undefined && activeInvestmentsNo !== null && (
              <Number>{activeInvestmentsNo}</Number>
            )}
          </PlaceholderFallback>
        </InfoField>
        <InfoField title={<Translate name="studioInvestmentInvestorDetails.totalAmount" />}>
          <PlaceholderFallback>
            {activeInvestmentsSum && <Currency>{activeInvestmentsSum}</Currency>}
          </PlaceholderFallback>
        </InfoField>
        {isLegalPerson && (
          <InfoField title={<Translate name="studioInvestmentInvestorDetails.legalPerson" />}>
            <Translate
              name={`studioInvestmentInvestorDetails.${custodianBank ? 'custodianBank' : 'noCustodianBank'}`}
            />
          </InfoField>
        )}
      </InfoGrid>
    </>
  );
};

export default StudioInvestmentInvestorDetails;
