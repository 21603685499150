import { Cmd, loop, Loop, LoopReducerWithDefinedState } from 'redux-loop';
import { mergeAll } from 'lodash/fp';
import { ConfigActions, configFetchSuccess, configFetchFailure } from './actions';
import { getConfigEffect } from './effects';
import type { ConfigActionTypes, ConfigFetch, ConfigFetchFailure, ConfigFetchSuccess } from './actions';
import type { Config } from 'api';
import { WithRootActionTypes } from 'store/actions';

const emptyDoc = {
  id: '',
  name: '',
  file: '',
  updatedAt: new Date(),
  createdAt: new Date(),
};

const defaultServerConfig: Config = {
  hubspotId: '',
  networks: {},
  faq: [],
  investNowUrl: '',
  commonPasswordStrings: [],
  identificationFormDoc: null,
  identificationInPerson: true,
  allowVibCopy: false,
  cashlinkTosDocument: emptyDoc,
  effectaAccountSetupDocument: emptyDoc,
  effectaTermsConditions: emptyDoc,
  marketingOptsEnabled: [],
  friendVouchersEnabled: true,
  ewpgSendEmailsToInvestorsEnabled: true,
  bankAccountRequired: true,
  securitiesDepositAccountRequired: true,
  taxInformationRequired: true,
  telephoneNumberRequired: false,
  tanganyTosUrl: '',
  cashlinkWalletTosUrl: '',
  features: { canCreateInvestor: false },
  googleTagManagerId: '',
  platformData: {
    platformName: '',
    companyName: '',
    address: { street: '', zip: '', city: '' },
    tosUrl: '',
    privacyUrl: '',
    imprintUrl: '',
    supportEmail: '',
    supportPhone: '',
  },
  registerOnlyFlow: {
    walletStepEnabled: false,
    canUseTanganyWallet: false,
    canUseCashlinkWallet: false,
    canUseMobileWallet: false,
  },
};

export type ConfigStateShape = {
  data: Config;
  loading: boolean;
  loaded: boolean;
  error: Error | null;
};

export const initialState: ConfigStateShape = {
  data: defaultServerConfig,
  loaded: false,
  loading: false,
  error: null,
};

type ConfigLoop<A> = (state: ConfigStateShape, action: A) => ConfigStateShape | Loop<ConfigStateShape>;

const handleConfigFetch: ConfigLoop<ConfigFetch> = (state) => {
  return loop(
    mergeAll([state, { loading: true, error: false }]),
    Cmd.run(getConfigEffect, {
      successActionCreator: configFetchSuccess,
      failActionCreator: configFetchFailure,
    }),
  );
};

const handleConfigFetchSuccess: ConfigLoop<ConfigFetchSuccess> = (state, { data }) => {
  return mergeAll([
    state,
    {
      data,
      loading: false,
      loaded: true,
      error: null,
    },
  ]);
};

const handleConfigFetchFailure: ConfigLoop<ConfigFetchFailure> = (state, { error }) => {
  return mergeAll([
    state,
    {
      data: null,
      loading: false,
      loaded: true,
      error: error,
    },
  ]);
};

const reducer: LoopReducerWithDefinedState<ConfigStateShape, WithRootActionTypes<ConfigActionTypes>> = (
  state = initialState,
  action,
): ConfigStateShape | Loop<ConfigStateShape> => {
  switch (action.type) {
    case ConfigActions.CONFIG_FETCH:
      return handleConfigFetch(state, action);
    case ConfigActions.CONFIG_FETCH_SUCCESS:
      return handleConfigFetchSuccess(state, action);
    case ConfigActions.CONFIG_FETCH_FAILURE:
      return handleConfigFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
