import React, { FunctionComponent, useMemo, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Translate from 'ui/atoms/translate';

import ServerError from 'ui/types/server-error';
import { Document } from 'ui/types/document';

import { PersonType } from 'ui/types/person';
import Header from 'ui/atoms/header';
import { camelCase } from 'change-case';
import { compact } from 'lodash';
import DocumentModal from 'libraries/document-modal';
import Container from 'ui/atoms/container';
import { ProductType } from 'ui/types/asset';
import { Money } from 'ui/types/money';
import Section from 'ui/atoms/section';
import PaymentTotalListing from 'libraries/payment-total-listing';
import makeForm from 'ui/forms';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import { CustomCheckbox } from 'api/models';
import { GroupEnum } from 'api/models/GroupEnum';
import ConsiderationPeriodConfirmation from './consideration-period-confirmation';
import ActionButtons from 'ui/molecules/action-buttons';
import Spacer from 'ui/atoms/spacer';

export interface AcceptDocumentsFields {
  [key: string]: boolean;
}

export interface InvestmentCommitmentProps {
  /** Additional classes. */
  className?: string;

  /** Indicates a loading state */
  loading?: boolean;

  /** On submit callback */
  onSubmit?: () => void;

  personType?: PersonType;

  /** Error message */
  error?: ServerError;

  /** Investors can enter units (true) or investment amount (false) in the commitment step */
  useUnits: boolean;

  customCheckboxes: CustomCheckbox[];

  customCommitmentDocument?: Document;

  productName: string;
  productType: ProductType;
  disagio: Money;
  agio: Money | null;
  paymentTotal: Money;
  investmentTotal: Money;
  isEcsp: boolean;

  showAccruedInterest: boolean;

  accruedInterest: Money;
  tokenPrice: Money;

  costs?: {
    show?: boolean;
    document?: Document;
  };
}

const InvestmentCommitmentForm = makeForm<AcceptDocumentsFields>();

const InvestmentCommitment: FunctionComponent<InvestmentCommitmentProps> = (props) => {
  const {
    className,
    onSubmit = () => {},
    customCheckboxes,
    error,
    loading,
    productName,
    productType,
    costs,
    isEcsp,
    ...paymentTotalListingProps
  } = props;

  const [isPeriodConfirmationChecked, setPeriodConfirmationChecked] = useState(false);

  const groupedCheckboxes = useMemo(() => {
    const checkboxesByGroup: { [key: string]: CustomCheckbox[] } = {};
    Object.values(GroupEnum).forEach((group) => (checkboxesByGroup[group] = []));

    const costsAllowanceCheckbox = {
      i18nKey: 'costs',
      group: GroupEnum.LIABILITYUMBRELLA,
      document: costs?.document,
    };

    let checkboxesToDisplay = customCheckboxes.concat(costs?.show ? (costsAllowanceCheckbox as CustomCheckbox) : []);

    checkboxesToDisplay.forEach((checkbox) => {
      if (checkbox.group && checkboxesByGroup[checkbox.group]) {
        checkboxesByGroup[checkbox.group].push(checkbox);
      }
    });

    return Object.entries(checkboxesByGroup);
  }, [JSON.stringify(customCheckboxes), costs?.show, costs?.document]);

  return (
    <div className={cx('investment-commitment', className)}>
      <Section>
        <Container fluid={true} padding={false}>
          <Header size="small" spacing="none" breakWords={true}>
            {productName}
          </Header>
          <Translate as="p" name={`productType.${camelCase(productType)}`} />

          <PaymentTotalListing {...paymentTotalListingProps} isAccruedInterestFixed={false} />
        </Container>
      </Section>
      <InvestmentCommitmentForm onSubmit={onSubmit} error={error}>
        <Section>
          <Header as="h3" size="small" spacing="small">
            <Translate name="investmentCommitment.accept.title" />
          </Header>
          <Translate as="p" name="investmentCommitment.accept.description" />
        </Section>
        {groupedCheckboxes?.length > 0 &&
          groupedCheckboxes.map(([group, checkboxesToAccept], index) => {
            if (!checkboxesToAccept.length) return null;

            return (
              <Section key={index} spacing="none">
                <Header spacing="small" size="xsmall">
                  <Translate name={`investmentCommitment.group.${snakeToCamelCaseWithNumbers(group.toLowerCase())}`} />
                </Header>
                {checkboxesToAccept.map((checkboxToAccept, i) => {
                  if (!checkboxToAccept.document) return null;

                  return (
                    <InvestmentCommitmentForm.Group name={checkboxToAccept.i18nKey} required={true}>
                      <InvestmentCommitmentForm.Checkbox key={i}>
                        <Translate
                          name={`investmentCommitment.accept.${camelCase(checkboxToAccept.i18nKey)}`}
                          args={compact([checkboxToAccept.document]).map((document) => {
                            if (!document.id) return null;

                            return (text) => {
                              return (
                                <DocumentModal key={document.id} {...document}>
                                  {text}
                                </DocumentModal>
                              );
                            };
                          })}
                        />
                      </InvestmentCommitmentForm.Checkbox>
                    </InvestmentCommitmentForm.Group>
                  );
                })}
                <Spacer y={4} />
              </Section>
            );
          })}
        {isEcsp && (
          <ConsiderationPeriodConfirmation
            onChangeConfirmation={(isChecked) => setPeriodConfirmationChecked(isChecked)}
          />
        )}
        <ActionButtons>
          <InvestmentCommitmentForm.Submit
            variant="primary"
            size="large"
            loading={loading}
            disabled={isEcsp && !isPeriodConfirmationChecked}
          >
            <Translate name="investmentCommitment.makeOffer" />
          </InvestmentCommitmentForm.Submit>
        </ActionButtons>
      </InvestmentCommitmentForm>
    </div>
  );
};

export default InvestmentCommitment;
