import { InvestorStepEnum } from 'api/models';

export enum InvestorSidebarStep {
  ACCOUNT_DATA = 'accountData',
  QUESTIONNAIRE = 'questionnaire',
  KYC = 'kyc',
  WAITINGKYC = 'waitingKyc',
  DEPOT = 'depotSelection',
  ADDITIONAL_INFORMATION = 'registerAdditionalInformation',
  REGISTRATION_DONE = 'registrationDone',
}

export interface sidebarMappingInterface {
  sidebarStep?: InvestorSidebarStep;
  wizardSteps?: InvestorStepEnum[];
}

const getSidebarMapping = (isWalletStepEnabled?: boolean) => {
  return [
    {
      sidebarStep: InvestorSidebarStep.ACCOUNT_DATA,
      wizardSteps: [InvestorStepEnum.ACCOUNTSETUP, InvestorStepEnum.UNCONFIRMED],
    },
    {
      sidebarStep: InvestorSidebarStep.QUESTIONNAIRE,
      wizardSteps: [InvestorStepEnum.QUESTIONNAIRE],
    },
    {
      sidebarStep: InvestorSidebarStep.KYC,
      wizardSteps: [
        InvestorStepEnum.BENEFICIARY,
        InvestorStepEnum.IDENTIFICATION,
        InvestorStepEnum.RESUMEIDENTIFICATION,
        InvestorStepEnum.IDENTIFICATIONREJECTED,
        InvestorStepEnum.IDENTIFICATIONFAILED,
      ],
    },
    ...(isWalletStepEnabled
      ? [
          {
            sidebarStep: InvestorSidebarStep.DEPOT,
            wizardSteps: [
              InvestorStepEnum.SELECTWALLET,
              InvestorStepEnum.SETUPMOBILEWALLET,
              InvestorStepEnum.CONFIRMWALLET,
            ],
          },
        ]
      : []),
    {
      sidebarStep: InvestorSidebarStep.WAITINGKYC,
      wizardSteps: [InvestorStepEnum.WAITINGKYC],
    },
    {
      sidebarStep: InvestorSidebarStep.ADDITIONAL_INFORMATION,
      wizardSteps: [InvestorStepEnum.ADDITIONALINFORMATION, InvestorStepEnum.REGISTRATIONDONE],
    },
  ];
};

export default getSidebarMapping;
