import EmailConfirm from './email-confirm';
import InvestorRegistration from './investor-registration';
import InvestorRegistrationSuccess from './investor-registration-success';
import IssuerRegistration from './issuer-registration';
import IssuerRegistrationSuccess from './issuer-registration-success';
import type { ComponentRouting, RouteDefinitions } from 'core/routing/types';
import { string } from 'yup';

export const ROUTES = {
  emailConfirm: 'emailConfirm',
  investorRegistration: 'investorRegistration',
  investorRegistrationSuccess: 'investorRegistrationSuccess',
  issuerRegistration: 'issuerRegistration',
  issuerRegistrationSuccess: 'issuerRegistrationSuccess',
};

export const EMAIL_CONFIRM = {
  path: '/demo/issuer/activation/:id/:token/',
  exact: true,
  condition: () => true,
  requirements: {
    id: string().required(),
    token: string().required(),
  },
};

export const INVESTOR_REGISTRATION = {
  path: '/demo/register-investor/',
  exact: true,
  condition: () => true,
};

export const INVESTOR_REGISTRATION_SUCCESS = {
  path: '/demo/register-investor/success/',
  exact: true,
  condition: () => true,
};

export const ISSUER_REGISTRATION = {
  path: '/demo/registration/',
  exact: true,
  condition: () => true,
};

export const ISSUER_REGISTRATION_SUCCESS = {
  path: '/demo/registration/success/:encodedEmail/',
  exact: true,
  condition: () => true,
  requirements: {
    encodedEmail: string().required(),
  },
};

export const DEMO_ROUTES: RouteDefinitions = {
  emailConfirm: EMAIL_CONFIRM,
  investorRegistration: INVESTOR_REGISTRATION,
  investorRegistrationSuccess: INVESTOR_REGISTRATION_SUCCESS,
  issuerRegistration: ISSUER_REGISTRATION,
  issuerRegistrationSuccess: ISSUER_REGISTRATION_SUCCESS,
};

export const DEMO_PATH_LIST = Object.values(DEMO_ROUTES).map((route) => route.path);

export const DEMO_COMPONENT_ROUTING: ComponentRouting = {
  emailConfirm: EmailConfirm,
  investorRegistration: InvestorRegistration,
  investorRegistrationSuccess: InvestorRegistrationSuccess,
  issuerRegistration: IssuerRegistration,
  issuerRegistrationSuccess: IssuerRegistrationSuccess,
};
