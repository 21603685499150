import { PermissionsEnum } from 'api';
import type { InvestmentToken, InvestmentTokenForInvestor } from 'api';

type permissionsTokenType = InvestmentTokenForInvestor | InvestmentToken | null | undefined;
type permissionsTokenArrayType = InvestmentTokenForInvestor[] | InvestmentToken[] | null | undefined;

export const userHasPermission = (tokens: permissionsTokenArrayType, permission: PermissionsEnum) =>
  !!tokens && tokens.some((token) => !!token?.permissions.find((perm) => perm === permission));

export const userHasPermissionForAllProducts = (tokens: permissionsTokenArrayType, permission: PermissionsEnum) =>
  !!tokens && tokens.every((token) => !!token?.permissions.find((perm) => perm === permission));

export const currentTokenHasPermission = (token: permissionsTokenType, permission: PermissionsEnum) =>
  !!token?.permissions.find((perm) => perm === permission);

export const hasViewPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ViewData);

export const hasManageCreditDigitalSecuritiesPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ManageCreditDigitalSecurities);

export const hasManageDataPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ManageData);

export const hasManageDistributionPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ManageDistribution);

export const hasManageIncomingPaymentsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ManageIncomingPayments);

export const hasManageTriggerSolutionPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.TriggerSolution);

export const hasManageApprovalsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ManageApprovals);

export const hasDownloadStatisticsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.DownloadStatistics);

export const hasManageRedemptionsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, PermissionsEnum.ManageRedemptions);

export const hasViewPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ViewData);

export const hasManageCreditDigitalSecuritiesPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageCreditDigitalSecurities);

export const hasManageDataPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageData);

export const hasManageDistributionPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageDistribution);

export const hasManageIncomingPaymentsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageIncomingPayments);

export const hasManageApprovalsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageApprovals);

export const hasDownloadStatisticsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.DownloadStatistics);

export const hasManageRedemptionsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageRedemptions);

export const hasManageVouchersPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, PermissionsEnum.ManageVouchers);

export const currentTokenHasViewPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ViewData);

export const currentTokenHasManageCreditDigitalSecuritiesPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageCreditDigitalSecurities);

export const currentTokenHasManageDataPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageData);

export const currentTokenHasManageDistributionPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageDistribution);

export const currentTokenHasManageIncomingPaymentsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageIncomingPayments);

export const currentTokenHasManageApprovalsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageApprovals);

export const currentTokenHasDownloadStatisticsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.DownloadStatistics);

export const currentTokenHasManageRedemptionsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageRedemptions);

export const currentTokenHasManageVouchersPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, PermissionsEnum.ManageVouchers);
