import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';

export enum CancellationModalType {
  CANCELLATION,
  REJECTION,
  KYC_OR_PAYMENT_MISSING,
  COMPLIANCE_CANCELLATION,
}
export interface InvestmentCancellationModalProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  onCancelInvestment: () => void;

  onClose: () => void;

  type: CancellationModalType;

  loading?: boolean;

  reason?: ReactNode;
}

const InvestmentCancellationModal: FunctionComponent<InvestmentCancellationModalProps> = (props) => {
  const { className, onCancelInvestment, onClose, loading, type, reason } = props;

  const [successCancel, setSuccessCancel] = useState(false);

  const onCloseModal = useCallback(async () => {
    setSuccessCancel(false);
    return onClose();
  }, [onClose]);

  const onCancel = useCallback(async () => {
    await onCancelInvestment();
    setSuccessCancel(true);
  }, [onClose, onCancelInvestment]);

  const getTranslationForModalType = () => {
    switch (type) {
      case CancellationModalType.REJECTION:
        return 'issuerInvestmentRejectModal';
      case CancellationModalType.KYC_OR_PAYMENT_MISSING:
        return 'issuerInvestmentKycOrPaymentMissingModal';
      case CancellationModalType.CANCELLATION:
        return 'issuerInvestmentCancellationModal';
      case CancellationModalType.COMPLIANCE_CANCELLATION:
        return 'issuerInvestmentCancellationComplianceModal';
      default:
        return 'issuerInvestmentCancellationModal';
    }
  };

  return (
    <Modal onClose={onClose} className={cx('investment-cancellation-modal', className)}>
      <ModalHeader>
        <Translate name={`${getTranslationForModalType()}.title`} />
      </ModalHeader>
      <ModalContent>
        {successCancel ? (
          <>
            <Hint variant="success">
              <Translate name={`${getTranslationForModalType()}.successMessage`} />
            </Hint>
          </>
        ) : (
          <>
            <p>
              <Translate name={`${getTranslationForModalType()}.description`} />
            </p>
            {!!reason && (
              <>
                <Translate as="strong" name="issuerInvestmentCancellationComplianceModal.reason" />
                <p>{reason}</p>
              </>
            )}
            <Hint variant="warning">
              <Translate name="issuerInvestmentCancellationModal.info" />
            </Hint>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={
            successCancel
              ? [
                  {
                    name: 'cancel',
                    content: <Translate name="issuerInvestmentCancellationModal.successButton" />,
                    variant: 'primary',
                    size: 'large',
                    onClick: onCloseModal,
                  },
                ]
              : [
                  {
                    name: 'cancelInvestment',
                    content: <Translate name={`${getTranslationForModalType()}.button`} />,
                    loading: loading,
                    size: 'large',
                    onClick: onCancel,
                  },
                  {
                    name: 'cancel',
                    content: <Translate name="issuerInvestmentCancellationModal.cancel" />,
                    variant: 'primary',
                    size: 'large',
                    onClick: onCloseModal,
                  },
                ]
          }
        />
      </ModalFooter>
    </Modal>
  );
};

export default InvestmentCancellationModal;
