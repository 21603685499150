import React, { FunctionComponent } from 'react';
import { SalutationEnum } from 'api';
import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import { validateAlphanumericZIP } from 'ui/helper/validators';
import Translate from 'ui/atoms/translate';
import Grid, { Col } from 'ui/atoms/grid';
import useCountries from 'src/hooks/use-countries';

export interface BenefitingPersonFields {
  salutation: string;
  forename: string;
  surname: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  birthDate: Date;
  birthPlace: Date;
}

export interface RegisterInvestorBenefitingPersonModalProps {
  /** On submit callback */
  onSubmit?: (values: BenefitingPersonFields) => void;

  open?: boolean;

  isEditMode?: boolean;

  onCancelTriggered?: () => void;

  /** Benefiting Persons can have an initial state */
  initialState?: Partial<BenefitingPersonFields>;
}

const RegisterInvestorBenefitingPersonModalForm = makeModalForm<BenefitingPersonFields>();

const RegisterInvestorBenefitingPersonModal: FunctionComponent<RegisterInvestorBenefitingPersonModalProps> = (
  props,
) => {
  const { isEditMode, initialState = {}, onSubmit = () => {}, onCancelTriggered = () => {} } = props;

  return (
    <Modal onClose={onCancelTriggered}>
      <RegisterInvestorBenefitingPersonModalForm onSubmit={onSubmit} initial={initialState} i18nKey="benefitingPersons">
        <ModalHeader>
          {isEditMode ? (
            <Translate name="benefitingPersons.editPerson">Person bearbeiten</Translate>
          ) : (
            <Translate name="benefitingPersons.addPerson">Person hinzufügen</Translate>
          )}
        </ModalHeader>
        <ModalContent>
          <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="salutation">
            <RegisterInvestorBenefitingPersonModalForm.GroupToggle>
              {Object.values(SalutationEnum).map((salutation, index) => (
                <RegisterInvestorBenefitingPersonModalForm.Radio value={salutation} key={index}>
                  <Translate name={`personalInformation.salutations.${salutation.toLowerCase()}`} />
                </RegisterInvestorBenefitingPersonModalForm.Radio>
              ))}
            </RegisterInvestorBenefitingPersonModalForm.GroupToggle>
          </RegisterInvestorBenefitingPersonModalForm.Group>

          <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="forename">
            <RegisterInvestorBenefitingPersonModalForm.Input autoFocus={true} />
          </RegisterInvestorBenefitingPersonModalForm.Group>
          <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="surname">
            <RegisterInvestorBenefitingPersonModalForm.Input />
          </RegisterInvestorBenefitingPersonModalForm.Group>
          <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="street">
            <RegisterInvestorBenefitingPersonModalForm.Input />
          </RegisterInvestorBenefitingPersonModalForm.Group>
          <Grid>
            <Col width={4}>
              <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="zip">
                <RegisterInvestorBenefitingPersonModalForm.Input />
                <RegisterInvestorBenefitingPersonModalForm.Validation validate={validateAlphanumericZIP} />
              </RegisterInvestorBenefitingPersonModalForm.Group>
            </Col>
            <Col width={8}>
              <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="city">
                <RegisterInvestorBenefitingPersonModalForm.Input />
              </RegisterInvestorBenefitingPersonModalForm.Group>
            </Col>
          </Grid>
          <RegisterInvestorBenefitingPersonModalForm.Group required={true} name="country">
            <RegisterInvestorBenefitingPersonModalForm.Select
              options={useCountries()}
              value={initialState.country}
            ></RegisterInvestorBenefitingPersonModalForm.Select>
          </RegisterInvestorBenefitingPersonModalForm.Group>
          <Grid>
            <Col width={8}>
              <RegisterInvestorBenefitingPersonModalForm.Group name="birthDate" required={true}>
                <RegisterInvestorBenefitingPersonModalForm.DateInput max={new Date()} />
                {RegisterInvestorBenefitingPersonModalForm.Validators.Over18()}
              </RegisterInvestorBenefitingPersonModalForm.Group>
            </Col>
          </Grid>
          <Grid>
            <Col width={8}>
              <RegisterInvestorBenefitingPersonModalForm.Group name="birthPlace" required={true} occupyHiddenLabelSpace>
                <RegisterInvestorBenefitingPersonModalForm.Input />
              </RegisterInvestorBenefitingPersonModalForm.Group>
            </Col>
          </Grid>
          <RegisterInvestorBenefitingPersonModalForm.GenericErrorMessages />
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.cancel" />,
                size: 'large',
                onClick: onCancelTriggered,
              },
              {
                name: 'set',
                content: isEditMode ? (
                  <Translate name="benefitingPersons.editPerson" />
                ) : (
                  <Translate name="benefitingPersons.addPerson" />
                ),
                variant: 'primary',
                type: 'submit',
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </RegisterInvestorBenefitingPersonModalForm>
    </Modal>
  );
};

export default RegisterInvestorBenefitingPersonModal;
