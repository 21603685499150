import React, { FunctionComponent } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import ProductsTable from 'apps/issuer/pages/products/products-table';
import useGoTo from 'hooks/use-go-to';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { useProducts, useProductsFilter } from 'apps/issuer/hooks';
import Link from 'core/routing/link';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { FilterInputOption, FilterPopup, FilterRange } from 'ui/molecules/filtering';
import FilterMultiSelect from 'ui/molecules/filtering/filter-popup/filter-multi-select';

const IssuerInvestorDetailsPageFilters: FunctionComponent = () => {
  const translate = useTranslateWithStringArgs();

  const filterLabels = {
    totalVolume: 'totalVolume',
    totalVolumeMin: 'totalVolumeMin',
    totalVolumeMax: 'totalVolumeMax',
  };

  const { tokens } = useProducts();
  const { productsFilter } = useProductsFilter(tokens);

  return (
    <>
      <FilterPopup>
        <FilterMultiSelect
          label={translate('studioInvestmentsTable.product')}
          options={productsFilter}
          input="id"
          defaultField="id"
        />
        <FilterRange
          label={translate('filters.labels.totalVolume')}
          inputNames={[filterLabels.totalVolumeMin, filterLabels.totalVolumeMax]}
          filterName={filterLabels.totalVolume}
        >
          <FilterInputOption
            input={filterLabels.totalVolumeMin}
            pairedInput={filterLabels.totalVolumeMax}
            type="number"
            label={null}
            defaultField={filterLabels.totalVolumeMin}
            name={filterLabels.totalVolume}
          />
          <FilterInputOption
            input={filterLabels.totalVolumeMax}
            pairedInput={filterLabels.totalVolumeMin}
            type="number"
            label={null}
            defaultField={filterLabels.totalVolumeMax}
            name={filterLabels.totalVolume}
          />
        </FilterRange>
      </FilterPopup>
    </>
  );
};

const IssuerProductsPage = () => {
  const { loading, tokens } = useProducts();

  const goToProductDetails = useGoTo(ISSUER_ROUTES.productDetails);

  // this should never happen though
  if (!tokens) {
    return null;
  }

  return (
    <WideContent title={<Translate name="dashboard.issuerProducts" />}>
      <Section spacing="medium">
        <Translate name="issuerProducts.subtitle" />
      </Section>
      <Section spacing="medium">
        <ProductsTable
          goToDetails={(productId: string) => goToProductDetails({ productId })}
          loading={loading}
          filters={<IssuerInvestorDetailsPageFilters />}
        />
      </Section>
      <Link icon="arrow-left" to="/dashboard/issuerOverview">
        <Translate as="span" name="studioConfirmMultiples.action.backToOverview" />
      </Link>
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerProductsPage, AppType.ISSUER);
