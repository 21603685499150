import Table from 'ui/molecules/table';
import { resolveColor } from 'ui/styles/utils/colors';
import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const SmallFont = styled(Table.Cell)`
  font-size: 0.64rem;
`;

export const PaymentTotalListingContainer = styled.div`
  border-radius: 8px;
  text-align: start;
  padding-top: 1rem;

  ${query.largeDesktops`
    background-color: ${({ theme }) => resolveColor(theme, 'white')};
    width: 100%;
    padding: 0.75rem 1rem;
  `}
`;

export const SummaryTable = styled(Table)`
  margin: 0px;
  width: 100%;
`;

export const Cell = styled(Table.Cell)`
  height: auto;
  padding: 0.25rem 0 !important;
`;

export const Row = styled(Table.Row)`
  &:last-child > ${Cell} {
    padding-bottom: 0.5rem !important;
  }
`;

export const FootRow = styled(Table.Row)`
  &:last-child > ${Cell} {
    padding-top: 0.5rem !important;
  }
`;
