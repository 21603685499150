import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Modal, ModalContent, ModalFooter, ModalFooterButtons, ModalHeader } from 'ui/molecules/modal';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import { InvitationsApi, InvestmentsApi } from 'api';
import useApiCall from 'src/hooks/use-api-call';

export interface CancelInvestmentProps {
  setConfirmCancelOpen: (param: boolean) => void;
  isInvitation: boolean;
  investmentId: string;
  setInvestmentsAfterCancel: (investmentId: string) => void;
}

const CancelInvestment: FunctionComponent<CancelInvestmentProps> = (props) => {
  const { setInvestmentsAfterCancel, setConfirmCancelOpen, isInvitation, investmentId } = props;

  const { withApi: withAbortionApi, makeAuthenticatedApi: makeAuthenticatedAbortionApi } = useApiCall(true);

  const [successCancel, setSuccessCancel] = useState(false);

  const investmentsAbortionApi: InvestmentsApi = useMemo(
    () => makeAuthenticatedAbortionApi(InvestmentsApi),
    [makeAuthenticatedAbortionApi],
  );
  const invitationsAbortionApi: InvitationsApi = useMemo(
    () => makeAuthenticatedAbortionApi(InvitationsApi),
    [makeAuthenticatedAbortionApi],
  );

  const onCloseModal = useCallback(async () => {
    setSuccessCancel(false);
    setConfirmCancelOpen(false);
  }, [setSuccessCancel, setConfirmCancelOpen]);

  const onCancel = useCallback(async () => {
    if (isInvitation) {
      const response = await onDeleteInvitation(investmentId);
      setSuccessCancel(response || false);
    } else {
      const response = await onDeleteInvestment(investmentId);
      setSuccessCancel(response || false);
    }
  }, [investmentId, isInvitation]);

  const onDeleteInvestment = useCallback(
    (investmentId) => {
      return withAbortionApi(async () => {
        try {
          await investmentsAbortionApi.investmentsAbortionCreate({
            id: investmentId,
          });
          setInvestmentsAfterCancel(investmentId);
          return true;
        } catch (e) {
          return false;
        }
      });
    },
    [setInvestmentsAfterCancel, withAbortionApi, investmentsAbortionApi],
  );

  const onDeleteInvitation = useCallback(
    (invitationId) => {
      return withAbortionApi(async () => {
        try {
          await invitationsAbortionApi.invitationsAbortionCreate({
            id: invitationId,
          });
          setInvestmentsAfterCancel(investmentId);
          return true;
        } catch (e) {
          return false;
        }
      });
    },
    [withAbortionApi, invitationsAbortionApi, setInvestmentsAfterCancel],
  );

  return (
    <Modal onClose={onCloseModal}>
      <ModalHeader>
        <Translate name={'investorInvestmentCancellationModal.title'} />
      </ModalHeader>
      <ModalContent>
        {successCancel ? (
          <>
            <Hint variant="success">
              <Translate name={'investorInvestmentCancellationModal.successMessage'} />
            </Hint>
          </>
        ) : (
          <>
            <p>
              <Translate name={'investorInvestmentCancellationModal.description'} />
            </p>
            <Hint variant="warning">
              <Translate name="investorInvestmentCancellationModal.info" />
            </Hint>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={
            successCancel
              ? [
                  {
                    name: 'cancel',
                    content: <Translate name="investorInvestmentCancellationModal.return" />,
                    variant: 'primary',
                    size: 'large',
                    onClick: onCloseModal,
                  },
                ]
              : [
                  {
                    name: 'cancelInvestment',
                    content: <Translate name={'investorInvestmentCancellationModal.button'} />,
                    size: 'large',
                    onClick: onCancel,
                  },
                  {
                    name: 'cancel',
                    content: <Translate name="investorInvestmentCancellationModal.cancel" />,
                    variant: 'primary',
                    size: 'large',
                    onClick: onCloseModal,
                  },
                ]
          }
        />
      </ModalFooter>
    </Modal>
  );
};

export default CancelInvestment;
