import { Money, CurrencyEnum } from './money';
import { toNumber } from '../helper/money';

import BigNumber from 'bignumber.js';

export class MoneyValue {
  bigNumber: BigNumber;
  currency: CurrencyEnum;

  static cache: { [key: string]: MoneyValue } = {};

  static fromMoney(money: Money): MoneyValue {
    const key = JSON.stringify(money);
    if (key in this.cache) {
      return this.cache[key];
    }
    return (this.cache[key] = new this(new BigNumber(toNumber(`${money.amount}e-${money.decimals}`)), money.currency));
  }

  constructor(bigNumber: BigNumber, currency: CurrencyEnum) {
    this.currency = currency;
    this.bigNumber = bigNumber;
  }

  mod(value: MoneyValue): BigNumber {
    if (this.currency !== value.currency) {
      throw Error('Currencies of MoneyValues differ, modulus cannot be calculated');
    }
    return this.bigNumber.mod(value.bigNumber);
  }

  gt(value: MoneyValue): boolean {
    if (this.currency !== value.currency) {
      throw Error('Currencies of MoneyValues differ, greater than cannot be calculated');
    }
    return this.bigNumber.gt(value.bigNumber);
  }

  lt(value: MoneyValue): boolean {
    if (this.currency !== value.currency) {
      throw Error('Currencies of MoneyValues differ, lower than cannot be calculated');
    }
    return this.bigNumber.lt(value.bigNumber);
  }

  mul(value: BigNumber): MoneyValue {
    return new MoneyValue(this.bigNumber.times(value), this.currency);
  }

  div(value: MoneyValue): BigNumber {
    if (this.currency !== value.currency) {
      throw Error('Currencies of MoneyValues differ, division cannot be performed');
    }
    return this.bigNumber.div(value.bigNumber);
  }

  get decimals(): number {
    return this.bigNumber.decimalPlaces();
  }

  get amount(): string {
    return this.bigNumber.toString(10).replace('.', '');
  }
}
