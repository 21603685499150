import React, { FunctionComponent } from 'react';
import { camelCase } from 'change-case';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { InvestorInvestmentStatusEnum } from 'ui/types/investment';
import makeLink from 'helper/make-link';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import { assertUnreachable } from 'ui/helper/unreachable';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { StepEnum } from 'src/api';

const hasOpenInvestmentProcessAction = (status: InvestorInvestmentStatusEnum): boolean => {
  switch (status) {
    case InvestorInvestmentStatusEnum.PreInvestmentAmount:
    case InvestorInvestmentStatusEnum.InvestmentAmountMissing:
    case InvestorInvestmentStatusEnum.QuestionnaireMissing:
    case InvestorInvestmentStatusEnum.VibMissing:
    case InvestorInvestmentStatusEnum.OfferConfirmationMissing:
    case InvestorInvestmentStatusEnum.BenefitingPersonsMissing:
    case InvestorInvestmentStatusEnum.IdentificationMissing:
    case InvestorInvestmentStatusEnum.IdentificationRejected:
    case InvestorInvestmentStatusEnum.PaymentOutstanding:
    case InvestorInvestmentStatusEnum.WalletSetupMissing:
    case InvestorInvestmentStatusEnum.AdditionalInformationMissing:
      return true;
    case InvestorInvestmentStatusEnum.WaitingAcceptance:
    case InvestorInvestmentStatusEnum.IdentificationPending:
    case InvestorInvestmentStatusEnum.IdentificationFailed:
    case InvestorInvestmentStatusEnum.Finished:
    case InvestorInvestmentStatusEnum.WalletSetupPending:
    case InvestorInvestmentStatusEnum.WaitingSecurityTransfer:
    case InvestorInvestmentStatusEnum.SecuritiesTransferredToCustomWallet:
    case InvestorInvestmentStatusEnum.SecuritiesTransferredToManagedWallet:
    case InvestorInvestmentStatusEnum.SuccessFullyTransfered:
    case InvestorInvestmentStatusEnum.SuccessPartiallyTransfered:
    case InvestorInvestmentStatusEnum.SuccessRedeemed:
    case InvestorInvestmentStatusEnum.Handover:
    case InvestorInvestmentStatusEnum.Canceled:
      return false;
  }
  return assertUnreachable(status);
};

export interface InvestorStatusActionButtonProps {
  status: InvestorInvestmentStatusEnum;
  invitationId: string;
  canAbort?: boolean;
  onCancelInvestment?: () => void;
  areAllTokensUnavailable?: boolean;
  areTokensPartiallyAvailable?: boolean;
}

const InvestorInvestmentActionButtons: FunctionComponent<InvestorStatusActionButtonProps> = ({
  status,
  invitationId,
  canAbort,
  onCancelInvestment = () => false,
  areAllTokensUnavailable,
  areTokensPartiallyAvailable,
}) => {
  const dispatch = useDispatch();

  if (areTokensPartiallyAvailable) {
    return (
      <ActionButtons>
        {canAbort && (
          <Button variant="link" onClick={onCancelInvestment}>
            <Translate name="dashboardInvestorContinueInvestments.abort" />
          </Button>
        )}
        <Button
          variant="primary"
          onClick={() =>
            dispatch(
              push(
                makeLink(INVESTMENT_ROUTES.investment, {
                  invitationId: invitationId,
                }) +
                  '?step=' +
                  StepEnum.INVESTMENTAMOUNT,
              ),
            )
          }
        >
          <Translate name={'investorInvestmentStatus.tokensPartiallyUnavailable.action'} />
        </Button>
      </ActionButtons>
    );
  }

  return (
    <>
      <ActionButtons>
        {canAbort && (
          <Button variant="link" onClick={onCancelInvestment}>
            <Translate name="dashboardInvestorContinueInvestments.abort" />
          </Button>
        )}
        {hasOpenInvestmentProcessAction(status) && !areAllTokensUnavailable && (
          <Button
            variant="primary"
            onClick={() =>
              dispatch(
                push(
                  makeLink(INVESTMENT_ROUTES.investment, {
                    invitationId: invitationId,
                  }),
                ),
              )
            }
          >
            <Translate name={`investorInvestmentStatus.${camelCase(status)}.action`} />
          </Button>
        )}
      </ActionButtons>
    </>
  );
};

export default InvestorInvestmentActionButtons;
