import React, { FunctionComponent, ReactNode, useCallback, useState, useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Input from 'ui/atoms/input';
import useApiCall from 'hooks/use-api-call';
import { AdminApi, AdminInvestmentVoucher } from 'api';
import Currency from 'ui/atoms/currency';
import { Money } from 'ui/types/money';
import Header from 'ui/atoms/header';
import Segment from 'ui/atoms/segment';

export interface AddVoucherModalProps {
  /** Additional classes. */
  className?: string;

  title: ReactNode;

  investmentId: string;

  onVoucherAdd: (voucher: AdminInvestmentVoucher | null) => void;

  onHideModal: () => void;
}

const AddVoucherModal: FunctionComponent<AddVoucherModalProps> = ({
  onVoucherAdd,
  onHideModal,
  title,
  investmentId,
}) => {
  const { withApi, makeAuthenticatedApi } = useApiCall();

  const investmentsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const [voucherCode, setVoucherCode] = useState('');
  // auxiliary value used since we want to wait for 500ms before sending the request to the server
  const [voucherCodeWithoutDelay, setVoucherCodeWithDelay] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [disagio, setDisagio] = useState<Money | null>(null);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    voucherCodeWithoutDelay.length > 0 ? setIsValidating(true) : setIsValidating(false);
    const timeout = setTimeout(() => setVoucherCode(voucherCodeWithoutDelay), 500);
    return () => clearTimeout(timeout);
  }, [voucherCodeWithoutDelay]);

  useEffect(() => {
    if (!voucherCode.length) return;

    withApi(async () => {
      const { isValid, disagio } = await investmentsApi.adminVouchersValidatorCreate({
        adminVoucherValidatorRequest: {
          voucherCode,
          investmentId,
        },
      });
      setIsValid(isValid);
      setIsValidating(false);
      setShowHint(true);
      setDisagio(disagio);
    });
  }, [voucherCode]);

  const addVoucher = useCallback(() => {
    withApi(async () => {
      const investment = await investmentsApi.adminInvestmentsPartialUpdate({
        id: investmentId,
        patchedAdminInvestmentDetailsRequest: {
          voucherCode,
        },
      });

      onVoucherAdd(investment.voucher);
      onHideModal();
    });
  }, [voucherCode]);

  return (
    <Modal onClose={onHideModal}>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        <Hint variant="info">
          <Translate name="addVoucherModal.info" />
        </Hint>
        <Translate name="addVoucherModal.voucherCode" as="strong" />
        <Input onChange={(e: any) => setVoucherCodeWithDelay(e.target.value)} validating={isValidating} />
        {showHint && isValid && disagio && (
          <Hint variant="success">
            <Segment basic={true} padded="none">
              <Header as="h3" size="small">
                <Translate
                  name="addVoucherModal.success.title"
                  args={[voucherCode, (_, key) => <Currency key={key}>{disagio}</Currency>]}
                />
              </Header>
              <Translate
                as="p"
                name="addVoucherModal.success.description"
                args={[(_, key) => <Currency key={key}>{disagio}</Currency>]}
              />
            </Segment>
          </Hint>
        )}
        {showHint && !isValid && (
          <Hint variant="danger">
            <Translate name="addVoucherModal.error" />
          </Hint>
        )}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.close" />,
              size: 'large',
              onClick: onHideModal,
            },
            {
              name: 'add',
              content: <Translate name="common.add" />,
              variant: 'primary',
              type: 'submit',
              size: 'large',
              disabled: !isValid,
              onClick: addVoucher,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddVoucherModal;
