import { Cmd } from 'redux-loop';
import { InvestmentsApi, InvestmentsListStatusEnum, InvitationsApi } from 'api';
import { makeAuthenticatedApi } from 'core/api';
import { currentUserSelector } from 'core/auth/selectors';
import type { RootAppState } from 'store/types';

export async function getHasInvestmentsEffect(getState: Cmd.GetState): Promise<boolean> {
  const { currentUser } = currentUserSelector(getState<RootAppState>());

  if (!currentUser?.investor) {
    return Promise.reject(
      'Attempted to fetch investment data before user was logged in. This is a no-op. User has to be logged in for investment data to be shown.',
    );
  }

  const investmentsApi: InvestmentsApi = makeAuthenticatedApi(InvestmentsApi, getState);
  const invitationsApi: InvitationsApi = makeAuthenticatedApi(InvitationsApi, getState);

  const [invitations, investments] = await Promise.all([
    invitationsApi.invitationsList({
      status: InvestmentsListStatusEnum.Open,
      limit: 2,
    }),
    investmentsApi.investmentsList({
      status: InvestmentsListStatusEnum.All,
      limit: 2,
    }),
  ]);

  return !!invitations.count || !!investments.count;
}

export async function getHasEcspInvitationsEffect(getState: Cmd.GetState): Promise<boolean> {
  const { currentUser } = currentUserSelector(getState<RootAppState>());

  if (!currentUser?.investor) {
    return Promise.reject(
      'Attempted to fetch investment data before user was logged in. This is a no-op. User has to be logged in for investment data to be shown.',
    );
  }

  const invitationsApi: InvitationsApi = makeAuthenticatedApi(InvitationsApi, getState);

  const invitations = await invitationsApi.invitationsList({
    isEcsp: true,
    limit: 1,
  });

  return !!invitations.count;
}
