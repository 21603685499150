import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import PaymentTotalListing from 'src/libraries/payment-total-listing';
import Hint from 'src/ui/atoms/hint';
import Translate from 'src/ui/atoms/translate';
import { Money } from 'ui/types/money';

export interface ConsiderationPeriodProps {
  /** Amount of securities */
  amount: number;

  /** tokenPrice per security */
  tokenPrice: Money | null;

  /** Investment amount */
  investmentTotal: Money | null;

  /** Payment amount */
  paymentTotal: Money | null;

  /** Disagio amount */
  disagio: Money | null;

  /** Agio amount */
  agio: Money | null;

  /** Accrued interest */
  accruedInterest: Money;

  /** payment information */
  paymentInformation?: {
    /** Account holder */
    accountHolder: string;

    /** Iban */
    iban: string;

    /** BIC */
    bic: string;

    /** Reference text */
    referenceText: string;
  };

  /** Additional classes. */
  className?: string;

  useUnits: boolean;

  showAccruedInterest: boolean;

  /** As long as investment is not accepted, accrued interest might still change. */
  isAccruedInterestFixed: boolean;

  considerationStartedAt: Date;
}

const CONSIDERATION_HOURS = 96;

const ConsiderationPeriod: FunctionComponent<ConsiderationPeriodProps> = (props) => {
  const { considerationStartedAt, ...paymentTotalListingProps } = props;

  const [hoursLeft, setHoursLeft] = useState(0);

  useEffect(() => {
    var duration = moment.duration(moment().diff(considerationStartedAt));
    var hours = Math.ceil(CONSIDERATION_HOURS - duration.asHours());
    setHoursLeft(hours);
  }, []);

  return (
    <div>
      <PaymentTotalListing {...paymentTotalListingProps} showUnitsInfoIfConfigured={true} />
      <Hint variant="success">
        {hoursLeft > 0 ? (
          <Translate name="considerationPeriodStatus.info" args={[hoursLeft.toString()]} />
        ) : (
          <Translate name="considerationPeriodStatus.timeExpiredInfo" />
        )}
      </Hint>
    </div>
  );
};

export default ConsiderationPeriod;
