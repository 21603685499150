import { AdminApi, AdminInvestor, UsersApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import useGoTo from 'hooks/use-go-to';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTranslate from 'ui/hooks/use-translate';

export const useDeleteAccount = (investor: AdminInvestor, onModalClose: () => void) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [email, setEmail] = useState<string>();
  const [requestFutureDeletion, setRequestFutureDeletion] = useState(false);
  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [error, setError] = useState(false);

  const translate = useTranslate();
  const { makeAuthenticatedApi, withApi } = useApiCall();
  const goToInvestors = useGoTo({ path: '/dashboard/issuerInvestors' });

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  useEffect(() => {
    if (moment().diff(investor.earliestDeletion, 'days') >= 0) {
      setRequestFutureDeletion(false);
    } else {
      setRequestFutureDeletion(true);
    }
  }, [investor.earliestDeletion]);

  useEffect(() => {
    withApi(async () => {
      const { email } = await usersApi.usersMeRetrieve();
      setEmail(email);
    });
  }, [usersApi, withApi]);

  const getModalHeader = useCallback(() => {
    if (deletionSuccess) {
      return requestFutureDeletion
        ? `${translate('dashboardIssuerInvestorDetails.futureDeletion.deactivateAccountTitle')}`
        : `${translate('dashboardIssuerInvestorDetails.deleteAccount.deleted')}`;
    }
    return requestFutureDeletion
      ? `${translate('dashboardIssuerInvestorDetails.futureDeletion.deactivateTitle')} - ${investor.name} (${
          investor.email
        })`
      : `${translate('dashboardIssuerInvestorDetails.deleteAccount.delete')} - ${investor.name} (${investor.email})`;
  }, [deletionSuccess, requestFutureDeletion, investor.name, investor.email]);

  const onConfirm = useCallback(async () => {
    try {
      if (!requestFutureDeletion) {
        await adminApi.adminInvestorsDestroy({ id: investor.id });
      } else {
        await adminApi.adminInvestorsPendingDeletionCreate({
          id: investor.id,
          adminPendingInvestorDeletionRequest: {
            investor: investor.id,
            requestingUser: {
              email: email || '',
            },
            earliestDeletionDatetime: investor.earliestDeletion,
          },
        });
      }
      setDeletionSuccess(true);
    } catch (e) {
      setError(true);
    }
  }, [email, investor?.id, investor?.earliestDeletion, requestFutureDeletion, adminApi]);

  const onBack = () => goToInvestors();
  const onClose = () => onModalClose();

  const canDeleteInvestor = useCallback(() => {
    return investor.activeInvestmentsNo === 0 && investor.wallets.length === 0;
  }, [investor.activeInvestmentsNo, investor.wallets.length]);

  return {
    error,
    deletionSuccess,
    requestFutureDeletion,
    isConfirmed,
    setIsConfirmed,
    canDeleteInvestor,
    getModalHeader,
    onConfirm,
    onBack,
    onClose,
  };
};
