import { InvestorStepEnum } from 'api';
import { assertUnreachable } from 'ui/helper/unreachable';
import { ColorVariants } from 'ui/styles/themes/color-variants';

export enum InvestorRegistrationStatusEnum {
  AccountSetup = 'AccountSetup',
  InvestmentAmountMissing = 'InvestmentAmountMissing',
  QuestionnaireMissing = 'QuestionnaireMissing',
  BenefitingPersonsMissing = 'BenefitingPersonsMissing',
  IdentificationMissing = 'IdentificationMissing',
  IdentificationRejected = 'IdentificationRejected',
  IdentificationPending = 'IdentificationPending',
  IdentificationFailed = 'IdentificationFailed',
  WalletSetupMissing = 'WalletSetupMissing',
  WalletSetupPending = 'WalletSetupPending',
  AdditionalInformationMissing = 'AdditionalInformationMissing',
  Finished = 'Finished',
}

export const getInvestorRegistrationStatusVariant = (status: InvestorRegistrationStatusEnum): ColorVariants => {
  switch (status) {
    case InvestorRegistrationStatusEnum.Finished:
      return 'success';
    case InvestorRegistrationStatusEnum.AccountSetup:
    case InvestorRegistrationStatusEnum.InvestmentAmountMissing:
    case InvestorRegistrationStatusEnum.QuestionnaireMissing:
    case InvestorRegistrationStatusEnum.BenefitingPersonsMissing:
    case InvestorRegistrationStatusEnum.IdentificationMissing:
    case InvestorRegistrationStatusEnum.IdentificationPending:
    case InvestorRegistrationStatusEnum.AdditionalInformationMissing:
    case InvestorRegistrationStatusEnum.WalletSetupMissing:
    case InvestorRegistrationStatusEnum.WalletSetupPending:
      return 'info';
    case InvestorRegistrationStatusEnum.IdentificationRejected:
    case InvestorRegistrationStatusEnum.IdentificationFailed:
      return 'warning';
  }
};

export const getInvestorRegistrationStatus = ({ step }: { step: InvestorStepEnum }): InvestorRegistrationStatusEnum => {
  switch (step) {
    case InvestorStepEnum.ACCOUNTSETUP:
    case InvestorStepEnum.UNCONFIRMED:
      return InvestorRegistrationStatusEnum.AccountSetup;
    case InvestorStepEnum.QUESTIONNAIRE:
      return InvestorRegistrationStatusEnum.QuestionnaireMissing;
    case InvestorStepEnum.BENEFICIARY:
      return InvestorRegistrationStatusEnum.BenefitingPersonsMissing;
    case InvestorStepEnum.IDENTIFICATION:
    case InvestorStepEnum.RESUMEIDENTIFICATION:
      return InvestorRegistrationStatusEnum.IdentificationMissing;
    case InvestorStepEnum.IDENTIFICATIONREJECTED:
      return InvestorRegistrationStatusEnum.IdentificationRejected;
    case InvestorStepEnum.IDENTIFICATIONFAILED:
      return InvestorRegistrationStatusEnum.IdentificationFailed;
    case InvestorStepEnum.WAITINGKYC:
      return InvestorRegistrationStatusEnum.IdentificationPending;
    case InvestorStepEnum.SELECTWALLET:
    case InvestorStepEnum.SETUPMOBILEWALLET:
      return InvestorRegistrationStatusEnum.WalletSetupMissing;
    case InvestorStepEnum.CONFIRMWALLET:
      return InvestorRegistrationStatusEnum.WalletSetupPending;
    case InvestorStepEnum.ADDITIONALINFORMATION:
      return InvestorRegistrationStatusEnum.AdditionalInformationMissing;
    case InvestorStepEnum.REGISTRATIONDONE:
      return InvestorRegistrationStatusEnum.Finished;
  }

  // make sure we covered all cases
  return assertUnreachable(step);
};
