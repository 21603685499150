import React, { FunctionComponent, useContext } from 'react';
import { push } from 'connected-react-router';

import PageTitle from 'core/components/page-title';
import DemoIssuerRegistrationForm, { IssuerRegistrationFields } from './demo-issuer-registration-form';
import Translate from 'ui/atoms/translate';

import { useDispatch } from 'store/hooks';
import makeLink from 'helper/make-link';

import { DemoApi } from 'api';
import { useApiCall } from 'hooks/use-api-call';
import { makeApi } from 'core/api';
import { DEMO_ROUTES } from 'subapps/demo/pages/routes.config';
import { I18NContext } from 'ui/i18n/provider';

const IssuerRegistration: FunctionComponent<{}> = (props) => {
  const dispatch = useDispatch();

  const { activeLocale } = useContext(I18NContext);
  const { loading, error, withApi } = useApiCall();

  // TODO form should allow free text company name incl. legal type later
  const onSubmit = async (fields: IssuerRegistrationFields) => {
    const { companyName, firstName, lastName, email, password, consentPrivacy } = fields;
    const api: DemoApi = await makeApi(DemoApi);

    if (
      !(await withApi(async () => {
        await api.demoIssuerRegistrationCreate({
          demoIssuerRegistrationRequest: {
            companyName,
            forename: firstName,
            surname: lastName,
            email,
            password,
            acceptTos: consentPrivacy,
            preferredLanguage: activeLocale,
          },
        });
        return true;
      }))
    ) {
      // an error occurred
      return;
    }
    dispatch(
      push(
        makeLink(DEMO_ROUTES.issuerRegistrationSuccess, {
          encodedEmail: encodeURIComponent(email),
        }),
      ),
    );
  };

  return (
    <>
      <PageTitle>
        <Translate name="demo.registration.title" />
      </PageTitle>
      <DemoIssuerRegistrationForm error={error} loading={loading} onSubmit={onSubmit} />
    </>
  );
};

export default IssuerRegistration;
