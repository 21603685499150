import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';
import useApiCall from 'hooks/use-api-call';
import { UsersApi } from 'api/apis';
import ChangeEmailAddress, { ChangeEmailAddressFields, ChangeEmailAddressForm } from './change-email-address-form';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';

const BoxedChangeEmailAddress: FunctionComponent = () => {
  const { error, loading, withApi, makeAuthenticatedApi } = useApiCall();
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);
  const [newEmail, setNewEmail] = useState<string>();
  const [oldEmail, setOldEmail] = useState<string>();

  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      const { email } = await usersApi.usersMeRetrieve();
      setOldEmail(email);
    });
  }, [usersApi, withApi]);

  const onChangeEmail = useCallback(
    ({ email, password }: ChangeEmailAddressFields) => {
      withApi(async () => {
        await usersApi.usersEmailResetCreate({
          emailResetRequest: {
            password,
            email: email,
          },
        });
        setNewEmail(email);
        setConfirmationEmailSent(true);
      });
    },
    [usersApi, withApi],
  );
  // TODO(mara-cashlink): add error translation for the error code "user_already_registered" to locales after error handling UX has been defined

  return (
    <BoxedContent title={<Translate name="common.email" />} releaseSpaceWhenTitleIsEmpty={true}>
      {!oldEmail && <LoadingRing />}
      {oldEmail && (
        <ChangeEmailAddressForm onSubmit={onChangeEmail} error={error} i18nKey="changeEmailAddress">
          <ChangeEmailAddress
            emailChangedSuccessfully={confirmationEmailSent}
            oldEmail={oldEmail}
            newEmail={newEmail}
          />
          <ChangeEmailAddressForm.GenericErrorMessages />
          <ActionButtons>
            {confirmationEmailSent && (
              <Button variant="link" onClick={() => setConfirmationEmailSent(false)}>
                <Translate name="changeEmailAddress.changeEmail" />
              </Button>
            )}
            {!confirmationEmailSent && (
              <Button type="submit" variant="primary" loading={loading}>
                <Translate name="changeEmailAddress.changeEmail" />
              </Button>
            )}
          </ActionButtons>
        </ChangeEmailAddressForm>
      )}
    </BoxedContent>
  );
};

export default BoxedChangeEmailAddress;
