import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';

import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import { BenefitingPerson } from 'api/models';
import LoadingRing from 'ui/atoms/loading-ring';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import useInvestorMe from 'hooks/use-investor-me';
import { PersonToLegalPerson } from 'helper/cast';
import IdentificationSuccess from 'src/libraries/wizard/components/identification-step-done/identification-success';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import { extractNaturalAndLegalFromPerson } from 'core/api/conversions';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const IdentificationStepDone: FunctionComponent<{}> = () => {
  const { investmentId, nextStep } = useContext(WizardContext);

  const [benefitingPersons, setBenefitingPersons] = useState<Array<BenefitingPerson>>();

  const { error: apiError, loading: apiLoading, withApi, makeAuthenticatedApi } = useApiCall();

  const { investor, loading: investorLoading, error: investorError } = useInvestorMe();

  const { naturalPerson } = extractNaturalAndLegalFromPerson(investor?.person);

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      if (PersonToLegalPerson(investor?.person) && investor?.id) {
        // TODO(geforcefan): was investorsBenefitingPersonsRead before, check this call
        const { results } = await investorsApi.investorsBenefitingPersonsList({
          id: investor.id,
        });
        setBenefitingPersons(results);
      }
    });
  }, [investorsApi, investmentId, investor, withApi]);

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (apiError || investorError) return null;

  if (!investor || investorLoading || apiLoading) return <LoadingRing />;

  const title = <Translate name="identification.title" />;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <IdentificationSuccess
        naturalPerson={
          naturalPerson
            ? {
                ...naturalPerson,
                identificationDocumentNumber: naturalPerson.identificationDocumentNumber || undefined,
                birthDate: naturalPerson.birthDate || undefined,
              }
            : undefined
        }
        benefitingPersons={benefitingPersons}
      />
      <ActionButtons>
        <Button size="large" fluid={true} variant="primary" onClick={nextStep}>
          <Translate name="common.continue" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default IdentificationStepDone;
