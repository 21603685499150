import React from 'react';
import { string } from 'yup';
import Approvals from './approvals';
import Documents from './documents';
import Vouchers from './vouchers';
import CreditSecurities from './credit-securities';
import Overview from './overview';
import Products from './products';
import FriendReferrals from './friend-referrals';
import FriendReferralDetails from './friend-referral-details';
import ProductDetails from './product-details';
import CampaignDetails from './product-details/campaigns/campaign-details';
import IncomingPayments from './incoming-payments';
import InvestmentDetails from './investment-details';
import VoucherDetails from './voucher-details';
import TriggerSolutionSettlements from './trigger-solution-settlements';
import TriggerSolutionSettlementsDetails from './trigger-solution-settlements-details';
import InvestmentInvitationDetails from './product-details/invitations/invitation-details';
import Investments from './investments';
import InvestorDetails from './investor-details';
import Investors from './investors';
import Translate from 'ui/atoms/translate';
import {
  hasManageApprovalsPermission,
  hasManageCreditDigitalSecuritiesPermission,
  hasManageIncomingPaymentsPermission,
  hasManageTriggerSolutionPermission,
  hasViewPermission,
} from 'apps/issuer/helpers/permissions';
import type { NavigationItem } from 'core/nav/types';
import type { ComponentRouting, RouteDefinitions } from 'core/routing/types';
import type { InvestmentToken } from 'api';

export const ROUTES = {
  root: 'root',
  approvals: 'approvals',
  overview: 'overview',
  products: 'products',
  productDetails: 'productDetails',
  campaigns: 'campaigns',
  campaignDetails: 'campaignDetails',
  creditSecurities: 'creditSecurities',
  incomingPayments: 'incomingPayments',
  investmentDetails: 'investmentDetails',
  investmentInvitations: 'investmentInvitations',
  investmentInvitationDetails: 'investmentInvitationDetails',
  investments: 'investments',
  investors: 'investors',
  investorDetails: 'investorDetails',
  documents: 'documents',
  vouchers: 'vouchers',
  voucherDetails: 'voucherDetails',
  friendReferrals: 'friendReferrals',
  friendReferralDetails: 'friendReferralDetails',
  triggerSolutionsSettlements: 'triggerSolutionsSettlements',
  triggerSolutionsSettlementsDetails: 'triggerSolutionsSettlementsDetails',
};

export const OVERVIEW = {
  path: '/dashboard/issuerOverview',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const PRODUCTS = {
  path: '/dashboard/issuerProducts',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const PRODUCT_DETAILS = {
  path: '/dashboard/issuerProduct/:productId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const CAMPAIGN_DETAILS = {
  path: '/dashboard/issuerCampaign/:campaignId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const INVESTMENT_INVITATION_DETAILS = {
  path: '/dashboard/issuerInvestmentInvitations/:invitationId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const INVESTORS = {
  path: '/dashboard/issuerInvestors',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens) || props.configFeatures.canRegisterOnly,
};

export const INVESTOR_DETAILS = {
  path: '/dashboard/issuerInvestor/:investorId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens) || props.configFeatures.canRegisterOnly,
  requirements: {
    investorId: string().required(),
  },
};

export const INVESTMENTS = {
  path: '/dashboard/issuerInvestments',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const INVESTMENT_DETAILS = {
  path: '/dashboard/issuerInvestment/:productId/investment/:investmentId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
  requirements: {
    investmentId: string().required(),
  },
};

export const APPROVALS = {
  path: '/dashboard/issuerApprovals',
  exact: true,
  condition: (props: any) => hasManageApprovalsPermission(props.tokens),
};

export const INCOMING_PAYMENTS = {
  path: '/dashboard/issuerIncomingPayments',
  exact: true,
  condition: (props: any) => hasManageIncomingPaymentsPermission(props.tokens),
};

export const CREDIT_SECURITIES = {
  path: '/dashboard/issuerCreditSecurities',
  exact: true,
  condition: (props: any) => {
    return (
      hasManageCreditDigitalSecuritiesPermission(props.tokens) &&
      !!props.tokens?.some((token: InvestmentToken) => token?.isTokenized)
    );
  },
};

export const DOCUMENTS = {
  path: '/dashboard/issuerDocuments',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const VOUCHERS = {
  path: '/dashboard/issuerVouchers',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const FRIEND_REFERRALS = {
  path: '/dashboard/friendReferrals',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
};

export const VOUCHER_DETAILS = {
  path: '/dashboard/issuerVouchers/:voucherId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
  requirements: {
    voucherId: string().required(),
  },
};

export const FRIEND_REFERRAL_DETAILS = {
  path: '/dashboard/friendReferrals/:referralId',
  exact: true,
  condition: (props: any) => hasViewPermission(props.tokens),
  requirements: {
    referralId: string().required(),
  },
};

export const TRIGGER_SOLUTIONS_SETTLEMENTS = {
  path: '/dashboard/issuerTriggerSolutionSettlements',
  exact: true,
  condition: (props: any) => hasManageTriggerSolutionPermission(props.tokens),
};

export const TRIGGER_SOLUTIONS_SETTLEMENTS_DETAILS = {
  path: '/dashboard/issuerTriggerSolutionSettlements/:settlementId',
  exact: true,
  condition: (props: any) => hasManageTriggerSolutionPermission(props.tokens),
  requirements: {
    settlementId: string().required(),
  },
};

export const LEGACY_REDIRECTS = [
  {
    from: '/dashboard/issuerCampaigns',
    to: '/dashboard/issuerProducts',
    exact: true,
  },
  {
    from: '/dashboard/issuerCampaign/:id',
    to: '/dashboard/issuerProducts',
    exact: false,
  },
  {
    from: '/dashboard/issuerInvestmentInvitations',
    to: '/dashboard/issuerProducts',
    exact: true,
  },
  {
    from: '/dashboard/issuerInvestmentInvitation/:id',
    to: '/dashboard/issuerProducts',
    exact: false,
  },
];

export const ISSUER_ROUTES: RouteDefinitions = {
  [ROUTES.overview]: OVERVIEW,
  [ROUTES.products]: PRODUCTS,
  [ROUTES.productDetails]: PRODUCT_DETAILS,
  [ROUTES.campaignDetails]: CAMPAIGN_DETAILS,
  [ROUTES.creditSecurities]: CREDIT_SECURITIES,
  [ROUTES.investments]: INVESTMENTS,
  [ROUTES.investmentDetails]: INVESTMENT_DETAILS,
  [ROUTES.investmentInvitationDetails]: INVESTMENT_INVITATION_DETAILS,
  [ROUTES.investors]: INVESTORS,
  [ROUTES.investorDetails]: INVESTOR_DETAILS,
  [ROUTES.approvals]: APPROVALS,
  [ROUTES.incomingPayments]: INCOMING_PAYMENTS,
  [ROUTES.documents]: DOCUMENTS,
  [ROUTES.vouchers]: VOUCHERS,
  [ROUTES.voucherDetails]: VOUCHER_DETAILS,
  [ROUTES.friendReferrals]: FRIEND_REFERRALS,
  [ROUTES.friendReferralDetails]: FRIEND_REFERRAL_DETAILS,
  [ROUTES.triggerSolutionsSettlements]: TRIGGER_SOLUTIONS_SETTLEMENTS,
  [ROUTES.triggerSolutionsSettlementsDetails]: TRIGGER_SOLUTIONS_SETTLEMENTS_DETAILS,
};

const REDIRECT_PATHS = Object.values(LEGACY_REDIRECTS).map((redirect) => redirect.from);

export const ISSUER_PATHS = Object.values(ISSUER_ROUTES).map((route) => route.path);

ISSUER_PATHS.push(...REDIRECT_PATHS);

export const ISSUER_NAVIGATION: NavigationItem[] = [
  {
    key: ROUTES.overview,
    route: OVERVIEW,
    label: <Translate name="dashboard.issuerOverview" />,
  },
  {
    key: ROUTES.products,
    route: PRODUCTS,
    label: <Translate name="dashboard.issuerProducts" />,
    subRoutes: [
      {
        key: ROUTES.productDetails,
        route: PRODUCT_DETAILS,
        subRoutes: [
          {
            key: ROUTES.campaignDetails,
            route: CAMPAIGN_DETAILS,
          },
          {
            key: ROUTES.investmentInvitationDetails,
            route: INVESTMENT_INVITATION_DETAILS,
          },
        ],
      },
    ],
  },
  {
    key: ROUTES.investors,
    route: INVESTORS,
    label: <Translate name="dashboard.issuerInvestors" />,
    subRoutes: [
      {
        key: ROUTES.investorDetails,
        route: INVESTOR_DETAILS,
      },
    ],
  },
  {
    key: ROUTES.investments,
    route: INVESTMENTS,
    label: <Translate name="dashboard.issuerInvestments" />,
    subRoutes: [
      {
        key: ROUTES.investmentDetails,
        route: INVESTMENT_DETAILS,
      },
    ],
  },
  {
    key: ROUTES.approvals,
    route: APPROVALS,
    label: <Translate name="dashboard.issuerApprovals" />,
  },
  {
    key: ROUTES.incomingPayments,
    route: INCOMING_PAYMENTS,
    label: <Translate name="dashboard.issuerIncomingPayments" />,
  },
  {
    key: ROUTES.creditSecurities,
    route: CREDIT_SECURITIES,
    label: <Translate name="dashboard.issuerCreditSecurities" />,
  },
  {
    key: ROUTES.documents,
    route: DOCUMENTS,
    label: <Translate name="dashboard.issuerDocuments" />,
  },
  {
    key: ROUTES.vouchers,
    route: VOUCHERS,
    label: <Translate name="dashboard.issuerVouchers" />,
    subRoutes: [
      {
        key: ROUTES.voucherDetails,
        route: VOUCHER_DETAILS,
      },
    ],
  },
  {
    key: ROUTES.friendReferrals,
    route: FRIEND_REFERRALS,
    label: <Translate name="dashboard.friendReferrals" />,
  },
  {
    key: ROUTES.triggerSolutionsSettlements,
    route: TRIGGER_SOLUTIONS_SETTLEMENTS,
    label: <Translate name="dashboard.issuerTriggerSolutionSettlements" />,
  },
];

export const COMPONENT_ROUTING: ComponentRouting = {
  [ROUTES.overview]: Overview,
  [ROUTES.products]: Products,
  [ROUTES.productDetails]: ProductDetails,
  [ROUTES.campaignDetails]: CampaignDetails,
  [ROUTES.investmentInvitationDetails]: InvestmentInvitationDetails,
  [ROUTES.investments]: Investments,
  [ROUTES.investmentDetails]: InvestmentDetails,
  [ROUTES.investors]: Investors,
  [ROUTES.investorDetails]: InvestorDetails,
  [ROUTES.approvals]: Approvals,
  [ROUTES.incomingPayments]: IncomingPayments,
  [ROUTES.creditSecurities]: CreditSecurities,
  [ROUTES.documents]: Documents,
  [ROUTES.vouchers]: Vouchers,
  [ROUTES.voucherDetails]: VoucherDetails,
  [ROUTES.friendReferrals]: FriendReferrals,
  [ROUTES.friendReferralDetails]: FriendReferralDetails,
  [ROUTES.triggerSolutionsSettlements]: TriggerSolutionSettlements,
  [ROUTES.triggerSolutionsSettlementsDetails]: TriggerSolutionSettlementsDetails,
};
