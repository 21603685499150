import React, { FunctionComponent, useState } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WideContent from 'core/layout/content/wide-content';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { FilterDateOption, FilterPopup, FilterRange, FilterSingleOption, Search } from 'ui/molecules/filtering';
import IssuerVouchersTable from './vouchers-table';
import Section from 'ui/atoms/section';
import { hasManageVouchersPermissionForAllProducts } from 'apps/issuer/helpers/permissions';
import { useIssuerSelector } from 'apps/issuer/hooks';
import VoucherModal from './create-voucher';
import { useFilterValue } from 'ui/molecules/filtering/hooks';

const IssuerVouchersPageFilters: FunctionComponent<{}> = () => {
  const translate = useTranslateWithStringArgs();

  const filterLabels = {
    createdAt: 'createdAt',
    createdAtAfter: 'createdAtAfter',
    createdAtBefore: 'createdAtBefore',
  };

  return (
    <>
      <Search input="search" defaultField="search" label={translate('filters.labels.name')} name="search" />
      <FilterPopup>
        <FilterRange
          label={translate('filters.labels.createdOn')}
          inputNames={[filterLabels.createdAtAfter, filterLabels.createdAtBefore]}
          filterName={filterLabels.createdAt}
        >
          <FilterDateOption
            input={filterLabels.createdAtAfter}
            pairedInput={filterLabels.createdAtBefore}
            label={null}
            defaultField={filterLabels.createdAtAfter}
            maxDate={useFilterValue(filterLabels.createdAtBefore) as Date}
            name={filterLabels.createdAt}
          />
          <FilterDateOption
            input={filterLabels.createdAtBefore}
            pairedInput={filterLabels.createdAtAfter}
            label={null}
            defaultField={filterLabels.createdAtBefore}
            minDate={useFilterValue(filterLabels.createdAtAfter) as Date}
            name={filterLabels.createdAt}
          />
        </FilterRange>
        <FilterSingleOption
          input="active"
          defaultField="active"
          label={translate('studioVouchersTable.status')}
          options={[
            {
              value: 'true',
              label: translate('studioVouchersTable.active'),
              name: 'active',
            },
            {
              value: 'false',
              label: translate('studioVouchersTable.deactivated'),
              name: 'deactivated',
            },
          ]}
        />
      </FilterPopup>
    </>
  );
};

const IssuerVouchersPage: FunctionComponent = () => {
  const { tokens } = useIssuerSelector();

  const hasManageVouchersPermission = hasManageVouchersPermissionForAllProducts(tokens);

  const [forceVouchersDataLoad, setForceVouchersDataLoad] = useState(false);

  const onVoucherCreated = () => {
    setForceVouchersDataLoad(true);
    setForceVouchersDataLoad(false);
  };

  return (
    <WideContent
      title={<Translate name="dashboardIssuerVouchers.title" />}
      headerPanel={hasManageVouchersPermission && <VoucherModal onVoucherChanged={onVoucherCreated} />}
    >
      <Section spacing="medium">
        <Translate name="dashboardIssuerVouchers.subtitle" />
      </Section>
      <IssuerVouchersTable
        filters={<IssuerVouchersPageFilters />}
        defaultOrdering={{ direction: 'desc', fieldName: 'created_at' }}
        defaultLimit={10}
        forceDataLoad={forceVouchersDataLoad}
      />
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerVouchersPage, AppType.ISSUER);
