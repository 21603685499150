import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import EmailHistoryTableView from './email-history-table-view';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { FilterDateOption, FilterPopup, FilterRange, Search } from 'ui/molecules/filtering';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import { AdminApi, AdminInvestorsMailsListTypeEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import { useFilterValue } from 'ui/molecules/filtering/hooks';
import FilterMultiSelect from 'src/ui/molecules/filtering/filter-popup/filter-multi-select';

const EmailHistoryFilters: FunctionComponent = () => {
  const translate = useTranslateWithStringArgs();

  const filterLabels = {
    sendAt: 'sendAt',
    sendAtAfter: 'sendAtAfter',
    sendAtBefore: 'sendAtBefore',
  };

  const mailTypeFilterOption = Object.values(AdminInvestorsMailsListTypeEnum).map((mailType) => ({
    value: mailType,
    label: translate(`emailType.${mailType}`),
    name: translate(`emailType.${mailType}`),
  }));

  return (
    <>
      <Search input="search" defaultField="search" label={translate('filters.labels.productOrSubject')} name="search" />
      <FilterPopup>
        <FilterMultiSelect
          label={translate('studioEmailHistoryTable.mailType')}
          options={mailTypeFilterOption}
          input="type"
          defaultField="type"
        />
        <FilterRange
          label={translate('filters.labels.deliveredDate')}
          inputNames={[filterLabels.sendAtAfter, filterLabels.sendAtBefore]}
          filterName={filterLabels.sendAt}
        >
          <FilterDateOption
            input={filterLabels.sendAtAfter}
            pairedInput={filterLabels.sendAtBefore}
            label={null}
            defaultField={filterLabels.sendAtAfter}
            maxDate={useFilterValue(filterLabels.sendAtBefore) as Date}
            name={filterLabels.sendAt}
          />
          <FilterDateOption
            input={filterLabels.sendAtBefore}
            pairedInput={filterLabels.sendAtAfter}
            label={null}
            defaultField={filterLabels.sendAtBefore}
            minDate={useFilterValue(filterLabels.sendAtAfter) as Date}
            name={filterLabels.sendAt}
          />
        </FilterRange>
      </FilterPopup>
    </>
  );
};

export interface EmailHistoryProps {
  investorId: string;
  hasManageDataPermission: boolean;
}

const EmailHistoryView: FunctionComponent<EmailHistoryProps> = ({ investorId, hasManageDataPermission }) => {
  const { withApi, makeAuthenticatedApi } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const [emailsNumber, setEmailsNumber] = useState(0);

  const [emailsResent, setEmailResent] = useState<string[]>([]);

  const resendEmail = useCallback(
    (emailId) => {
      withApi(async () => {
        await adminApi.adminMailsResendCreate({
          id: emailId,
        });
      });
      setEmailResent([...emailsResent, emailId]);
    },
    [withApi, adminApi, emailsResent],
  );

  const fetchEmails = () => {
    withApi(async () => {
      const mailsList = await adminApi.adminInvestorsMailsList({
        id: investorId,
        offset: 0,
        limit: 10,
      });
      setEmailsNumber(mailsList.count || 0);
    });
  };

  const isEmailResent = useCallback(
    (emailId: string) => {
      return emailsResent.filter((email) => email === emailId).length > 0;
    },
    [emailsResent],
  );

  useEffect(() => {
    fetchEmails();
  }, [withApi, adminApi]);

  if (!emailsNumber) {
    return null;
  }
  return (
    <Section spacing="small">
      <WideContent title={<Translate name={'dashboardIssuerInvestorDetails.emails'} />} ignorePageTitle>
        <EmailHistoryTableView
          filters={<EmailHistoryFilters />}
          investorId={investorId}
          emptyView={<IssuerEmptyView type={EmptyViewType.ISSUER_INVESTOR_EMAILS} />}
          resendEmail={resendEmail}
          isEmailResent={isEmailResent}
          hasManageDataPermission={hasManageDataPermission}
        />
      </WideContent>
    </Section>
  );
};

export default EmailHistoryView;
