import { useCallback } from 'react';
import { AdminBundesbankSettlementInstructionStatusEnum } from 'src/api';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

export const useTriggerSolutionStatus = () => {
  const translate = useTranslateWithStringArgs();

  const getVariant = useCallback((status: AdminBundesbankSettlementInstructionStatusEnum) => {
    switch (status) {
      case AdminBundesbankSettlementInstructionStatusEnum.AssetLocked:
      case AdminBundesbankSettlementInstructionStatusEnum.PaymentInstructionPrepared:
        return 'warning';
      case AdminBundesbankSettlementInstructionStatusEnum.Created:
      case AdminBundesbankSettlementInstructionStatusEnum.Success:
        return 'success';
      case AdminBundesbankSettlementInstructionStatusEnum.Failed:
        return 'danger';
    }
  }, []);

  const getStatusName = useCallback((status: AdminBundesbankSettlementInstructionStatusEnum) => {
    switch (status) {
      case AdminBundesbankSettlementInstructionStatusEnum.AssetLocked:
        return translate('studioSettlementsTable.assetLocked');
      case AdminBundesbankSettlementInstructionStatusEnum.Created:
        return translate('studioSettlementsTable.created');
      case AdminBundesbankSettlementInstructionStatusEnum.Failed:
        return translate('studioSettlementsTable.failed');
      case AdminBundesbankSettlementInstructionStatusEnum.PaymentInstructionPrepared:
        return translate('studioSettlementsTable.paymentInstructionPrepared');
      case AdminBundesbankSettlementInstructionStatusEnum.Success:
        return translate('studioSettlementsTable.success');
    }
  }, []);

  return {
    getStatusName,
    getVariant,
  };
};
