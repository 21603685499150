import { StepEnum } from 'api/models';

export enum WizardSidebarStep {
  ACCOUNT_DATA = 'accountData',
  INVESTMENT_DATA = 'investmentData',
  QUESTIONNAIRE = 'questionnaire',
  COMMITMENT = 'commitment',
  KYC = 'kyc',
  PAYMENT = 'payment',
  DEPOT = 'depotSelection',
  ADDITIONAL_INFORMATION = 'additionalInformation',
}

export interface sidebarMappingInterface {
  sidebarStep?: WizardSidebarStep;
  wizardSteps?: StepEnum[];
}

const sidebarMapping = [
  {
    sidebarStep: WizardSidebarStep.ACCOUNT_DATA,
    wizardSteps: [StepEnum.ACCOUNTSETUP, StepEnum.UNCONFIRMED],
  },
  {
    sidebarStep: WizardSidebarStep.INVESTMENT_DATA,
    wizardSteps: [StepEnum.INVESTMENTAMOUNT],
  },
  {
    sidebarStep: WizardSidebarStep.QUESTIONNAIRE,
    wizardSteps: [StepEnum.QUESTIONNAIRE],
  },
  {
    sidebarStep: WizardSidebarStep.COMMITMENT,
    wizardSteps: [StepEnum.VIB, StepEnum.OFFERCREATION],
  },
  {
    sidebarStep: WizardSidebarStep.KYC,
    wizardSteps: [
      StepEnum.BENEFICIARY,
      StepEnum.IDENTIFICATION,
      StepEnum.RESUMEIDENTIFICATION,
      StepEnum.IDENTIFICATIONREJECTED,
      StepEnum.IDENTIFICATIONFAILED,
    ],
  },
  {
    sidebarStep: WizardSidebarStep.PAYMENT,
    wizardSteps: [StepEnum.PAYMENT, StepEnum.WAITINGACCEPTANCE, StepEnum.WAITINGKYC],
  },
  {
    sidebarStep: WizardSidebarStep.DEPOT,
    wizardSteps: [StepEnum.SELECTWALLET, StepEnum.SETUPMOBILEWALLET, StepEnum.PREPAREASSET, StepEnum.CONFIRMWALLET],
  },
  {
    sidebarStep: WizardSidebarStep.ADDITIONAL_INFORMATION,
    wizardSteps: [StepEnum.ADDITIONALINFORMATION],
  },
];

export const transferSidebarMapping = [
  {
    sidebarStep: WizardSidebarStep.ACCOUNT_DATA,
    wizardSteps: [StepEnum.ACCOUNTSETUP, StepEnum.UNCONFIRMED],
  },
  {
    sidebarStep: WizardSidebarStep.KYC,
    wizardSteps: [
      StepEnum.BENEFICIARY,
      StepEnum.IDENTIFICATION,
      StepEnum.RESUMEIDENTIFICATION,
      StepEnum.IDENTIFICATIONREJECTED,
      StepEnum.WAITINGKYC,
    ],
  },
  {
    sidebarStep: WizardSidebarStep.DEPOT,
    wizardSteps: [StepEnum.SELECTWALLET, StepEnum.SETUPMOBILEWALLET, StepEnum.PREPAREASSET, StepEnum.CONFIRMWALLET],
  },
];

export default sidebarMapping;
