import {
  AssetPreparationStatusEnum,
  EducationalQualificationEnum,
  InvestmentCancellationEnum,
  NetworkTypeEnum,
  Person,
  ProductTypeEnum,
  RoleEnum,
  SalutationEnum,
  StepEnum,
  SurveyWaiverTypeEnum,
  TokenizationApiOperationStatus,
  TokenTransferStatusEnum,
  WalletTypeEnum,
} from 'api';
import { NetworkType } from 'ui/types/network';
import { PersonToLegalPerson, PersonToNaturalPerson } from 'helper/cast';
import { EducationalQualification, Occupation, PersonType, Salutation } from 'ui/types/person';
import { TransferStatus } from 'ui/types/transfer';
import { ProductType } from 'ui/types/asset';
import { assertUnreachable } from 'ui/helper/unreachable';
import { find } from 'lodash';
import { WalletType } from 'ui/types/wallet';
import { SurveyWaiverType } from 'ui/types/survey-waiver';
import { Step } from 'ui/types/step';
import { InvestmentCancellation } from 'ui/types/investment-cancellation';
import { AssetPreparationStatus } from 'ui/types/asset-preparation';
import { TokenizationOperationStatus } from 'ui/types/tokenization-api';
import { PlatformUrl } from 'ui/types/urls';

export const convertEducationalQualificationFromApi = (x: EducationalQualificationEnum): EducationalQualification => {
  switch (x) {
    case EducationalQualificationEnum.HighSchoolDiplomaALevels:
      return EducationalQualification.HighSchoolDiplomaALevels;
    case EducationalQualificationEnum.SecondarySchoolDiploma:
      return EducationalQualification.SecondarySchoolDiploma;
    case EducationalQualificationEnum.BachelorAndOrMasterDegree:
      return EducationalQualification.BachelorAndOrMasterDegree;
    case EducationalQualificationEnum.DoctoralDegree:
      return EducationalQualification.DoctoralDegree;
    case EducationalQualificationEnum.NotSpecified:
      return EducationalQualification.NotSpecified;
  }

  return assertUnreachable(x);
};

export const convertOccupationFromApi = (x: string): Occupation => {
  switch (x) {
    case Occupation.NotSpecified:
      return Occupation.NotSpecified;
    case Occupation.Employee:
      return Occupation.Employee;
    case Occupation.Apprentice:
      return Occupation.Apprentice;
    case Occupation.Pupil:
      return Occupation.Pupil;
    case Occupation.Student:
      return Occupation.Student;
    case Occupation.SelfEmployed:
      return Occupation.SelfEmployed;
    case Occupation.Retiree:
      return Occupation.Retiree;
    case Occupation.MarginalEmploymentMiniJob:
      return Occupation.MarginalEmploymentMiniJob;
    case Occupation.WithoutEmployment:
      return Occupation.WithoutEmployment;
  }

  return Occupation.NotSpecified;
};

export const convertEducationalQualificationToApi = (x: EducationalQualification): EducationalQualificationEnum => {
  switch (x) {
    case EducationalQualification.HighSchoolDiplomaALevels:
      return EducationalQualificationEnum.HighSchoolDiplomaALevels;
    case EducationalQualification.SecondarySchoolDiploma:
      return EducationalQualificationEnum.SecondarySchoolDiploma;
    case EducationalQualification.BachelorAndOrMasterDegree:
      return EducationalQualificationEnum.BachelorAndOrMasterDegree;
    case EducationalQualification.DoctoralDegree:
      return EducationalQualificationEnum.DoctoralDegree;
    case EducationalQualification.NotSpecified:
      return EducationalQualificationEnum.NotSpecified;
  }

  return assertUnreachable(x);
};

export const convertSalutationFromApi = (x: SalutationEnum): Salutation => {
  switch (x) {
    case SalutationEnum.MR:
      return Salutation.MR;
    case SalutationEnum.MS:
      return Salutation.MS;
    case SalutationEnum.D:
      return Salutation.D;
  }

  return assertUnreachable(x);
};

export const convertSalutationToApi = (x: Salutation): SalutationEnum => {
  switch (x) {
    case Salutation.MR:
      return SalutationEnum.MR;
    case Salutation.MS:
      return SalutationEnum.MS;
    case Salutation.D:
      return SalutationEnum.D;
  }

  return assertUnreachable(x);
};

export const extractNaturalAndLegalFromPerson = (person?: Person) => {
  if (!person)
    return {
      personType: undefined,
      naturalPerson: undefined,
      legalPerson: undefined,
    };

  const legalPerson = PersonToLegalPerson(person);

  return {
    personType: legalPerson ? PersonType.Legal : PersonType.Natural,
    naturalPerson:
      PersonToNaturalPerson(person) || PersonToNaturalPerson(find(person.persons as any, { role: RoleEnum.CEO })),
    legalPerson,
  };
};

export function convertProductTypeFromApi(x: ProductTypeEnum): ProductType {
  switch (x) {
    case ProductTypeEnum.AIF:
      return ProductType.AIF;
    case ProductTypeEnum.BOND:
      return ProductType.BOND;
    case ProductTypeEnum.DEBTSECURITY:
      return ProductType.DEBTSECURITY;
    case ProductTypeEnum.DIGITALCOMPANYSHARE:
      return ProductType.DIGITAL_COMPANY_SHARE;
    case ProductTypeEnum.PARTICIPATIONPAPER:
      return ProductType.PARTICIPATION_PAPER;
    case ProductTypeEnum.PARTICIPATIONRIGHT:
      return ProductType.PARTICIPATION_RIGHT;
    case ProductTypeEnum.SUBORDINATEDLOAN:
      return ProductType.SUBORDINATED_LOAN;
    case ProductTypeEnum.TOKENIZEDLIMITEDPARTNERSHARES:
      return ProductType.TOKENIZEDLIMITEDPARTNERSHARES;
    case ProductTypeEnum.TOKENIZEDARTWORK:
      return ProductType.TOKENIZEDARTWORK;
    case ProductTypeEnum.CONVERTIBLEBOND:
      return ProductType.CONVERTIBLEBOND;
    case ProductTypeEnum.FUNDSHARE:
      return ProductType.FUNDSHARE;
  }

  return assertUnreachable(x);
}

export function convertTransferStatusFromApi(x: TokenTransferStatusEnum): TransferStatus {
  switch (x) {
    case TokenTransferStatusEnum.Pending:
      return TransferStatus.PENDING;
    case TokenTransferStatusEnum.TfaRequested:
      return TransferStatus.TFA_REQUESTED;
    case TokenTransferStatusEnum.TfaConfirmed:
      return TransferStatus.TFA_CONFIRMED;
    case TokenTransferStatusEnum.Processing:
      return TransferStatus.PROCESSING;

    case TokenTransferStatusEnum.TfaRejected:
      return TransferStatus.TFA_REJECTED;
    case TokenTransferStatusEnum.ApproverRejected:
      return TransferStatus.APPROVER_REJECTED;
    case TokenTransferStatusEnum.Completed:
      return TransferStatus.COMPLETED;
    case TokenTransferStatusEnum.Failed:
      return TransferStatus.FAILED;
  }

  return assertUnreachable(x);
}

export const convertWalletTypeFromApi = (x: WalletTypeEnum): WalletType => {
  switch (x) {
    case WalletTypeEnum.TANGANY:
      return WalletType.TANGANY;
    case WalletTypeEnum.MOBILE:
      return WalletType.MOBILE;
    case WalletTypeEnum.GENERIC:
      return WalletType.GENERIC;
    case WalletTypeEnum.CASHLINK:
      return WalletType.CASHLINK;
  }

  return assertUnreachable(x);
};

export const convertWalletTypeToApi = (x: WalletType): WalletTypeEnum => {
  switch (x) {
    case WalletType.TANGANY:
      return WalletTypeEnum.TANGANY;
    case WalletType.MOBILE:
      return WalletTypeEnum.MOBILE;
    case WalletType.GENERIC:
      return WalletTypeEnum.GENERIC;
    case WalletType.CASHLINK:
      return WalletTypeEnum.CASHLINK;
  }

  return assertUnreachable(x);
};

export const convertSurveyWaiverTypeFromApi = (x: SurveyWaiverTypeEnum): SurveyWaiverType => {
  switch (x) {
    case SurveyWaiverTypeEnum.NOANSWERS:
      return SurveyWaiverType.NOANSWERS;
    case SurveyWaiverTypeEnum.UNSUITABLE:
      return SurveyWaiverType.UNSUITABLE;
  }

  return assertUnreachable(x);
};

export const convertInvestmentCancellationTypeFromApi = (x: InvestmentCancellationEnum): InvestmentCancellation => {
  switch (x) {
    case InvestmentCancellationEnum.KYCINSUFFICIENT:
      return InvestmentCancellation.KYCINSUFFICIENT;
    case InvestmentCancellationEnum.ABORTION:
      return InvestmentCancellation.ABORTION;
    case InvestmentCancellationEnum.REJECTION:
      return InvestmentCancellation.REJECTION;
    case InvestmentCancellationEnum.PAYMENTMISSING:
      return InvestmentCancellation.PAYMENTMISSING;
    case InvestmentCancellationEnum.CANCELLATION:
      return InvestmentCancellation.CANCELLATION;
    case InvestmentCancellationEnum.REJECTIONECSP:
      return InvestmentCancellation.REJECTION;
  }

  return assertUnreachable(x);
};

export const convertInvestmentIssuanceStatusFromApi = (
  x: TokenizationApiOperationStatus,
): TokenizationOperationStatus => {
  switch (x) {
    case TokenizationApiOperationStatus.Failed:
      return TokenizationOperationStatus.Failed;
    case TokenizationApiOperationStatus.Processing:
      return TokenizationOperationStatus.Processing;
    case TokenizationApiOperationStatus.Succeeded:
      return TokenizationOperationStatus.Succeeded;
    case TokenizationApiOperationStatus.Unknown:
      return TokenizationOperationStatus.Unknown;
    case TokenizationApiOperationStatus.Waiting:
      return TokenizationOperationStatus.Waiting;
  }

  return assertUnreachable(x);
};

export const convertSurveyWaiverTypeToApi = (x: SurveyWaiverType): SurveyWaiverTypeEnum => {
  switch (x) {
    case SurveyWaiverType.NOANSWERS:
      return SurveyWaiverTypeEnum.NOANSWERS;
    case SurveyWaiverType.UNSUITABLE:
      return SurveyWaiverTypeEnum.UNSUITABLE;
  }

  return assertUnreachable(x);
};

export const convertNetworkTypeFromApi = (x: NetworkTypeEnum): NetworkType => {
  switch (x) {
    case NetworkTypeEnum.ETHEREUM:
      return NetworkType.ETHEREUM;
    case NetworkTypeEnum.STELLAR:
      return NetworkType.STELLAR;
  }

  return assertUnreachable(x);
};

export const converAssetPreparationStatusFromApi = (x: AssetPreparationStatusEnum): AssetPreparationStatus => {
  switch (x) {
    case AssetPreparationStatusEnum.STARTED:
      return AssetPreparationStatus.STARTED;
    case AssetPreparationStatusEnum.FINISHED:
      return AssetPreparationStatus.FINISHED;
    case AssetPreparationStatusEnum.REJECTED:
      return AssetPreparationStatus.REJECTED;
  }

  return assertUnreachable(x);
};

export const convertNetworkTypeToApi = (x: NetworkType): NetworkTypeEnum => {
  switch (x) {
    case NetworkType.ETHEREUM:
      return NetworkTypeEnum.ETHEREUM;
    case NetworkType.STELLAR:
      return NetworkTypeEnum.STELLAR;
  }

  return assertUnreachable(x);
};

export const convertStepFromApi = (x: StepEnum): Step => {
  switch (x) {
    case StepEnum.ACCOUNTSETUP:
      return Step.ACCOUNTSETUP;
    case StepEnum.INVESTMENTAMOUNT:
      return Step.INVESTMENTAMOUNT;
    case StepEnum.VIB:
      return Step.VIB;
    case StepEnum.OFFERCREATION:
      return Step.OFFERCREATION;
    case StepEnum.QUESTIONNAIRE:
      return Step.QUESTIONNAIRE;
    case StepEnum.BENEFICIARY:
      return Step.BENEFICIARY;
    case StepEnum.IDENTIFICATION:
      return Step.IDENTIFICATION;
    case StepEnum.RESUMEIDENTIFICATION:
      return Step.RESUMEIDENTIFICATION;
    case StepEnum.IDENTIFICATIONREJECTED:
      return Step.IDENTIFICATIONREJECTED;
    case StepEnum.IDENTIFICATIONFAILED:
      return Step.IDENTIFICATIONFAILED;
    case StepEnum.WAITINGKYC:
      return Step.WAITINGKYC;
    case StepEnum.WAITINGACCEPTANCE:
      return Step.WAITINGACCEPTANCE;
    case StepEnum.WAITINGCOMPLIANCE:
      return Step.WAITINGCOMPLIANCE;
    case StepEnum.PAYMENT:
      return Step.PAYMENT;
    case StepEnum.SELECTWALLET:
      return Step.SELECTWALLET;
    case StepEnum.SETUPMOBILEWALLET:
      return Step.SETUPMOBILEWALLET;
    case StepEnum.PREPAREASSET:
      return Step.PREPAREASSET;
    case StepEnum.CONFIRMWALLET:
      return Step.CONFIRMWALLET;
    case StepEnum.FINAL:
      return Step.FINAL;
    case StepEnum.UNCONFIRMED:
      return Step.UNCONFIRMED;
    case StepEnum.ADDITIONALINFORMATION:
      return Step.ADDITIONALINFORMATION;
    case StepEnum.CANCELED:
      return Step.CANCELED;
  }

  return assertUnreachable(x);
};

export const convertStepToApi = (x: Step): StepEnum => {
  switch (x) {
    case Step.ACCOUNTSETUP:
      return StepEnum.ACCOUNTSETUP;
    case Step.INVESTMENTAMOUNT:
      return StepEnum.INVESTMENTAMOUNT;
    case Step.VIB:
      return StepEnum.VIB;
    case Step.OFFERCREATION:
      return StepEnum.OFFERCREATION;
    case Step.QUESTIONNAIRE:
      return StepEnum.QUESTIONNAIRE;
    case Step.BENEFICIARY:
      return StepEnum.BENEFICIARY;
    case Step.IDENTIFICATION:
      return StepEnum.IDENTIFICATION;
    case Step.RESUMEIDENTIFICATION:
      return StepEnum.RESUMEIDENTIFICATION;
    case Step.IDENTIFICATIONREJECTED:
      return StepEnum.IDENTIFICATIONREJECTED;
    case Step.IDENTIFICATIONFAILED:
      return StepEnum.IDENTIFICATIONFAILED;
    case Step.WAITINGKYC:
      return StepEnum.WAITINGKYC;
    case Step.WAITINGACCEPTANCE:
      return StepEnum.WAITINGACCEPTANCE;
    case Step.WAITINGCOMPLIANCE:
      return StepEnum.WAITINGCOMPLIANCE;
    case Step.PAYMENT:
      return StepEnum.PAYMENT;
    case Step.SELECTWALLET:
      return StepEnum.SELECTWALLET;
    case Step.PREPAREASSET:
      return StepEnum.PREPAREASSET;
    case Step.SETUPMOBILEWALLET:
      return StepEnum.SETUPMOBILEWALLET;
    case Step.CONFIRMWALLET:
      return StepEnum.CONFIRMWALLET;
    case Step.FINAL:
      return StepEnum.FINAL;
    case Step.UNCONFIRMED:
      return StepEnum.UNCONFIRMED;
    case Step.ADDITIONALINFORMATION:
      return StepEnum.ADDITIONALINFORMATION;
    case Step.CANCELED:
      return StepEnum.CANCELED;
  }

  return assertUnreachable(x);
};

export const convertPlatformUrl = (x: PlatformUrl) => {
  switch (x) {
    case PlatformUrl.TERMS_AND_CONDITIONS:
      return 'tosUrl';
    case PlatformUrl.PRIVACY_POLICY:
      return 'privacyUrl';
    case PlatformUrl.IMPRINT:
      return 'imprintUrl';
  }

  return assertUnreachable(x);
};
