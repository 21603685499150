import React, { FunctionComponent, useContext } from 'react';
import { BenefitingPerson, IdentificationMethodEnum, InvestorStepEnum, KycInformation, StepEnum, Document } from 'api';
import LoadingRing from 'ui/atoms/loading-ring';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import BenefitingPersonsSummary from 'ui/molecules/benefiting-persons-summary';
import { AnyServerError } from 'hooks/use-api-error';
import DummyKycLandingPage from './dummy-kyc-landing-page';
import PostIdentVerificationComponent from './post-ident-verification-component';
import IdNowLandingPage from './id-now-landing-page';
import IdentificationPending from './identification-pending';
import IdentificationErrorPage from './identification-error-page';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

export interface IdentificationStepProps {
  showLoading: boolean;
  step?: StepEnum | InvestorStepEnum;
  apiError?: AnyServerError;
  information?: KycInformation;
  apiLoading: boolean;
  redirectLoading: boolean;
  startIdentification: () => void;
  identificationFormDoc: Document | null;
  identificationInPerson: boolean;
  benefitingPersons?: Array<BenefitingPerson>;
}

const IdentificationStep: FunctionComponent<IdentificationStepProps> = ({
  showLoading,
  step,
  apiError,
  information,
  apiLoading,
  redirectLoading,
  startIdentification,
  identificationFormDoc,
  identificationInPerson,
  benefitingPersons,
}) => {
  const { changeStep } = useContext(WizardContext);

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (apiError || !information) return null;

  if (showLoading) {
    return <LoadingRing />;
  }

  const title = <Translate name="identification.title" />;

  if (step === StepEnum.IDENTIFICATIONREJECTED) {
    return (
      <>
        <WizardHeader />
        <Header size="large" spacing="xlarge">
          {title}
        </Header>
        <IdentificationErrorPage />
      </>
    );
  }

  const IdentificationComponent =
    {
      [IdentificationMethodEnum.DUMMY]: DummyKycLandingPage,
      [IdentificationMethodEnum.LIONWARE]: PostIdentVerificationComponent,
      [IdentificationMethodEnum.POSTIDENT]: PostIdentVerificationComponent,
      [IdentificationMethodEnum.IDNOW]: IdNowLandingPage,
      [IdentificationMethodEnum.OTHER]: DummyKycLandingPage,
    }[information.method] || React.Fragment;

  if (step === StepEnum.WAITINGKYC) {
    return <IdentificationPending title={title} />;
  }

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <IdentificationComponent
        error={apiError}
        loading={apiLoading || redirectLoading}
        alreadyStarted={step === StepEnum.RESUMEIDENTIFICATION}
        onClick={startIdentification}
        identificationFormDoc={identificationFormDoc}
        identificationInPerson={identificationInPerson}
      />
      {benefitingPersons && (
        <BenefitingPersonsSummary
          onEditClick={() => changeStep(StepEnum.BENEFICIARY)}
          benefitingPersons={benefitingPersons}
        />
      )}
    </>
  );
};

export default IdentificationStep;
