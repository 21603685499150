import React, { FunctionComponent, useState, useCallback, ReactNode } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi } from 'api/apis';
import { AdminInvestorList } from 'api/models';
import StudioInvestorsTable from './studio-investors-table';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import WideContent from 'core/layout/content/wide-content';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import {
  FilterDateOption,
  FilterInputOption,
  FilterPopup,
  FilterRange,
  FiltersWrapper,
  Search,
} from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import FilterMultiSelect from 'ui/molecules/filtering/filter-popup/filter-multi-select';
import { useProducts, useProductsFilter } from 'apps/issuer/hooks';
import { useFilterValue } from 'src/ui/molecules/filtering/hooks';
import { filterPossiblyNullStringValues } from 'src/ui/molecules/filtering/helpers';
import AddInvestorModal from './add-investor-modal';
import Button from 'src/ui/atoms/button';
import { useServerConfigSelector } from 'src/core/config/hooks';

interface InvestorsTableProps {
  defaultOrdering: DataRecordOrdering;
  defaultLimit: number;
  forceDataLoad?: boolean;
  filters?: ReactNode;
}

const IssuerInvestorsPageFilters: FunctionComponent<{}> = () => {
  const translate = useTranslateWithStringArgs();

  const { tokens } = useProducts();
  const { productsFilter } = useProductsFilter(tokens);

  const filterLabels = {
    activeInvestmentsNo: 'activeInvestmentsNo',
    activeInvestmentsNoMin: 'activeInvestmentsNoMin',
    activeInvestmentsNoMax: 'activeInvestmentsNoMax',
    activeInvestmentsSum: 'activeInvestmentsSum',
    activeInvestmentsSumMin: 'activeInvestmentsSumMin',
    activeInvestmentsSumMax: 'activeInvestmentsSumMax',
    registeredAt: 'registeredAt',
    registeredAtBefore: 'registeredAtBefore',
    registeredAtAfter: 'registeredAtAfter',
  };

  return (
    <>
      <Search input="search" defaultField="search" label={translate('filters.labels.investors')} name="search" />
      <FilterPopup>
        <FilterMultiSelect
          label={translate('studioInvestmentsTable.product')}
          options={[
            ...productsFilter,
            {
              value: null,
              label: translate('studioInvestorsTable.noProducts'),
              name: 'no_token',
            },
          ]}
          input="tokenId"
          defaultField="tokenId"
        />
        <FilterRange
          label={translate('filters.labels.activeInvestments')}
          inputNames={[filterLabels.activeInvestmentsNoMin, filterLabels.activeInvestmentsNoMax]}
          filterName={filterLabels.activeInvestmentsNo}
        >
          <FilterInputOption
            input={filterLabels.activeInvestmentsNoMin}
            pairedInput={filterLabels.activeInvestmentsNoMax}
            type="number"
            label={null}
            defaultField={filterLabels.activeInvestmentsNoMin}
            name={filterLabels.activeInvestmentsNo}
          />
          <FilterInputOption
            input={filterLabels.activeInvestmentsNoMax}
            pairedInput={filterLabels.activeInvestmentsNoMin}
            type="number"
            label={null}
            defaultField={filterLabels.activeInvestmentsNoMax}
            name={filterLabels.activeInvestmentsNo}
          />
        </FilterRange>
        <FilterRange
          label={translate('filters.labels.activeInvestmentsAmount')}
          inputNames={[filterLabels.activeInvestmentsSumMin, filterLabels.activeInvestmentsSumMax]}
          filterName={filterLabels.activeInvestmentsSum}
        >
          <FilterInputOption
            input={filterLabels.activeInvestmentsSumMin}
            pairedInput={filterLabels.activeInvestmentsSumMax}
            type="number"
            label={null}
            defaultField={filterLabels.activeInvestmentsSumMin}
            name={filterLabels.activeInvestmentsSum}
          />
          <FilterInputOption
            input={filterLabels.activeInvestmentsSumMax}
            pairedInput={filterLabels.activeInvestmentsSumMin}
            type="number"
            label={null}
            defaultField={filterLabels.activeInvestmentsSumMax}
            name={filterLabels.activeInvestmentsSum}
          />
        </FilterRange>
        <FilterRange
          label={translate('filters.labels.registeredOn')}
          inputNames={[filterLabels.registeredAtAfter, filterLabels.registeredAtBefore]}
          filterName={filterLabels.registeredAt}
        >
          <FilterDateOption
            input={filterLabels.registeredAtAfter}
            pairedInput={filterLabels.registeredAtBefore}
            label={null}
            defaultField={filterLabels.registeredAtAfter}
            maxDate={useFilterValue(filterLabels.registeredAtBefore) as Date}
            name={filterLabels.registeredAt}
          />
          <FilterDateOption
            input={filterLabels.registeredAtBefore}
            pairedInput={filterLabels.registeredAtAfter}
            label={null}
            defaultField={filterLabels.registeredAtBefore}
            minDate={useFilterValue(filterLabels.registeredAtAfter) as Date}
            name={filterLabels.registeredAt}
          />
        </FilterRange>
      </FilterPopup>
    </>
  );
};

const IssuerInvestorsDataRecordTable: FunctionComponent<InvestorsTableProps> = WithDataRecord<
  AdminApi,
  AdminInvestorList
>(AdminApi)(
  ({ data, ordering, onOrderBy, loading, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.investorDetails);

    return (
      <>
        {filters && <>{filters}</>}
        <StudioInvestorsTable
          onOrderBy={onOrderBy}
          ordering={ordering}
          investors={data}
          loading={loading}
          emptyView={<IssuerEmptyView type={EmptyViewType.ISSUER_INVESTORS} />}
          goToDetails={(investorId: string) => goTo({ investorId })}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    const { formattedFilter, restOfFilters } = filterPossiblyNullStringValues(props, 'tokenId');

    return api.adminInvestorsList({
      offset,
      limit,
      ordering,
      ...formattedFilter,
      ...restOfFilters,
    });
  },
);

const IssuerInvestorsPage: FunctionComponent<{}> = () => {
  const { config } = useServerConfigSelector();

  const [isAddInvestorModalOpen, setAddInvestorModalOpen] = useState(false);
  const [forceDataLoad, setForceDataLoad] = useState(false);

  const refreshTableData = useCallback(() => {
    setForceDataLoad(true);
    setAddInvestorModalOpen(false);
    setForceDataLoad(false);
  }, []);

  return (
    <WideContent title={<Translate name="dashboardIssuerInvestors.title" />}>
      {config.features.canCreateInvestor && (
        <Button
          variant="secondary"
          icon="plus"
          size="xsmall"
          iconSize="small"
          onClick={() => setAddInvestorModalOpen(true)}
        >
          <Translate name="common.addEntry" />
        </Button>
      )}
      <IssuerInvestorsDataRecordTable
        defaultOrdering={{ direction: 'asc', fieldName: 'last_name' }}
        defaultLimit={50}
        forceDataLoad={forceDataLoad}
        filters={
          <FiltersWrapper>
            <IssuerInvestorsPageFilters />
          </FiltersWrapper>
        }
      />
      {isAddInvestorModalOpen && (
        <AddInvestorModal onClose={() => setAddInvestorModalOpen(false)} onAddNewInvestor={refreshTableData} />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerInvestorsPage, AppType.ISSUER);
