import { makeApi, makeAuthenticatedApi } from 'core/api';
import { UsersApi } from 'api';

import type { User } from 'api';
import { Cmd } from 'redux-loop';
import { ApiError } from 'core/api/errors';
import { isAuthExpired } from 'core/api/helpers';

export const AUTH_TOKEN = 'authToken';

export function getLocalStorageAuth(): string | null {
  try {
    return localStorage.getItem(AUTH_TOKEN);
  } catch (e) {
    return null;
  }
}

export function setLocalStorageAuth(authToken: string): void {
  try {
    return localStorage.setItem(AUTH_TOKEN, authToken);
  } catch (e) {
    return;
  }
}

export function removeLocalStorageAuth(): void {
  try {
    return localStorage.removeItem(AUTH_TOKEN);
  } catch (e) {
    return;
  }
}

export function verifyInitialAuthEffect(): string {
  const authToken = getLocalStorageAuth();

  if (!authToken || isAuthExpired(authToken)) {
    throw new ApiError();
  }

  return authToken;
}

export async function getUserEffect(getState: Cmd.GetState): Promise<User> {
  const authToken = getLocalStorageAuth();

  if (!authToken) {
    throw new ApiError();
  }

  const usersApi = makeAuthenticatedApi(UsersApi, getState);
  const user = await usersApi.usersMeRetrieve();

  if (!user.email) {
    throw new ApiError();
  }

  return user;
}

export async function loginEffect(email: string, password: string): Promise<string> {
  const usersApi = makeApi(UsersApi);
  const { access } = await usersApi.usersLoginCreate({
    simpleTokenObtainRequest: {
      email,
      password,
    },
  });

  if (!access) {
    throw new ApiError();
  }

  return access;
}

export async function setUserLanguageEffect(language: string, getState: Cmd.GetState): Promise<User> {
  const usersApi = makeAuthenticatedApi(UsersApi, getState);

  return await usersApi.usersMePartialUpdate({
    patchedUserRequest: { language },
  });
}
