import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import Translate from 'ui/atoms/translate';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import PublicPage from 'core/auth/components/public-page';
import ForgotPassword, { ForgotPasswordFields, ForgotPasswordForm } from './forgot-password-form';
import { useApiCall } from 'hooks/use-api-call';
import { UsersApi } from 'api/apis';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import useGoTo from 'src/hooks/use-go-to';
import { AUTH_ROUTES } from '../routes.config';

const ForgotPasswordPage: FunctionComponent<{}> = () => {
  const { withApi, makeAuthenticatedApi, loading, error } = useApiCall();

  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  const [email, setEmail] = useState<string>();

  const api: UsersApi = useMemo(() => {
    return makeAuthenticatedApi(UsersApi);
  }, [makeAuthenticatedApi]);

  const onSubmit = useCallback(
    (values: ForgotPasswordFields) => {
      withApi(async () => {
        await api.usersPasswordResetCreate({
          resetPasswordRequest: { email: values.email },
        });
        setEmail(values.email);
        setPasswordResetEmailSent(true);
      });
    },
    [withApi, api],
  );

  const redirectToLogin = useGoTo(AUTH_ROUTES.login);

  const goBackToLogin = () => {
    redirectToLogin();
    setPasswordResetEmailSent(false);
  };

  return (
    <PublicPage>
      <NarrowContent>
        <BoxedContent title={<Translate name="forgotPassword.title" />}>
          <ForgotPasswordForm onSubmit={onSubmit} error={error} i18nKey="forgotPassword">
            <ForgotPassword passwordResetEmailSent={passwordResetEmailSent} email={email} />
            <ForgotPasswordForm.GenericErrorMessages />
            <ActionButtons>
              {!passwordResetEmailSent && (
                <Button type="submit" variant="primary" size="large" fluid={true} loading={loading}>
                  <Translate name="forgotPassword.resetPassword" />
                </Button>
              )}
              {passwordResetEmailSent && (
                <Button variant="link" size="large" onClick={goBackToLogin}>
                  <Translate name="forgotPassword.back" />
                </Button>
              )}
            </ActionButtons>
          </ForgotPasswordForm>
        </BoxedContent>
      </NarrowContent>
    </PublicPage>
  );
};

export default ForgotPasswordPage;
