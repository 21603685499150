import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import { useApiCall } from 'hooks/use-api-call';
import { AdminApi, InvestmentInvitation } from 'api';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import useGoTo from 'hooks/use-go-to';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import BoxedIssuerInvestmentInvitation from 'apps/issuer/pages/product-details/invitations/invitation-details/boxed-invitation';
import Link from 'core/routing/link';
import InvitationInvestment from 'apps/issuer/pages/product-details/invitations/invitation-details/invitation-investment';

const InvitationDetailsPage = (props: { invitationId: string }) => {
  const { invitationId } = props;

  const { withApi, makeAuthenticatedApi } = useApiCall(true);

  const [invitationDetails, setInvitationDetails] = useState<InvestmentInvitation>();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!invitationId) {
        return;
      }
      withApi(async () => {
        const invitationDetailsData = await api.adminInvitationsRetrieve({
          id: invitationId,
        });
        setInvitationDetails(invitationDetailsData as InvestmentInvitation);
      });
    });
  }, [withApi, api, invitationId]);

  const goToProductDetails = useGoTo(ISSUER_ROUTES.productDetails);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!invitationDetails) return null;

  return (
    <WideContent title={invitationDetails?.productName}>
      <Section spacing="medium">
        <Translate
          name={'issuerProductDetails.invitations.invitationDetails.subtitle'}
          args={[invitationDetails.name]}
        />
      </Section>
      <BoxedIssuerInvestmentInvitation
        key={invitationDetails.id}
        issuersCanManage
        onDeactivated={goToProductDetails}
        {...invitationDetails}
      />
      <Section spacing="medium">
        <h2>
          <Translate name={'issuerProductDetails.invitations.invitationDetails.investments.title'} />
        </h2>
        <p>
          <Translate name={'issuerProductDetails.invitations.invitationDetails.investments.subtitle'} />
        </p>
      </Section>
      <InvitationInvestment investmentId={invitationDetails.investment} />
      <Section spacing="medium">
        <Link icon="arrow-left" to={`/dashboard/issuerProduct/${invitationDetails.token.id}`}>
          <Translate as="span" name="issuerProductDetails.campaigns.campaignDetails.backToProductDetails" />
        </Link>
      </Section>
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvitationDetailsPage, AppType.ISSUER);
