import React, { FunctionComponent } from 'react';

import Translate from 'ui/atoms/translate';
import LoadingRing from 'ui/atoms/loading-ring';

import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';

import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import useApiCall from 'hooks/use-api-call';
import useOnlyOnce from 'hooks/use-only-once';
import { InvitationsApi, Invitation } from 'api';
import { InvitationToNonPreviewInvitation } from 'helper/cast';
import useGoTo from 'hooks/use-go-to';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import { getRegistrationLocalStorage } from 'subapps/investment/helpers';

// Redirect to the most recently updated invitation of the current investor.
// Later if needed we can update this to support also investments or
// "any" (invitation OR investment)
const InvitationContinuePage: FunctionComponent = () => {
  const goToInvesment = useGoTo(INVESTMENT_ROUTES.investment);
  const goToRegister = useGoTo(INVESTMENT_ROUTES.register);
  const goToDashboard = useGoTo(INVESTOR_ROUTES.overview);

  const { error, loading, makeAuthenticatedApi, withApi } = useApiCall();

  const api: InvitationsApi = makeAuthenticatedApi(InvitationsApi);

  useOnlyOnce(() => {
    const isRegistering = getRegistrationLocalStorage();

    if (isRegistering) {
      goToRegister();
      return;
    }

    withApi(async () => {
      const invitations = await api.invitationsList({
        limit: 1000,
      });
      let transferInvitations: Invitation[] | undefined;
      if (!invitations.results?.length) {
        const transferInvitationsResponse = await api.invitationsList({
          limit: 1000,
          invitationType: 'transfer_invitation',
        });
        transferInvitations = transferInvitationsResponse.results;
      }
      if (transferInvitations?.length) {
        const transferInvitation = transferInvitations.find((inv) => {
          return !InvitationToNonPreviewInvitation(inv)?.investment;
        });
        if (transferInvitation?.id) {
          goToInvesment({
            invitationId: transferInvitation.id,
          });
        }
      } else {
        const latest = invitations.results?.find((inv) => {
          return !InvitationToNonPreviewInvitation(inv)?.investment;
        });
        if (latest?.id) {
          goToInvesment({
            invitationId: latest.id,
          });
        } else {
          goToDashboard();
        }
      }
    });
  });

  return (
    <NarrowContent>
      <BoxedContent>
        {loading && <LoadingRing />}
        {error && <Translate name="errors.unknown" />}
      </BoxedContent>
    </NarrowContent>
  );
};

export default WithAuthenticatedPage(InvitationContinuePage, AppType.INVESTMENT);
