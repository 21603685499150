import React, { FunctionComponent } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import { Money } from 'ui/types/money';
import { RuleType } from 'ui/types/investment';
import Country from 'ui/types/country';
import Translate from 'ui/atoms/translate';
import Number from 'ui/atoms/number';
import Currency from 'ui/atoms/currency';
import { camelCase } from 'change-case';
import { InvestmentToken } from 'api';
import { ProductCampaignsStatus } from 'apps/issuer/pages/product-details/campaigns/shared/product-campaign-status';
import Date from 'ui/atoms/date';

export interface ProductCampaignCardProps {
  /** Additional classes. */
  className?: string;

  allocation: number;

  allocationAvailable: number;

  minNumberOfTokens: number;

  maxNumberOfTokens: number;

  pricePerToken: Money;

  ruleType: RuleType;

  country: Country;

  active?: boolean;

  token?: InvestmentToken;

  name?: string | null;

  signedPercentage: number;

  createdAt: Date;
}

const ProductCampaignCard: FunctionComponent<ProductCampaignCardProps> = (props) => {
  const {
    className,
    allocation,
    minNumberOfTokens,
    maxNumberOfTokens,
    pricePerToken,
    ruleType,
    country,
    active,
    token,
    createdAt,
    signedPercentage,
    name,
  } = props;

  return (
    <div className={className}>
      <InfoGrid columns={3} truncate={true}>
        <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.product" truncate={true} />}>
          {token?.name}
        </InfoField>
        <InfoField
          title={<Translate name="issuerProductDetails.campaigns.campaignDetails.typeOfOffering" truncate={true} />}
        >
          <Translate
            name={
              ruleType === RuleType.MinimumTicketSize
                ? `ruleType.minimumTicketSizeAlternative`
                : `ruleType.${camelCase(ruleType)}`
            }
          />
        </InfoField>
        <InfoField
          title={<Translate name="issuerProductDetails.campaigns.campaignDetails.createdAt" truncate={true} />}
        >
          <Date>{createdAt}</Date>
        </InfoField>
        <InfoField
          title={<Translate name="issuerProductDetails.campaigns.campaignDetails.reference" truncate={true} />}
        >
          {name || '-'}
        </InfoField>
        <InfoField
          title={<Translate name="issuerProductDetails.campaigns.campaignDetails.investmentsRange" truncate={true} />}
        >
          <Translate
            name="studioCampaignInvestmentCard.nrOfTokenRange.content"
            args={[
              (_, key) => <Number key={key}>{minNumberOfTokens}</Number>,
              (_, key) => <Number key={key}>{maxNumberOfTokens}</Number>,
            ]}
          />
        </InfoField>
        <InfoField title={<Translate name="studioCampaignInvestmentCard.pricePerToken" truncate={true} />}>
          <Currency decimals="*">{pricePerToken}</Currency>
        </InfoField>
        <InfoField
          title={<Translate name="issuerProductDetails.campaigns.campaignDetails.allocation.title" truncate={true} />}
        >
          <Translate
            name="issuerProductDetails.campaigns.campaignDetails.allocation.content"
            args={[(_, key) => <Number key={key}>{allocation}</Number>]}
          />
        </InfoField>
        <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.signed" truncate={true} />}>
          {`${signedPercentage.toFixed()}%`}
        </InfoField>
        <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.market" truncate={true} />}>
          <Translate name={`countries.${country}`} />
        </InfoField>
        <InfoField title={<Translate name="studioCampaignInvestmentCard.statusLabel" truncate={true} />}>
          <ProductCampaignsStatus active={active} />
        </InfoField>
      </InfoGrid>
    </div>
  );
};

export default ProductCampaignCard;
