import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Spacer from 'ui/atoms/spacer';
import Currency from 'ui/atoms/currency';
import Button from 'ui/atoms/button';
import { Money } from 'ui/types/money';
import { toMoney } from 'ui/helper/money';
import ActionButtons from 'ui/molecules/action-buttons';

export interface SavedNetworthProps {
  savedNetWorth: Money | null;
  restartCalculation: () => void;
  onSkipNetworth: () => void;
}

const SavedNetworth: FunctionComponent<SavedNetworthProps> = (props) => {
  const { savedNetWorth, restartCalculation, onSkipNetworth } = props;

  return (
    <>
      <Translate
        name="lossAbility.alreadyCalculatedWizard"
        args={[
          (_, key) => (
            <strong>
              <Currency key={key}>{savedNetWorth || toMoney(0)}</Currency>
            </strong>
          ),
        ]}
      />
      <Spacer y={4} />
      <ActionButtons>
        <Button variant="secondary" size="large" onClick={restartCalculation}>
          <Translate name="lossAbility.restartCalculation" />
        </Button>
        <Button variant="primary" size="large" onClick={onSkipNetworth}>
          <Translate name="questionnaire.selectionsAccurate" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default SavedNetworth;
