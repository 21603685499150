import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import type { FunctionComponent } from 'react';
import type { ComponentRouting, Routes } from 'core/routing/types';
import RenderRoute from './render-route';
import PageNotFound from 'core/components/not-found';

export interface RouteRendererProps {
  routes: Routes | null;
  componentRouting: ComponentRouting;
  legacyRedirects?: {
    from: string;
    to: string;
    exact?: boolean;
  }[];
}

const RoutesRenderer: FunctionComponent<RouteRendererProps> = ({ routes, componentRouting, legacyRedirects }) => {
  if (!routes) return null;

  return (
    <Switch>
      {Object.entries(routes).map(([key, route]) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          children={(props) => <RenderRoute Component={componentRouting[key]} route={route} {...props} />}
        />
      ))}
      {legacyRedirects
        ? legacyRedirects.map((redirect) => {
            return (
              <Route key={redirect.from} exact={redirect.exact} path={redirect.from}>
                <Redirect to={redirect.to} />
              </Route>
            );
          })
        : null}
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default RoutesRenderer;
