import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WideContent from 'core/layout/content/wide-content';
import IssuerInvestmentsTable from 'apps/issuer/shared/investments-table';
import { hasDownloadStatisticsPermission } from 'apps/issuer/helpers/permissions';
import DownloadInvestmentData from './download-investment-data';
import { useIssuerSelector } from 'apps/issuer/hooks';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';

const IssuerInvestmentsPage: FunctionComponent = () => {
  const { tokens } = useIssuerSelector();

  const hasDownloadPermission = hasDownloadStatisticsPermission(tokens);

  return (
    <WideContent
      title={<Translate name="dashboardInvestorInvestments.title" />}
      headerPanel={hasDownloadPermission && <DownloadInvestmentData />}
    >
      <IssuerInvestmentsTable
        filters={<IssuerInvestmentsFilters />}
        defaultOrdering={{ direction: 'desc', fieldName: 'signed_date' }}
        defaultLimit={50}
      />
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerInvestmentsPage, AppType.ISSUER);
