import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Section from 'ui/atoms/section';
import { InvestorOverview } from 'api';
import Spacer from 'src/ui/atoms/spacer';
import * as Styled from './styled';

export interface OpenTransactionsProps {
  investorOverview: InvestorOverview;
}

const OpenTransactions: FunctionComponent<OpenTransactionsProps> = (props) => {
  const { investorOverview } = props;

  return (
    <Section spacing="large">
      <Hint variant="info">
        <div>
          <Translate
            name="dashboardInvestorOverview.openInvestments"
            grammaticalNumber={investorOverview.numberOpenTransactions}
            args={[investorOverview.numberOpenTransactions]}
          />
          <Spacer x={2} inline />
          <Styled.RedirectButton to="/dashboard/investorContinueInvestments" variant="secondary" size="xsmall">
            <Translate name="common.next" />
          </Styled.RedirectButton>
        </div>
      </Hint>
    </Section>
  );
};

export default OpenTransactions;
