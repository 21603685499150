import { resolveColor } from 'ui/styles/utils/colors';
import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';

export interface RadioContainerProps {
  $spacing: Spacing;
}

export interface RadioProps {
  $disabled?: boolean;
  $checked?: boolean;
  $isInvalid?: boolean;
}

export interface RadioCheckmarkProps {
  $disabled?: boolean;
  $checked?: boolean;
}

export interface LabelProps {
  $disabled?: boolean;
  $checked?: boolean;
}

function getCheckedRadio({ theme, $checked, $disabled }: ThemedStyledProps<LabelProps, DefaultTheme>) {
  if (!$checked) return null;

  return css<LabelProps>`
    background: ${resolveColor(theme, $disabled ? 'grayDark' : 'primary')};
    border: 1px solid ${resolveColor(theme, $disabled ? 'grayDark' : 'primary')};

    ${!$disabled &&
    `&:hover {
      background-color: ${resolveColor(theme, $disabled ? 'grayDark' : 'primaryHover')};
      border: 1px solid ${resolveColor(theme, $disabled ? 'grayDark' : 'primaryHover')}
    }`}
  `;
}

function getRadioContainerStyle({ theme, $spacing }: ThemedStyledProps<RadioContainerProps, DefaultTheme>) {
  return css<RadioContainerProps>`
    padding-right: ${theme.spacing[$spacing]}rem;
    font-size: 14px;
    position: relative;
    display: flex;
    width: min-content;

    ${query.phone`
      font-size: 12px;
    `};
  `;
}

function getRadioCheckmark({ theme, $checked, $disabled }: ThemedStyledProps<RadioCheckmarkProps, DefaultTheme>) {
  if (!$checked) return null;

  return css`
    position: absolute;
    content: '';
    display: block;
    background: ${resolveColor(theme, $disabled ? 'grayHover' : 'white')};
    top: 8px;
    left: 8px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  `;
}

function getDisabledCheckmark({ theme, $disabled }: ThemedStyledProps<RadioCheckmarkProps, DefaultTheme>) {
  if (!$disabled) return null;

  return css`
    border-color: ${resolveColor(theme, 'grayHover')};
    cursor: not-allowed;
  `;
}

function getRadioStyle({ theme, $disabled, $isInvalid }: ThemedStyledProps<RadioProps, DefaultTheme>) {
  return css`
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    border: 1px solid ${$isInvalid ? resolveColor(theme, 'danger') : theme.input.borderColor};
    border-radius: 50%;
    cursor: pointer;

    ${!$disabled &&
    `
      &:hover {
        background-color: ${resolveColor(theme, 'primaryLight')};
        border: 1px solid ${resolveColor(theme, 'primaryHover')}
      }
      `}
  `;
}

function getDisabledRadio({ theme, $disabled }: ThemedStyledProps<RadioProps, DefaultTheme>) {
  if (!$disabled) return null;

  return css`
    background-color: ${resolveColor(theme, 'grayDark')};
    border: none;
    cursor: not-allowed;
  `;
}

export const RadioContainer = styled.span<RadioContainerProps>`
  ${getRadioContainerStyle};
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Radio = styled.span<RadioProps>`
  ${getRadioStyle};
  ${getCheckedRadio};
  ${getDisabledRadio};
`;

export const RadioCheckmark = styled.span<RadioCheckmarkProps>`
  ${getRadioCheckmark};
  ${getDisabledCheckmark};
`;

export const Label = styled.label<LabelProps>`
  width: 1.5rem;
  margin-right: 0.5rem;
`;
