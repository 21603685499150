import styled from 'styled-components';
import Link from 'core/routing/link';

export const RedirectButton = styled(Link)`
  color: #303539;
  border: 1px solid #303539;

  &:hover {
    color: ${({ theme }) => theme.colors.grayDark};
    border-color: ${({ theme }) => theme.colors.grayDark};
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:focus-visible {
    color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.black};
    box-shadow: none;
  }
`;
