import styled from 'styled-components';
import Icon from 'ui/atoms/icon';
import { Col } from 'ui/atoms/grid';
import { query } from 'ui/styles/queries';

export const LeftColumn = styled(Col)`
  && {
    ${query.lTablet`
      padding-right: 0.5rem;
    `}
    ${query.desktops`
      padding-right: 0.5rem;
    `}
  }
`;

export const RightColumn = styled(Col)`
  && {
    ${query.lTablet`
      padding-left: 0.5rem;
    `}
    ${query.desktops`
      padding-left: 0.5rem;
  `}
  }
`;

export const DeleteIcon = styled(Icon)`
  margin: 0 0.5rem;
`;
