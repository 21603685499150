import { useMemo } from 'react';
import { toNumber } from 'ui/helper/money';
import { Money } from 'ui/types/money';

export function useIsNetWorthSuitable(
  lossability: Money | null | undefined,
  investmentTotal: Money | null | undefined,
) {
  return useMemo(() => {
    if (!lossability || !investmentTotal) return false;
    return toNumber(lossability) >= toNumber(investmentTotal);
  }, [lossability, investmentTotal]);
}
