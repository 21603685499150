import useRoutesResolver from 'core/routing/hooks/use-routes-resolver';
import { ISSUER_ROUTES } from './routes.config';
import type { Feature, InvestmentToken } from 'api';

export function useIssuerNavigationItems({
  tokens,
  configFeatures,
}: {
  tokens: InvestmentToken[];
  configFeatures: Feature;
}) {
  return useRoutesResolver(ISSUER_ROUTES, {
    tokens,
    displayAllRoutes: true,
    configFeatures,
  });
}
