import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import StatusTag from 'ui/atoms/status-tag';
import { transferStatusToVariant } from 'ui/helper/variant';
import Date from 'ui/atoms/date';
import Table from 'ui/molecules/table';
import { AdminTokenTransfer, AdminTokenTransferTypeEnum } from 'api';
import LoadingRing from 'ui/atoms/loading-ring';
import Icon from 'ui/atoms/icon';
import * as Styled from './styled';
import Link from 'ui/atoms/link';
import useTranslate from 'ui/hooks/use-translate';
import { shorten } from 'helper/shorten';

export interface TransactionsTableProps {
  transactions: AdminTokenTransfer[];
  productId: string;
}

const TransactionsTable: FunctionComponent<TransactionsTableProps> = (props) => {
  const { transactions, productId } = props;

  const translate = useTranslate();

  if (!transactions) {
    return <LoadingRing />;
  }

  const getTransactionInvestors = (transaction: AdminTokenTransfer) => {
    let investmentLinkId = null;
    switch (transaction.type) {
      case AdminTokenTransferTypeEnum.HANDOVERRECIPIENT:
        investmentLinkId = transaction.investmentIdSender;
        break;
      case AdminTokenTransferTypeEnum.HANDOVERSENDER:
        investmentLinkId = transaction.investmentIdRecipient;
        break;
      default:
        break;
    }

    const investmentDetailsURL = investmentLinkId
      ? `/dashboard/issuerInvestment/${productId}/investment/${investmentLinkId}`
      : null;

    const issuerTranslation = translate('dashboardIssuerOverview.issuer');

    return (
      <>
        {transaction.investorNameSender ? shorten(transaction.investorNameSender, 20) : issuerTranslation}
        <Icon name="arrow-right" color="info" className="trans-icon" size="large" />
        {transaction.investorNameRecipient ? shorten(transaction.investorNameRecipient, 20) : issuerTranslation}
        {investmentDetailsURL && <Link href={investmentDetailsURL} className="external-link" external />}
      </>
    );
  };

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>
            <Translate name="portfolio.time" />
          </Table.HeadCell>
          <Table.HeadCell>
            <Translate name="portfolio.transaction" />
          </Table.HeadCell>
          <Table.HeadCell alignContent="right">
            <Translate name="portfolio.amount" />
          </Table.HeadCell>
          <Table.HeadCell>
            <Translate name="common.status" />
          </Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {(transactions || []).map((transaction, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>
                <Date showTime={true}>{transaction.createdAt}</Date>
              </Table.Cell>
              <Table.Cell>
                <Styled.TransInvestorInfo>{getTransactionInvestors(transaction)}</Styled.TransInvestorInfo>
              </Table.Cell>
              <Table.Cell alignContent="right">{transaction.amount}</Table.Cell>
              <Table.Cell>
                <StatusTag variant={transferStatusToVariant(transaction.status)}>
                  <Translate name={`portfolio.transferStatus.${transaction.status}`}>{transaction.status}</Translate>
                </StatusTag>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default TransactionsTable;
