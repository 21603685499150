import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import useInvestment from 'hooks/use-investment';
import useApiCall from 'hooks/use-api-call';
import { InvestmentsApi, InvitationsApi, TwoFactorAuthApi } from 'api/apis';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import { getWalletAddressForNetwork } from 'helper/network';
import UpdateMobileSafekeeping from 'src/subapps/investment/pages/investment/wizard-steps/wallet/setup-mobile-wallet/update-mobile-safekeeping';
import { StepEnum, TransferInvitation } from 'api/models';
import useInvestorMe from 'hooks/use-investor-me';
import CreateMobileSafekeepingWallet from 'subapps/investment/pages/investment/wizard-steps/wallet/setup-mobile-wallet/create-mobile-safekeeping-wallet';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const SetupMobileWalletStep: FunctionComponent<{}> = () => {
  const {
    investmentId,
    resourceId: invitationId,
    isTransferInvitation,
    finalize,
    changeStep,
  } = useContext(WizardContext);
  const { token, reload } = useInvestment(investmentId);
  const { investor, error: investorError } = useInvestorMe();

  const { error, makeAuthenticatedApi, withApi } = useApiCall();

  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);
  const investmentsApi: InvestmentsApi = useMemo(() => makeAuthenticatedApi(InvestmentsApi), [makeAuthenticatedApi]);

  let safekeepingAppUpdateNeeded = false;

  const load = useCallback(() => {
    withApi(async () => {
      let inv;
      if (isTransferInvitation) {
        inv = (await invitationsApi.invitationsRetrieve({
          id: invitationId,
        })) as TransferInvitation;
      } else {
        inv = await investmentsApi.investmentsRetrieve({
          id: investmentId,
        });
      }

      // Wizard step: SETUPMOBILEWALLET && investment has wallet -> app needs update
      safekeepingAppUpdateNeeded = !!inv?.wallet;

      const appUpdateSuccessfull = safekeepingAppUpdateNeeded && inv.step !== StepEnum.SETUPMOBILEWALLET;

      const walletSetupSuccessful =
        !safekeepingAppUpdateNeeded &&
        token?.tokenizationInfo?.networkType &&
        inv?.wallet &&
        getWalletAddressForNetwork(token?.tokenizationInfo.networkType, inv.wallet);

      if (appUpdateSuccessfull || walletSetupSuccessful) {
        reload();
        finalize();
      }
    });
  }, [withApi, investmentsApi, invitationsApi, reload, finalize, investmentId, token]);

  const twoFactorAuthApi: TwoFactorAuthApi = useMemo(
    () => makeAuthenticatedApi(TwoFactorAuthApi),
    [makeAuthenticatedApi],
  );

  const sendMobileSafeKeepingSetupEmail = useCallback(() => {
    withApi(async () => {
      await twoFactorAuthApi.twoFactorAuthDeviceInitializationCreate({
        tFADeviceInitializationRequest: {
          walletNeeded: true,
        },
      });
    });
  }, [withApi, twoFactorAuthApi]);

  const resetWalletOption = useCallback(() => {
    withApi(async () => {
      try {
        if (isTransferInvitation) {
          await invitationsApi.invitationsResetWalletSelectionCreate({
            id: invitationId,
          });
        } else {
          await investmentsApi.investmentsResetWalletSelectionCreate({
            id: investmentId,
          });
        }
        changeStep(StepEnum.SELECTWALLET);
      } catch (e) {
        console.error(e);
      }
    });
  }, [withApi, isTransferInvitation]);

  const timeoutHandler = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timeoutHandler.current = setInterval(load, 2000);
    return () => {
      if (timeoutHandler.current) {
        clearInterval(timeoutHandler.current);
      }
    };
  }, [load]);

  useEffect(() => {
    if (error && timeoutHandler.current) {
      clearInterval(timeoutHandler.current);
    }
  }, [error, timeoutHandler]);

  if (!investor) return <LoadingRing />;

  const title = safekeepingAppUpdateNeeded ? (
    <Translate name="updateMobileSafekeeping.title" />
  ) : (
    <Translate name="investmentDepot.title" />
  );

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      {safekeepingAppUpdateNeeded ? (
        <UpdateMobileSafekeeping />
      ) : (
        <CreateMobileSafekeepingWallet
          email={investor.email}
          onResendEmail={sendMobileSafeKeepingSetupEmail}
          error={error || investorError}
          resetWalletOption={resetWalletOption}
        />
      )}
    </>
  );
};

export default SetupMobileWalletStep;
