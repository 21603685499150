import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store/hooks';
import { invalidateAuth, login as loginAction } from './actions';
import {
  authIsVerifiedSelector,
  authTokenSelector,
  currentUserSelector,
  isLoggedInSelector,
  redirectPathSelector,
} from './selectors';
import { isInvestor } from './helper';

export function useCurrentUserSelector() {
  return useSelector(currentUserSelector);
}

export function useIsLoggedInSelector() {
  return useSelector(isLoggedInSelector);
}

export function useIsInvestor() {
  const { currentUser } = useCurrentUserSelector();

  if (!currentUser) return false;

  return isInvestor(currentUser);
}

export function useAuthIsVerified() {
  return useSelector(authIsVerifiedSelector);
}

export function useAuthTokenSelector() {
  return useSelector(authTokenSelector);
}

export function useRedirectPathSelector() {
  return useSelector(redirectPathSelector);
}

export function useLogin() {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserSelector();
  const { loading, error } = useAuthTokenSelector();

  const login = useCallback(
    (email: string, password: string) => {
      dispatch(loginAction(email, password));
    },
    [dispatch, loginAction],
  );

  return {
    currentUser,
    loading,
    error,
    login,
  };
}

export function useLogout() {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedInSelector();

  return useCallback(() => {
    if (!isLoggedIn) return;

    return dispatch(invalidateAuth());
  }, [isLoggedIn, dispatch, invalidateAuth]);
}
