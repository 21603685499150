import Icon from 'ui/atoms/icon';
import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const FormGroupLabel = styled.label`
  ${query.phone`
    font-size: 0.875rem;
  `};
`;

export const FormGroupRequirements = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RequirementsInfo = styled.div`
  display: flex;
`;

export const SameRow = styled.div`
  display: flex;

  ${query.phone`
    font-size: 0.875rem;
  `};
`;

export const RequirementInfoIcon = styled(Icon)`
  height: fit-content;
  margin-top: 0.25rem;
`;
