import React, { FunctionComponent, useRef, useState } from 'react';

import cx from 'ui/helper/prefixed-class-names';
import { toNumber, nullMoney } from 'ui/helper/money';

import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import NumberComponent from 'ui/atoms/number';
import Button from 'ui/atoms/button';
import Currency from 'ui/atoms/currency';
import Segment from 'ui/atoms/segment';
import { CurrencyEnum, Money } from 'ui/types/money';
import useTranslate from 'ui/hooks/use-translate';
import useClipboard from 'ui/hooks/use-clipboard';
import { compact } from 'lodash';
import { find, get } from 'lodash';
import { RuleType } from 'ui/types/investment';
import Hint from 'ui/atoms/hint';
import ServerError from 'ui/types/server-error';
import { StateValues } from 'react-use-form-state';
import useCountries from 'src/hooks/use-countries';

export interface CreateInvestmentInvitationFields {
  fullName: string;
  email: string;
  country: string;
  pricePerToken: string;
  maxNumberOfTokens: string;
  ruleType: RuleType;
  currency: string;
}

interface RuleSet {
  country: string;

  hasPrivatePlacement: boolean;

  hasPublicOffering: boolean;

  hasMinimumTicket: boolean;

  remainingInvitations: number;

  minimumTicketSize: Money;
}

interface CurrencyRate {
  readonly exchangeRate: Money;
  readonly baseCurrency: CurrencyEnum;
}

export interface CreateInvestmentInvitationModalProps {
  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: (values: CreateInvestmentInvitationFields) => void;

  /** On change callback */
  onChange?: (values: CreateInvestmentInvitationFields) => void;

  onCancelTriggered?: () => void;

  onCloseAfterSuccess?: () => void;

  /** Open? */
  open?: boolean;

  ruleSets?: RuleSet[];

  backToBackMinPrice?: Money;

  investmentTotal?: Money;

  valuation?: Money;

  maxNumberOfTokensUpperLimit?: number;

  calculationError?: boolean;

  investmentInvitationLink?: string;

  currencies?: CurrencyRate[];

  showShareInfo?: boolean;

  createInvitationError?: ServerError;

  loading?: boolean;
}

const CreateInvestmentInvitationForm = makeModalForm<CreateInvestmentInvitationFields>();

const CreateInvestmentInvitationModal: FunctionComponent<CreateInvestmentInvitationModalProps> = (props) => {
  const {
    className,
    open = false,
    onSubmit = () => {},
    onChange = () => {},
    onCancelTriggered = () => {},
    onCloseAfterSuccess = () => {},
    backToBackMinPrice = nullMoney,
    investmentTotal,
    valuation = nullMoney,
    showShareInfo,
    maxNumberOfTokensUpperLimit = 0,
    calculationError,
    investmentInvitationLink,
    ruleSets = [],
    currencies = [],
    loading,
    createInvitationError,
  } = props;

  const defaultCurrency = currencies.length === 1 ? undefined : get(currencies, '[0].exchangeRate.currency');

  const propsRef = useRef<CreateInvestmentInvitationModalProps>(props);
  propsRef.current = props;

  const translate = useTranslate();

  const { copied, copy } = useClipboard();

  const [createInvestmentValues, setCreateInvestmentValues] = useState<CreateInvestmentInvitationFields>();

  const activeCurrency = find(currencies, {
    exchangeRate: {
      currency: createInvestmentValues?.currency || defaultCurrency,
    },
  });

  const ruleSet: RuleSet = find(ruleSets, {
    country: createInvestmentValues?.country,
  }) || {
    country: '',
    hasPrivatePlacement: false,
    hasPublicOffering: false,
    hasMinimumTicket: false,
    remainingInvitations: 0,
    minimumTicketSize: nullMoney,
  };

  const countriesList = useCountries(ruleSets.map((r) => r.country));

  const { hasPrivatePlacement, hasPublicOffering, hasMinimumTicket, remainingInvitations, minimumTicketSize } = ruleSet;

  const ruleTypes = compact([
    hasPrivatePlacement && RuleType.PrivatePlacement,
    hasPublicOffering && RuleType.PublicOffering,
    hasMinimumTicket && RuleType.MinimumTicketSize,
  ]) as RuleType[];

  const ruleTypeElements = {
    [RuleType.PrivatePlacement]: {
      name: <Translate name="ruleType.privatePlacement" />,
      requirement: createInvestmentValues?.country && remainingInvitations >= 1 && (
        <Translate
          name="investmentInvitation.information.privatePlacement"
          args={[remainingInvitations, translate(`countries.${createInvestmentValues.country}`)]}
        />
      ),
      validationError: createInvestmentValues?.country && (
        <Translate
          name="investmentInvitation.errors.privatePlacement"
          args={[translate(`countries.${createInvestmentValues.country}`)]}
        />
      ),
      valid: remainingInvitations >= 1,
    },
    [RuleType.MinimumTicketSize]: {
      name: (
        <Translate
          name="ruleType.minimumTicketSize"
          args={[(_, key) => <Currency key={key}>{minimumTicketSize}</Currency>]}
        />
      ),
      requirement: null,
      validationError: <Translate name="investmentInvitation.errors.minimumTicketSize" />,
      valid: investmentTotal && toNumber(investmentTotal) >= toNumber(minimumTicketSize),
    },
    [RuleType.PublicOffering]: {
      name: <Translate name="ruleType.publicOffering" />,
      requirement: null,
      validationError: null,
      valid: true,
    },
  };

  if (!open) return null;

  if (investmentInvitationLink) {
    return (
      <Modal onClose={onCloseAfterSuccess}>
        <ModalHeader>
          <Translate name="investmentInvitation.sendInvestment" />
        </ModalHeader>
        <ModalContent>
          <p>
            <Translate
              name="investmentInvitation.sendInvestmentDescription"
              args={[createInvestmentValues?.fullName]}
            />
          </p>

          <Segment padded="tiny" inverted={true}>
            {investmentInvitationLink}
          </Segment>

          <Button variant="link" onClick={() => copy(investmentInvitationLink)} disabled={copied}>
            {copied ? <Translate name="copy.link.copied" /> : <Translate name="copy.link.copy" />}
          </Button>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'overview',
                content: <Translate name="investmentInvitation.investmentOverview" />,
                variant: 'primary',
                size: 'large',
                onClick: onCloseAfterSuccess,
              },
            ]}
          />
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal onClose={onCancelTriggered} className={cx('create-investment-invitation-modal', className)}>
      <CreateInvestmentInvitationForm
        onSubmit={onSubmit}
        onChange={(values: StateValues<CreateInvestmentInvitationFields>) => {
          setCreateInvestmentValues(values);
          onChange(values);
        }}
        error={createInvitationError}
        i18nKey="investmentInvitationForm"
        initial={{
          country: '',
          currency: defaultCurrency,
        }}
      >
        <ModalHeader>
          <Translate name="investmentInvitation.create.long" />
        </ModalHeader>
        <ModalContent>
          <CreateInvestmentInvitationForm.ValueProvider>
            {({ ruleType, maxNumberOfTokens, pricePerToken, country }) => (
              <>
                <CreateInvestmentInvitationForm.Group name="fullName" required={true}>
                  <CreateInvestmentInvitationForm.Input autoFocus={true} />
                </CreateInvestmentInvitationForm.Group>
                <CreateInvestmentInvitationForm.Group name="email" required={true}>
                  <CreateInvestmentInvitationForm.Input />
                  {CreateInvestmentInvitationForm.Validators.Email()}
                </CreateInvestmentInvitationForm.Group>
                <CreateInvestmentInvitationForm.Group name="country" required={true}>
                  <CreateInvestmentInvitationForm.Select
                    options={countriesList}
                    onChange={(country: string) => {
                      // @ts-ignore
                      setCreateInvestmentValues({ ...createInvestmentValues, country });
                    }}
                  ></CreateInvestmentInvitationForm.Select>
                </CreateInvestmentInvitationForm.Group>
                {defaultCurrency && (
                  <CreateInvestmentInvitationForm.Group
                    required={true}
                    name="currency"
                    info={
                      activeCurrency &&
                      activeCurrency.exchangeRate.currency !== activeCurrency.baseCurrency && (
                        <Translate
                          name="investmentInvitation.information.currency"
                          args={[
                            (_, key) => (
                              <Currency key={key}>
                                {{
                                  currency: activeCurrency.baseCurrency,
                                  amount: '100',
                                  decimals: 2,
                                }}
                              </Currency>
                            ),
                            (_, key) => (
                              <Currency key={key} decimals={6}>
                                {activeCurrency.exchangeRate}
                              </Currency>
                            ),
                          ]}
                        />
                      )
                    }
                  >
                    <CreateInvestmentInvitationForm.Select
                      options={currencies.map(({ exchangeRate }) => ({
                        value: exchangeRate.currency,
                        label: translate(`currencies.${exchangeRate.currency}`),
                      }))}
                    ></CreateInvestmentInvitationForm.Select>
                  </CreateInvestmentInvitationForm.Group>
                )}
                <CreateInvestmentInvitationForm.Group name="pricePerToken" required={true}>
                  <CreateInvestmentInvitationForm.Input type="number" isCurrency />
                  {CreateInvestmentInvitationForm.Validators.Range(
                    toNumber(backToBackMinPrice),
                    undefined,
                    <Translate
                      name="investmentInvitationForm.fields.pricePerToken.requirements.min.error"
                      args={[(_, key) => <Currency key={key}>{backToBackMinPrice}</Currency>]}
                    />,
                    <Translate
                      name="investmentInvitationForm.fields.pricePerToken.requirements.min.helper"
                      args={[(_, key) => <Currency key={key}>{backToBackMinPrice}</Currency>]}
                    />,
                  )}
                  {CreateInvestmentInvitationForm.Validators.MaxDecimals(13)}
                </CreateInvestmentInvitationForm.Group>
                <CreateInvestmentInvitationForm.Group
                  name="maxNumberOfTokens"
                  required={true}
                  info={
                    !calculationError &&
                    maxNumberOfTokens &&
                    // TODO(mara-cashlink): add loading conditional here?
                    pricePerToken &&
                    investmentTotal && (
                      <>
                        <Translate
                          name="investmentInvitation.information.investmentTotal"
                          args={[(_, key) => <Currency key={key}>{investmentTotal}</Currency>]}
                        />
                        {showShareInfo && (
                          <Translate
                            name="investmentInvitation.information.valuation"
                            args={[(_, key) => <Currency key={key}>{valuation}</Currency>]}
                          />
                        )}
                      </>
                    )
                  }
                >
                  <CreateInvestmentInvitationForm.Input type="number" />
                  {CreateInvestmentInvitationForm.Validators.Integer()}
                  {CreateInvestmentInvitationForm.Validators.Range(
                    1,
                    maxNumberOfTokensUpperLimit,
                    <Translate
                      name="investmentInvitationForm.fields.maxNumberOfTokens.requirements.max.error"
                      args={[
                        <NumberComponent key={0}>{1}</NumberComponent>,
                        <NumberComponent key={1}>{maxNumberOfTokensUpperLimit}</NumberComponent>,
                      ]}
                    />,
                    <Translate
                      name="investmentInvitationForm.fields.maxNumberOfTokens.requirements.max.helper"
                      args={[
                        <NumberComponent key={0} type="shares">
                          {maxNumberOfTokensUpperLimit}
                        </NumberComponent>,
                      ]}
                    />,
                  )}
                </CreateInvestmentInvitationForm.Group>
                {!!ruleTypes.length && (
                  <CreateInvestmentInvitationForm.Group
                    name="ruleType"
                    required={true}
                    info={ruleType && ruleTypeElements[ruleType].requirement}
                  >
                    <CreateInvestmentInvitationForm.GroupToggle>
                      {ruleTypes.map((ruleTypeOption) => (
                        <CreateInvestmentInvitationForm.Radio value={ruleTypeOption}>
                          {ruleTypeElements[ruleTypeOption].name}
                        </CreateInvestmentInvitationForm.Radio>
                      ))}
                    </CreateInvestmentInvitationForm.GroupToggle>
                  </CreateInvestmentInvitationForm.Group>
                )}

                {ruleType && maxNumberOfTokens && pricePerToken && country && !ruleTypeElements[ruleType].valid && (
                  <Hint variant="danger">{ruleTypeElements[ruleType].validationError}</Hint>
                )}
              </>
            )}
          </CreateInvestmentInvitationForm.ValueProvider>
          <CreateInvestmentInvitationForm.GenericErrorMessages />
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.cancel" />,
                size: 'large',
                onClick: onCloseAfterSuccess,
              },
              {
                name: 'submit',
                type: 'submit',
                variant: 'primary',
                loading: loading,
                content: <Translate name="investmentInvitation.create.short" />,
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </CreateInvestmentInvitationForm>
    </Modal>
  );
};

export default CreateInvestmentInvitationModal;
