import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import CreateCampaignModal, { CreateCampaignFields } from './create-campaign-modal';
import Button from 'ui/atoms/button';
import makeLink from 'helper/make-link';
import useApiCall from 'hooks/use-api-call';
import { AdminApi } from 'api/apis';
import { toMoney, toNumber } from 'ui/helper/money';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import { Campaign, IssuerInvestmentRules, Money } from 'api/models';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { useActiveProduct } from 'apps/issuer/hooks';

interface IssuerCreateCampaignProps {
  onCloseAfterSuccess?: () => void;
  productId?: string;
}

interface CampaignCalculations {
  remainingTokens: number | undefined;
  investmentTotalMin: Money | undefined;
  investmentTotalMax: Money | undefined;
}

const IssuerCreateCampaign: FunctionComponent<IssuerCreateCampaignProps> = (props) => {
  const { onCloseAfterSuccess = () => {}, children, productId } = props;

  const { loading, token, reloadProducts } = useActiveProduct(productId);

  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingCalculation, setIsLoadingCalculation] = useState(false);

  const { withApi, makeAuthenticatedApi, error } = useApiCall(true);

  const { withApi: withCalcApi, makeAuthenticatedApi: makeAuthenticatedCalcApi, error: calcError } = useApiCall(false);

  const {
    withApi: withCampaignsApi,
    loading: loadingCampaignsApi,
    makeAuthenticatedApi: makeAuthenticatedCampaignsApi,
    error: createCampaignError,
  } = useApiCall(false);

  const [values, setValues] = useState<CreateCampaignFields>();

  const [campaign, setCampaign] = useState<Campaign>();

  const [calculations, setCalculations] = useState<CampaignCalculations>({
    remainingTokens: undefined,
    investmentTotalMin: undefined,
    investmentTotalMax: undefined,
  });

  const [ruleSets, setRuleSets] = useState<IssuerInvestmentRules[]>();

  const tokensCalcApi: AdminApi = useMemo(() => makeAuthenticatedCalcApi(AdminApi), [makeAuthenticatedCalcApi]);

  const invitationRulesApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const campaignsApi: AdminApi = useMemo(
    () => makeAuthenticatedCampaignsApi(AdminApi),
    [makeAuthenticatedCampaignsApi],
  );

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const answerErrors = getRemainingError();

  const getCalculations = useCallback(() => {
    setIsLoadingCalculation(true);

    withCalcApi(async (utilities) => {
      if (!token?.id) return;

      let remainingTokensCalc: number | undefined = undefined;
      let investmentTotalMinCalc: Money | undefined = undefined;
      let investmentTotalMaxCalc: Money | undefined = undefined;

      const { takeLatest } = utilities;
      try {
        const { remainingTokens } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: token.id,
          requestedTokens: 0,
        });
        remainingTokensCalc = remainingTokens;
      } catch (error) {
        remainingTokensCalc = undefined;
      }
      try {
        const { investmentTotal: investmentTotalMin } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: token.id,
          ...(values &&
            values.minNumberOfTokens && {
              requestedTokens: toNumber(values.minNumberOfTokens),
            }),
          ...(values &&
            values.pricePerToken && {
              pricePerToken: toNumber(values.pricePerToken),
            }),
        });
        investmentTotalMinCalc = investmentTotalMin;
      } catch (error) {
        investmentTotalMinCalc = undefined;
      }
      try {
        const { investmentTotal: investmentTotalMax } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: token.id,
          ...(values &&
            values.maxNumberOfTokens && {
              requestedTokens: toNumber(values.maxNumberOfTokens),
            }),
          ...(values &&
            values.pricePerToken && {
              pricePerToken: toNumber(values.pricePerToken),
            }),
        });
        investmentTotalMaxCalc = investmentTotalMax;
      } catch (error) {
        investmentTotalMaxCalc = undefined;
      }
      takeLatest(() => {
        setCalculations({
          remainingTokens: remainingTokensCalc,
          investmentTotalMin: investmentTotalMinCalc,
          investmentTotalMax: investmentTotalMaxCalc,
        });
        setIsLoadingCalculation(false);
      });
    });
  }, [withCalcApi, tokensCalcApi, token, values]);

  useEffect(() => {
    getCalculations();
  }, [values, token, getCalculations]);

  const createCampaign = useCallback(
    (values: CreateCampaignFields) => {
      withCampaignsApi(async () => {
        if (!token) return;
        const campaign = await campaignsApi.adminTokensCampaignsCreate({
          id: token.id,
          campaignRequest: {
            allocation: toNumber(values.allocation),

            // TODO: add currency after corresponding backend changes in campaignsCreate api
            // currency: values.currency

            // TODO: default currency "EUR" correct?
            pricePerToken: toMoney(values.pricePerToken.replace(',', '.')),

            minNumberOfTokens: toNumber(values.minNumberOfTokens),
            maxNumberOfTokens: toNumber(values.maxNumberOfTokens),

            // TODO: use Type Country/CampaignCountryEnum instead of any
            country: values.country as any,

            // TODO: use Type CampaignRuleType/CampaignRuleTypeEnum instead of any
            ruleType: values.ruleType as any,

            name: values.name,

            legalPersonsAllowed: values.legalPersonsAllowed ? values.legalPersonsAllowed === 'true' : undefined,

            naturalPersonsAllowed: values.naturalPersonsAllowed ? values.naturalPersonsAllowed === 'true' : undefined,

            active: true,
          },
        });
        setCampaign(campaign);
        reloadProducts();
      });
    },
    [withCampaignsApi, campaignsApi, token],
  );

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!token?.id) return;
      const rules = await invitationRulesApi.adminTokensInvitationRulesList({
        id: token.id,
      });
      setRuleSets(rules);
    });
  }, [withApi, token, invitationRulesApi, setRuleSets]);

  const openCampaignCreation = async () => {
    setCampaign(undefined);
    setValues(undefined);
    setIsCreateCampaignOpen(true);
    loadData();
  };

  useEffect(() => {
    if (!token?.id) return;
    getCalculations();
  }, [token, values, getCalculations]);

  useEffect(() => {
    if (answerErrors) setIsLoading(false);
  }, [answerErrors]);

  useEffect(() => {
    setIsLoading(loading || (isCreateCampaignOpen && (!token?.id || !calculations)));
  }, [token, calculations, loading, isCreateCampaignOpen]);

  return (
    <>
      {isCreateCampaignOpen && token && (
        <CreateCampaignModal
          loading={loadingCampaignsApi || isLoadingCalculation}
          open={true}
          onSubmit={createCampaign}
          onCloseAfterSuccess={() => {
            onCloseAfterSuccess();
            setIsCreateCampaignOpen(false);
          }}
          onCancelTriggered={() => setIsCreateCampaignOpen(false)}
          onChange={setValues}
          currencies={token.currencies}
          allocationUpperLimit={calculations.remainingTokens}
          backToBackMinPrice={token.backToBackMinPrice}
          investmentTotalMin={calculations && calculations.investmentTotalMin}
          investmentTotalMax={calculations && calculations.investmentTotalMax}
          ruleSets={ruleSets}
          calculationError={calcError}
          createCampaignError={createCampaignError}
          campaignLink={
            campaign &&
            makeLink(
              INVESTMENT_ROUTES.campaign,
              {
                campaignId: campaign.id,
              },
              true,
            )
          }
        />
      )}
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!token || !token.id}
        onClick={openCampaignCreation}
        error={answerErrors}
      >
        {children}
      </Button>
    </>
  );
};

export default IssuerCreateCampaign;
