import React, { FunctionComponent } from 'react';

import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import LoadingRing from 'ui/atoms/loading-ring';

import PageTitle from 'core/components/page-title';
import useOnlyOnce from 'ui/hooks/use-only-once';
import { useApiCall } from 'hooks/use-api-call';
import { DemoApi } from 'api';
import { makeApi } from 'core/api';
import useGoTo from 'hooks/use-go-to';

interface RegistrationSuccessProps {
  id: string;
  token: string;
}

const EmailConfirm: FunctionComponent<RegistrationSuccessProps> = ({ id, token }) => {
  const goToDashboard = useGoTo({ path: '/dashboard' });

  const { loading, withApi } = useApiCall(true);

  useOnlyOnce(() => {
    (async () => {
      const api: DemoApi = await makeApi(DemoApi);
      withApi(
        async () =>
          await api.demoIssuerActivationCreate({
            demoIssuerActivationRequest: {
              id,
              token,
            },
          }),
      );
    })();
    return undefined;
  });

  const onClick = () => {
    goToDashboard();
  };

  return (
    <>
      {loading ? (
        <LoadingRing />
      ) : (
        <>
          <PageTitle>
            <Translate name="demo.issuerReg.emailConfirmation.titleSuccess" />
          </PageTitle>
          <p>
            <Translate name="demo.issuerReg.emailConfirmation.textSuccess" />
          </p>
          <p>
            <Button size="large" fluid={true} variant="primary" onClick={onClick}>
              <Translate name="demo.issuerReg.emailConfirmation.goToDashboard" />
            </Button>
          </p>
        </>
      )}
    </>
  );
};

export default EmailConfirm;
