import { useSelector, useDispatch } from 'store/hooks';
import { issuerSelector, productSelector } from './selectors';
import { tokensFetch } from './actions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IssuerAppState } from 'store/types';
import { compact } from 'lodash';
import { AdminApi, InvestmentToken, PermissionsEnum, TokenGlobalOverview, AdminInvestorList } from 'api';
import { useApiCall } from 'hooks/use-api-call';
import {
  currentTokenHasPermission,
  hasManageApprovalsPermission,
  hasManageCreditDigitalSecuritiesPermission,
  hasManageIncomingPaymentsPermission,
} from 'apps/issuer/helpers/permissions';

export function useIssuerSelector() {
  return useSelector(issuerSelector);
}

export function useProductsSelector() {}

export function useProductSelector(productId: string | undefined) {
  return useSelector((state: IssuerAppState) => productSelector(state, productId));
}

export function useProducts() {
  const { tokens, loading, error, isInitial } = useIssuerSelector();
  const reloadProducts = useReloadProducts();

  return { tokens, loading, error, isInitial, reloadProducts };
}

export function useReloadProducts() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(tokensFetch());
  }, [dispatch, tokensFetch]);
}

export const useActiveProduct = (productId: string | undefined) => {
  const reloadProducts = useReloadProducts();
  const { token, loading, error } = useProductSelector(productId);

  return { token, error, loading, reloadProducts };
};

export const useProductsFilter = (tokens: InvestmentToken[] | null) => {
  const productsFilter =
    (tokens &&
      tokens.length > 0 &&
      tokens.map((product) => ({
        value: product.id,
        label: product.name,
        name: product.name,
      }))) ||
    [];

  return { productsFilter };
};

interface RequiredIssuerAction {
  type: 'approveOffers' | 'confirmPayments' | 'creditSecurities';
  number: number;
  link: string;
}

export function useIssuerOverview() {
  const { tokens, loading: tokenLoading } = useProducts();

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);

  const [productsOverview, setProductsOverview] = useState<TokenGlobalOverview>();
  const [investorsIncompleteList, setInvestorsIncompleteList] = useState<AdminInvestorList[]>();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const canApprove = hasManageApprovalsPermission(tokens);
  const canManagePayments = hasManageIncomingPaymentsPermission(tokens);
  const canCreditDigitalSecurities = hasManageCreditDigitalSecuritiesPermission(tokens);

  const requiredActions: RequiredIssuerAction[] | undefined = useMemo(() => {
    if (!productsOverview) return;
    const {
      numberApprovableInvestments,
      numberPaymentsOutstandingInvestments,
      numberSecuritiesOutstandingInvestments,
    } = productsOverview;

    return compact([
      numberApprovableInvestments &&
        canApprove && {
          type: 'approveOffers',
          number: numberApprovableInvestments,
          link: 'issuerApprovals',
        },
      numberPaymentsOutstandingInvestments &&
        canManagePayments && {
          type: 'confirmPayments',
          number: numberPaymentsOutstandingInvestments,
          link: 'issuerIncomingPayments',
        },
      numberSecuritiesOutstandingInvestments &&
        canCreditDigitalSecurities && {
          type: 'creditSecurities',
          number: numberSecuritiesOutstandingInvestments,
          link: 'issuerCreditSecurities',
        },
    ]);
  }, [
    productsOverview?.numberApprovableInvestments,
    productsOverview?.numberPaymentsOutstandingInvestments,
    productsOverview?.numberSecuritiesOutstandingInvestments,
    canApprove,
    canManagePayments,
    canCreditDigitalSecurities,
  ]);

  useEffect(() => {
    withApi(async () => {
      if (!tokens?.length) return;

      const globalOverview = await api.adminOverviewRetrieve();
      setProductsOverview(globalOverview);

      const investorsList = await api.adminInvestorsIncompleteList({});
      setInvestorsIncompleteList(investorsList);
    });
  }, [withApi, api, tokens]);

  return {
    requiredActions,
    productsOverview,
    investorsIncompleteList,
    loading,
    tokenLoading,
    tokens,
  };
}

/**
 * Filters the products for which the user actually has the specific permission for
 * @param permission
 */
export function useProductsWithPermission(permission: PermissionsEnum | null | undefined) {
  const { tokens } = useProducts();

  if (!tokens || !permission) return [];

  return tokens.filter((token) => currentTokenHasPermission(token, permission));
}
