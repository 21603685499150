import { FrontendStatusEnum, InvestmentAcquisitionTypeRecipientEnum, StepEnum } from 'api/models';
import { InvestorInvestmentStatusEnum } from '../ui/types/investment';
import { assertUnreachable } from '../ui/helper/unreachable';
import { toNumber } from 'ui/helper/money';
import { ColorVariants } from 'ui/styles/themes/color-variants';

export const getIssuerInvestmentStatusVariant = (status: FrontendStatusEnum): ColorVariants => {
  switch (status) {
    case FrontendStatusEnum.Finished:
    case FrontendStatusEnum.SecuritiesTransferred:
    case FrontendStatusEnum.TransactionSucceeded:
    case FrontendStatusEnum.SuccessFullyTransfered:
    case FrontendStatusEnum.SuccessRedeemed:
    case FrontendStatusEnum.Handover:
      return 'success';
    case FrontendStatusEnum.Aborted:
    case FrontendStatusEnum.Rejected:
    case FrontendStatusEnum.CanceledKycInsufficient:
    case FrontendStatusEnum.CanceledPaymentMissing:
    case FrontendStatusEnum.Cancellation:
    case FrontendStatusEnum.TransactionFailed:
      return 'danger';
    case FrontendStatusEnum.IdentificationPending:
    case FrontendStatusEnum.IdentificationCompliancePending:
    case FrontendStatusEnum.AcceptanceMissing:
    case FrontendStatusEnum.PaymentPending:
    case FrontendStatusEnum.WalletSetupPending:
    case FrontendStatusEnum.SecurityTransferMissing:
    case FrontendStatusEnum.AdditionalInformationPending:
    case FrontendStatusEnum.TransactionPending:
    case FrontendStatusEnum.SuccessPartiallyTransfered:
      return 'warning';
  }
};

export const getInvestorInvestmentStatusVariant = (status: InvestorInvestmentStatusEnum): ColorVariants => {
  switch (status) {
    case InvestorInvestmentStatusEnum.Finished:
    case InvestorInvestmentStatusEnum.SecuritiesTransferredToManagedWallet:
    case InvestorInvestmentStatusEnum.SecuritiesTransferredToCustomWallet:
    case InvestorInvestmentStatusEnum.SuccessFullyTransfered:
    case InvestorInvestmentStatusEnum.SuccessRedeemed:
    case InvestorInvestmentStatusEnum.Handover:
      return 'success';
    case InvestorInvestmentStatusEnum.PreInvestmentAmount:
    case InvestorInvestmentStatusEnum.InvestmentAmountMissing:
    case InvestorInvestmentStatusEnum.QuestionnaireMissing:
    case InvestorInvestmentStatusEnum.VibMissing:
    case InvestorInvestmentStatusEnum.OfferConfirmationMissing:
    case InvestorInvestmentStatusEnum.BenefitingPersonsMissing:
    case InvestorInvestmentStatusEnum.IdentificationMissing:
    case InvestorInvestmentStatusEnum.IdentificationPending:
    case InvestorInvestmentStatusEnum.WaitingAcceptance:
    case InvestorInvestmentStatusEnum.PaymentOutstanding:
    case InvestorInvestmentStatusEnum.WalletSetupMissing:
    case InvestorInvestmentStatusEnum.WaitingSecurityTransfer:
    case InvestorInvestmentStatusEnum.WalletSetupPending:
    case InvestorInvestmentStatusEnum.AdditionalInformationMissing:
    case InvestorInvestmentStatusEnum.SuccessPartiallyTransfered:
      return 'info';
    case InvestorInvestmentStatusEnum.IdentificationRejected:
    case InvestorInvestmentStatusEnum.IdentificationFailed:
    case InvestorInvestmentStatusEnum.Canceled:
      return 'warning';
  }
};

interface GetInvestorInvestmentStatusInterface {
  hasTokenTransactionHash: boolean | undefined;
  isWalletManaged: boolean | undefined;
  hasWalletAddress: boolean | undefined;
  isTokenized: boolean | undefined;
  step: StepEnum;
  investmentTotal: number;
  investmentTotalFulfilled: number;
  acquisitionType: InvestmentAcquisitionTypeRecipientEnum | undefined;
  isRedeemed: boolean | undefined;
}

export const getInvestorInvestmentStatus = ({
  hasTokenTransactionHash,
  isWalletManaged,
  hasWalletAddress,
  isTokenized,
  step,
  investmentTotal,
  investmentTotalFulfilled,
  acquisitionType,
  isRedeemed,
}: GetInvestorInvestmentStatusInterface): InvestorInvestmentStatusEnum => {
  switch (step) {
    case StepEnum.ACCOUNTSETUP:
    case StepEnum.UNCONFIRMED:
      return InvestorInvestmentStatusEnum.PreInvestmentAmount;
    case StepEnum.INVESTMENTAMOUNT:
      return InvestorInvestmentStatusEnum.InvestmentAmountMissing;
    case StepEnum.QUESTIONNAIRE:
      return InvestorInvestmentStatusEnum.QuestionnaireMissing;
    case StepEnum.VIB:
      return InvestorInvestmentStatusEnum.VibMissing;
    case StepEnum.OFFERCREATION:
      return InvestorInvestmentStatusEnum.OfferConfirmationMissing;
    case StepEnum.BENEFICIARY:
      return InvestorInvestmentStatusEnum.BenefitingPersonsMissing;
    case StepEnum.IDENTIFICATION:
    case StepEnum.RESUMEIDENTIFICATION:
      return InvestorInvestmentStatusEnum.IdentificationMissing;
    case StepEnum.IDENTIFICATIONREJECTED:
      return InvestorInvestmentStatusEnum.IdentificationRejected;
    case StepEnum.IDENTIFICATIONFAILED:
      return InvestorInvestmentStatusEnum.IdentificationFailed;
    case StepEnum.WAITINGKYC:
      return InvestorInvestmentStatusEnum.IdentificationPending;
    case StepEnum.WAITINGACCEPTANCE:
    case StepEnum.WAITINGCOMPLIANCE:
      return InvestorInvestmentStatusEnum.WaitingAcceptance;
    case StepEnum.PAYMENT:
      return InvestorInvestmentStatusEnum.PaymentOutstanding;
    case StepEnum.SELECTWALLET:
    case StepEnum.SETUPMOBILEWALLET:
    case StepEnum.PREPAREASSET:
    case StepEnum.CONFIRMWALLET:
      return InvestorInvestmentStatusEnum.WalletSetupMissing;
    case StepEnum.ADDITIONALINFORMATION:
      return InvestorInvestmentStatusEnum.AdditionalInformationMissing;
    case StepEnum.CANCELED:
      return InvestorInvestmentStatusEnum.Canceled;

    case StepEnum.FINAL:
      if (isRedeemed) {
        return InvestorInvestmentStatusEnum.SuccessRedeemed;
      }
      if (isTokenized) {
        if (!hasTokenTransactionHash) {
          if (!hasWalletAddress) {
            return InvestorInvestmentStatusEnum.WalletSetupPending;
          }
          return InvestorInvestmentStatusEnum.WaitingSecurityTransfer;
        }
        if (toNumber(investmentTotal) !== toNumber(investmentTotalFulfilled)) {
          if (toNumber(investmentTotal) === 0) {
            return InvestorInvestmentStatusEnum.SuccessFullyTransfered;
          }
          return InvestorInvestmentStatusEnum.SuccessPartiallyTransfered;
        }
        if (acquisitionType === InvestmentAcquisitionTypeRecipientEnum.HANDOVER) {
          return InvestorInvestmentStatusEnum.Handover;
        }

        if (isWalletManaged) {
          return InvestorInvestmentStatusEnum.SecuritiesTransferredToManagedWallet;
        }
        return InvestorInvestmentStatusEnum.SecuritiesTransferredToCustomWallet;
      }
      return InvestorInvestmentStatusEnum.Finished;
  }

  // make sure we covered all cases
  return assertUnreachable(step);
};
