import { useCallback } from 'react';
import { AdminBundesbankPaymentInstructionStatusEnum } from 'src/api';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

export const useTriggerSolutionPaymentStatus = () => {
  const translate = useTranslateWithStringArgs();

  const getVariant = useCallback((status: AdminBundesbankPaymentInstructionStatusEnum) => {
    switch (status) {
      case AdminBundesbankPaymentInstructionStatusEnum.FAILED:
      case AdminBundesbankPaymentInstructionStatusEnum.CANCELED:
        return 'danger';
      case AdminBundesbankPaymentInstructionStatusEnum.PREPARE:
      case AdminBundesbankPaymentInstructionStatusEnum.INITIALLYAPPROVED:
      case AdminBundesbankPaymentInstructionStatusEnum.APPROVED:
      case AdminBundesbankPaymentInstructionStatusEnum.READY:
      case AdminBundesbankPaymentInstructionStatusEnum.TRIGGERED:
      case AdminBundesbankPaymentInstructionStatusEnum.PAYMENTLOCKED:
      case AdminBundesbankPaymentInstructionStatusEnum.HTLCREADY:
        return 'warning';
      case AdminBundesbankPaymentInstructionStatusEnum.SUCCESS:
        return 'success';
    }
  }, []);

  const getStatusName = useCallback((status: AdminBundesbankPaymentInstructionStatusEnum) => {
    switch (status) {
      case AdminBundesbankPaymentInstructionStatusEnum.FAILED:
        return translate('triggerSolutionsPayment.status.failed');
      case AdminBundesbankPaymentInstructionStatusEnum.CANCELED:
        return translate('triggerSolutionsPayment.status.canceled');
      case AdminBundesbankPaymentInstructionStatusEnum.PREPARE:
        return translate('triggerSolutionsPayment.status.prepare');
      case AdminBundesbankPaymentInstructionStatusEnum.INITIALLYAPPROVED:
        return translate('triggerSolutionsPayment.status.initiallyApproved');
      case AdminBundesbankPaymentInstructionStatusEnum.APPROVED:
        return translate('triggerSolutionsPayment.status.approved');
      case AdminBundesbankPaymentInstructionStatusEnum.READY:
        return translate('triggerSolutionsPayment.status.ready');
      case AdminBundesbankPaymentInstructionStatusEnum.TRIGGERED:
        return translate('triggerSolutionsPayment.status.triggered');
      case AdminBundesbankPaymentInstructionStatusEnum.PAYMENTLOCKED:
        return translate('triggerSolutionsPayment.status.paymentLocked');
      case AdminBundesbankPaymentInstructionStatusEnum.HTLCREADY:
        return translate('triggerSolutionsPayment.status.htlcReady');
      case AdminBundesbankPaymentInstructionStatusEnum.SUCCESS:
        return translate('triggerSolutionsPayment.status.success');
    }
  }, []);

  return {
    getStatusName,
    getVariant,
  };
};
