import styled from 'styled-components';
import Button from 'ui/atoms/button';
import Icon from 'ui/atoms/icon';

export const Container = styled.div`
  margin: 1rem 0;
`;

export const Row = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Title = styled(Button)`
  flex: 1 0 0;
  justify-content: flex-start;
  padding: 0;
`;

export const Content = styled.p`
  margin-top: 1rem;
`;

export const ExpandIcon = styled(Icon)`
  margin-left: auto;
`;
