import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid, { Col } from 'ui/atoms/grid';
import Input from 'ui/atoms/input';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';
import { VoucherFields } from '..';
import Spacer from 'ui/atoms/spacer';

interface VoucherUsesByInvestorProps {
  Form: any;
  values: VoucherFields;
  numberOfUsesByInvestorAmount: number | undefined;
  onChangeUsesByInvestor: (noOfUses: string, amount: number) => void;
}

export enum VoucherUsesByInvestorEnum {
  UNLIMITED = 'unlimited',
  FIRST_TIME = 'firstTime',
  LIMITED_TO = 'limitedTo',
}

const VoucherUsesByInvestor: FunctionComponent<VoucherUsesByInvestorProps> = ({
  Form,
  values,
  numberOfUsesByInvestorAmount,
  onChangeUsesByInvestor,
}) => {
  const [amount, setAmount] = useState(numberOfUsesByInvestorAmount || 1);

  useEffect(() => {
    onChangeUsesByInvestor(values.numberOfUsesByInvestor, amount);
  }, [values.numberOfUsesByInvestor, amount]);

  return (
    <Section spacing="medium">
      <Form.Group name="numberOfUsesByInvestor" required={true}>
        <Grid>
          <Col width={8} phone={12}>
            <strong>
              <Translate name={`createVoucherForm.numberOfUsesByInvestor.label`} />
            </strong>
            <Spacer y={2} />
            <Form.GroupToggle compact>
              {Object.values(VoucherUsesByInvestorEnum).map((option) => (
                <Form.Radio value={option} key={option}>
                  <Translate name={`createVoucherForm.${option}`} />
                </Form.Radio>
              ))}
            </Form.GroupToggle>
          </Col>
          <Col width={4} phone={12}>
            <Input
              type="number"
              min={1}
              label={<Translate name={'createVoucherForm.amount'} />}
              disabled={values.numberOfUsesByInvestor !== VoucherUsesByInvestorEnum.LIMITED_TO}
              onChange={(e: any) => setAmount(e.target.value)}
              value={values.numberOfUsesByInvestor === VoucherUsesByInvestorEnum.LIMITED_TO ? amount.toString() : ''}
            />
            <small>
              <Translate name={`createVoucherForm.numberOfUsesByInvestor.info`} />
            </small>
          </Col>
        </Grid>
      </Form.Group>
    </Section>
  );
};

export default VoucherUsesByInvestor;
