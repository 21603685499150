import React, { FunctionComponent, useMemo } from 'react';
import { QuestionnaireForm } from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import {
  EducationOccupationFields,
  QuestionnaireAnswers,
  QuestionnairePage,
  QuestionnairePagesSegments,
  QuestionnaireQuestions,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import { useInvestmentQuestionnaireFormMemo } from 'libraries/wizard/components/questionnaire/questionnaire-form/hooks';
import { GroupScore } from 'api';
import Translate from 'ui/atoms/translate';
import { Money } from 'ui/types/money';
import Button from 'ui/atoms/button';
import ActionButtons from 'ui/molecules/action-buttons';
import QuestionnaireSummaryNetworth from './questionnaire-summary-networth';
import QuestionnaireSummaryQuestions from './questionnaire-summary-questions';

export interface QuestionnaireSummaryProps {
  initialAnswers: QuestionnaireAnswers[];
  questions: QuestionnaireQuestions[];
  initialEducationOccupation?: EducationOccupationFields;
  questionnairePagesSegments: QuestionnairePagesSegments;
  onContinue: () => void;
  onGoBack: () => void;
  scoreList?: GroupScore[];
  lossability?: Money | null;
  netWorth?: Money | null;
  investmentTotal?: Money | null;
  noAnswers?: boolean;
}

const QuestionnaireSummary: FunctionComponent<QuestionnaireSummaryProps> = (props) => {
  const {
    initialAnswers,
    questions,
    initialEducationOccupation,
    questionnairePagesSegments,
    scoreList,
    lossability,
    netWorth,
    investmentTotal,
    noAnswers,
    onContinue,
    onGoBack,
  } = props;

  const initialQuestionnaireValues = mapValues(
    keyBy(initialAnswers, 'id'),
    (answers) => answers.selectedOption || answers.options![0],
  );

  const questionsSegments = useInvestmentQuestionnaireFormMemo(questions);

  const pageSegments = questionnairePagesSegments.map((segmentPage: QuestionnairePage) => segmentPage.segments).flat();

  const SummaryButtons = useMemo(() => {
    return (
      <ActionButtons>
        <Button type="submit" size="large" variant="primary" fluid={true} onClick={onContinue}>
          <Translate name={'common.continue'} />
        </Button>
      </ActionButtons>
    );
  }, [onGoBack, onContinue]);

  return (
    <QuestionnaireForm
      initial={{
        ...initialQuestionnaireValues,
        ...initialEducationOccupation,
      }}
      onSubmit={() => {}}
      i18nKey="questionnaire"
    >
      <QuestionnaireSummaryQuestions
        pageSegments={pageSegments}
        questionsSegments={questionsSegments}
        scoreList={scoreList}
        noAnswers={noAnswers}
      />
      <QuestionnaireSummaryNetworth
        investmentTotal={investmentTotal}
        netWorth={netWorth}
        lossability={lossability}
        index={noAnswers ? 0 : pageSegments.length + 1}
      />
      {SummaryButtons}
    </QuestionnaireForm>
  );
};

export default QuestionnaireSummary;
